import axios from 'axios';
import Helper from "@/helper";

export default {
  async getPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    return await axios({
      url: `/api/tenant/item/paged`,
      method: 'post',
      params: {...Helper.tableOptionsToRestPageParams(options)},
      data: filter,
    }).then(r => r.data);
  },
  async getItem(id) {
    return await axios({
      url: '/api/tenant/item/' + id,
      method: 'get',
    }).then(r => r.data);
  },
  async updateItem(id, /* Item */ data) {
    return await axios({
      url: '/api/tenant/item/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async getItemGroups() {
    return await axios({
      url: '/api/tenant/item/groups',
      method: 'get',
    }).then(r => r.data);
  },
  async runRobot() {
    return await axios({
      url: '/api/tenant/robot/item-fetch',
      method: 'post',
    }).then(r => r.data);
  },
  async runWarehouseStockRobot() {
    return await axios({
      url: '/api/tenant/robot/warehouse-stock-fetch',
      method: 'post',
    }).then(r => r.data);
  },
};
