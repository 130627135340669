<template>
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="saving"
                :title="$t('user_profile.change_password')">
    <v-card-text class="scroll-content">
      <v-alert :value="true" class="mt-6 mb-6" outlined type="info">{{
          $t('user_profile.new_password_guide')
        }}
      </v-alert>

      <v-form ref="form">
        <v-text-field
          v-model="oldPassword"
          :append-icon="showOldPassword ? 'visibility' : 'visibility_off'"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_profile.old_password')"
          :rules="[$rules.required, $rules.mustBeAtLeast6Characters]"
          :type="showOldPassword ? 'text' : 'password'"
          autocomplete="new-password"
          class="password mt-5"
          outlined
          validate-on-blur
          @click:append="showOldPassword = !showOldPassword"
        ></v-text-field>

        <v-text-field
          v-model="newPassword"
          :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_profile.new_password')"
          :rules="[$rules.required, $rules.mustBeAtLeast6Characters]"
          :type="showNewPassword ? 'text' : 'password'"
          autocomplete="new-password"
          class="password mt-5"
          outlined
          validate-on-blur
          @click:append="showNewPassword = !showNewPassword"
        ></v-text-field>

        <v-text-field
          v-model="newPassword2"
          :append-icon="showNewPassword2 ? 'visibility' : 'visibility_off'"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_profile.new_password_again')"
          :rules="[$rules.required, $rules.passwordHasToMatch(newPassword2, newPassword)]"
          :type="showNewPassword2 ? 'text' : 'password'"
          autocomplete="new-password"
          class="password mt-5"
          outlined
          validate-on-blur
          @click:append="showNewPassword2 = !showNewPassword2"
        ></v-text-field>
      </v-form>

      <v-alert :value="showAlert" class="mt-4" outlined type="error">{{ alertText }}</v-alert>
    </v-card-text>
  </basic-dialog>
</template>

<script>
import {Auth} from 'aws-amplify';
import BasicDialog from "@/components/BasicDialog";

export default {
  name: 'ChangePasswordDialog',
  components: {BasicDialog},
  props: {
    user: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      dialog: false,
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      showOldPassword: false,
      showNewPassword: false,
      showNewPassword2: false,
      showAlert: false,
      alertText: '',
      saving: false,
      actionButtons: {
        cancel: {
          click: this.cancel,
        },
        save: {
          click: this.savePassword,
        }
      }
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    savePassword() {
      this.saving = true;
      this.showAlert = false;
      this.alertText = '';

      if (this.newPassword !== this.newPassword2) {
        this.showAlert = true;
        this.alertText = this.$t('user_profile.errors.new_passwords_do_not_match');
        this.saving = false;
        return;
      }
      if (this.oldPassword.length < 6) {
        this.showAlert = true;
        this.alertText = this.$t('user_profile.errors.old_password_must_be_at_least_6_characters');
        this.saving = false;
        return;
      }
      if (this.newPassword.length < 6) {
        this.showAlert = true;
        this.alertText = this.$t('user_profile.errors.new_password_must_be_at_least_6_characters');
        this.saving = false;
        return;
      }
      if (!this.$refs.form.validate()) {
        this.saving = false;
        return;
      }
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, this.oldPassword, this.newPassword);
        })
        .then(data => {
          console.log(data);
          this.saving = false;
          this.dialog = false;
        })
        .catch(err => {
          console.log(err);
          this.saving = false;
          this.showAlert = true;
          this.alertText = this.$t('user_profile.errors.' + err.code);
        });
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form.reset();
        this.oldPassword = '';
        this.newPassword = '';
        this.newPassword2 = '';
        this.showOldPassword = false;
        this.showNewPassword = false;
        this.showNewPassword2 = false;
        this.showAlert = false;
        this.alertText = '';
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-content {
  max-height: calc(100vh - 240px);
  overflow: auto;
}

@media screen and (min-width: 600px) {
  .scroll-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
</style>
