<template>
  <v-col cols="12">
    <div class="login-guide white--text text-center mb-8">
      {{ $t('login.password_change_required') }}
    </div>

    <v-alert
      v-if="error"
      :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : uiComponentProperties.outlined ? 'rounded-0' : ''"
      :text="uiComponentProperties.text"
      :value="true"
      class="text-left"
      type="error"
    >
      {{ error }}
    </v-alert>

    <v-text-field
      v-model="password"
      :color="uiComponentProperties.inputColor"
      :label="$t('login.new_password')"
      :outlined="uiComponentProperties.outlined"
      :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
      autofocus
      class="mb-8"
      hide-details
      type="password"
    />
    <v-text-field
      v-model="passwordConfirm"
      :color="uiComponentProperties.inputColor"
      :label="$t('login.new_password_again')"
      :outlined="uiComponentProperties.outlined"
      :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
      autofocus
      class="mb-8"
      hide-details
      type="password"
    />

    <div
      v-for="attribute in options.user.challengeParam.requiredAttributes"
      v-bind:key="attribute"
      :attribute="attribute"
      v-bind:class="amplifyUI.formField"
    >
      <div v-bind:class="amplifyUI.inputLabel">{{ attribute.charAt(0).toUpperCase() + attribute.slice(1) }}</div>
      <input
        v-model="requiredAttributes[attribute]"
        :placeholder="attribute.charAt(0).toUpperCase() + attribute.slice(1)"
        v-bind:class="amplifyUI.input"
      />
    </div>

    <div class="text-center">
      <v-btn
        :class="uiComponentProperties.buttonClass"
        :color="uiComponentProperties.buttonColor"
        :rounded="uiComponentProperties.rounded"
        block
        class="font-weight-bold"
        large
        @click="change"
      >
        <v-icon left>mdi mdi-send</v-icon>
        {{ $t('login.change_password') }}
      </v-btn>
      <v-btn :rounded="uiComponentProperties.rounded" block class="mt-5" color="primary lighten-5" large text
             @click="signIn">
        <v-icon left>mdi mdi-arrow-left</v-icon>
        {{ $t('login.back_to_sign_in') }}
      </v-btn>
    </div>
  </v-col>
</template>
<script>
import {AmplifyEventBus} from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'RequireNewPassword',
  props: ['requireNewPasswordConfig', 'uiComponentProperties'],
  data() {
    return {
      user: '',
      error: '',
      password: '',
      passwordConfirm: '',
      logger: {},
      requiredAttributes: {},
      amplifyUI: AmplifyUI,
    };
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Enter new password'),
        user: {
          challengeParam: {
            requiredAttributes: [],
          },
        },
      };
      return Object.assign(defaults, this.requireNewPasswordConfig || {});
    },
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    setError: function (e) {
      if (!e) {
        this.error = '';
        return;
      }
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
    checkContact(user) {
      this.$Amplify.Auth.verifiedContact(user)
        .then(
          /*data*/ () => {
            this.setError(false);
            AmplifyEventBus.$emit('localUser', this.user);
            AmplifyEventBus.$emit('authState', 'signedIn');
          }
        )
        .catch(e => this.setError(e));
    },
    change() {
      if (this.password !== this.passwordConfirm) {
        this.setError(this.$t('login.passwords_do_not_match'));
        return;
      }
      this.$Amplify.Auth.completeNewPassword(this.options.user, this.password, this.requiredAttributes)
        .then(
          /* user */ () => {
            if (this.options.user.challengeName === 'SMS_MFA') {
              AmplifyEventBus.$emit('localUser', this.options.user);
              AmplifyEventBus.$emit('authState', 'confirmSignIn');
            } else if (this.options.user.challengeName === 'MFA_SETUP') {
              AmplifyEventBus.$emit('localUser', this.options.user);
              AmplifyEventBus.$emit('authState', 'setMfa');
            } else {
              this.checkContact(this.options.user);
            }
            this.setError(false);
          }
        )
        .catch(err => this.setError(err));
    },
    signIn: function () {
      AmplifyEventBus.$emit('authState', 'signIn');
    },
  },
};
</script>
