<template>
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="saving"
                :title="$t('tenants.add_new_user')">

    <v-card-text class="scroll-content">
      <v-form ref="form">
        <v-text-field
          v-model="email"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_management.email')"
          :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"
          autocomplete="email"
          class="mt-5 pt-4 email"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="firstName"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_management.first_name')"
          autocomplete="first-name"
          class="mt-5 first-name"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_management.last_name')"
          autocomplete="family-name"
          class="mt-5 last-name"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
      </v-form>

      <v-alert :value="showAlert" class="mt-4" outlined type="error">{{ $t('check_all_required_fields') }}</v-alert>
    </v-card-text>

  </basic-dialog>
</template>

<script>
import tenantApi from '../../../api/tenant';
import BasicDialog from "@/components/BasicDialog";

export default {
  name: 'AddUserDialog',
  components: {BasicDialog},
  data() {
    return {
      dialog: false,
      saving: false,
      email: '',
      firstName: '',
      lastName: '',
      showAlert: false,
      actionButtons: {
        cancel: {
          click: this.cancel,
        },
        save: {
          click: this.addNewUserToTenant,
        }
      }
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    async addNewUserToTenant() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;
      try {
        await tenantApi.addNewUserToTenant({
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
        });
        this.$showSuccessNotification(this.$t('tenants.user_add_success'));
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('tenants.user_add_fail'));
      }
      this.saving = false;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.email = '';
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true;
      }, 300);
    } else {
      this.ready = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.scroll-content {
  max-height: calc(100vh - 240px);
  overflow: auto;
}

@media screen and (min-width: 600px) {
  .scroll-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}

</style>
