<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600" persistent
            @keydown.esc="cancel()">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title class="white--text">{{
            newItem ? $t('items.create_new_item') : $t('items.edit_item')
          }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <template v-if="!loading && item">
        <v-card-text class="pt-5">
          <v-form ref="form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="item.code"
                    :disabled="saving"
                    :label="$t('item.code')"
                    dense
                    hide-details
                    outlined
                    readonly
                    validate-on-blur

                >
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-model="item.description"
                    :disabled="saving"
                    :label="$t('item.description')"
                    dense
                    hide-details
                    outlined
                    readonly
                    validate-on-blur

                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="item.description2"
                    :disabled="saving"
                    :label="$t('item.description2')"
                    dense
                    hide-details
                    outlined
                    readonly

                    validate-on-blur

                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                    v-model.number="item.warehouseAmount"
                    :disabled="saving"
                    :label="$t('item.warehouse_amount')"
                    dense
                    hide-details
                    outlined
                    readonly
                    validate-on-blur

                >
                </v-text-field>
                <template>
                  <div class="text-caption pt-2 px-1">{{
                      $t('item.last_warehouse_update')
                    }} {{ $formatDateTime(item.lastWarehouseUpdate) }}
                  </div>

                </template>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="item.unit"
                    :disabled="saving"
                    :label="$t('item.unit')"
                    dense
                    hide-details
                    outlined
                    readonly

                    validate-on-blur

                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="item.group"
                    :disabled="saving"
                    :items="itemGroups"
                    :label="$t('item.group')"
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    outlined
                    readonly
                    return-object
                    validate-on-blur
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model.number="item.width"
                    :disabled="saving"
                    :label="$t('item.width')"
                    dense
                    hide-details
                    outlined
                    validate-on-blur
                >
                  <template v-slot:append>
                    <span>mm</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model.number="item.height"
                    :disabled="saving"
                    :label="$t('item.height')"
                    dense
                    hide-details
                    outlined
                    validate-on-blur
                >
                  <template v-slot:append>
                    <span>mm</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

          </v-form>
          <v-alert :value="showAlert" outlined type="error">{{ $t('validation.check_all_required_fields') }}</v-alert>
        </v-card-text>

        <v-divider/>

        <v-card-title>
          <span class="text-h6">{{ $tc('item.sub_item', 2) }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addSubItem()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <template v-for="(subItem, i) in item.subItems">
            <add-or-edit-item-dialog-sub-item :key="`${subItem.id}_${i}`" :sub-item="subItem"
                                              @input="e => setSubItem(e, i)"
                                              @remove="e => removeSubItem(e,i)"/>
          </template>
        </v-card-text>
      </template>

      <v-card-text v-if="loading" class="pt-5">
        <hb-loading-indicator/>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
            color="default"
            @click.native="cancel()"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-close
          </v-icon
          >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
            v-if="!newItem"
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            color="primary"
            @click.native="saveItem()"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-content-save
          </v-icon
          >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
        <v-btn
            v-if="newItem"
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            color="primary"
            @click.native="createItem()"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-plus
          </v-icon
          >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import itemApi from '@/api/item';
import AddOrEditItemDialogSubItem from "@/components/Items/AddOrEditItemDialogSubItem.vue";

const itemTemplate = {
  id: null,
  code: null,
  description: '',
  subItems: [],
  width: null,
  height: null
};
export default {
  name: 'AddOrEditItemDialog',
  components: {AddOrEditItemDialogSubItem},
  data() {
    return {
      showAlert: false,
      title: '',
      newItem: null,
      saving: false,
      dialog: false,
      item: {...itemTemplate},
      itemGroups: [],
      loading: true,
    };
  },
  methods: {
    setSubItem(e, i) {
      this.item.subItems[i] = e;
    },
    addSubItem() {
      this.item.subItems = [...this.item.subItems, {id: null, description: '', amount: 0, unit: '', _deleted: false}]
    },
    removeSubItem(e, i) {
      if (this.item.subItems[i]) {
        this.item.subItems[i]._deleted = !this.item.subItems[i]._deleted;
      }
    },
    async open(itemId, newItem) {
      this.loading = true;
      this.dialog = true;
      this.newItem = newItem;
      this.$refs.form?.reset();
      this.item = {...itemTemplate};
      if (!this.newItem) {
        this.item.id = itemId;
        await this.getItem();
      }
      await this.getItemGroups();
      this.loading = false;
    },
    cancel() {
      this.item = null;
      this.newItem = null;
      this.showAlert = false;
      this.dialog = false;
    },
    async getItemGroups() {
      try {
        const response = await itemApi.getItemGroups();
        this.itemGroups = response;
      } catch (err) {
        this.$handleApiError(err);
      }
    },
    async saveItem() {
      try {
        this.saving = true;
        this.item.subItems = this.item.subItems?.filter(s => !s._deleted);
        await itemApi.updateItem(this.item.id, this.item);
        this.$emit('updated');
        this.cancel();
      } catch (err) {
        this.$handleApiError(err);
      }
      this.saving = false;
    },
    async createItem() {
      if (!this.item.name || !this.item.type) {
        this.showAlert = true;
        return;
      }

      try {
        this.saving = true;
        await itemApi.createItem(this.item);
        this.$emit('created');
        this.cancel();
      } catch (err) {
        this.$handleApiError(err);
      }
      this.saving = false;
    },
    async getItem() {
      this.saving = true;
      const item = await itemApi.getItem(this.item.id);
      if (item.subItems) {
        item.subItems.forEach(s => {
          s._deleted = false;
        })
      }
      this.item = item;

      this.saving = false;
    },
  },
};
</script>

<style>
.bottom_fixed {
  position: fixed;
  bottom: 0;
}
</style>
