<template>
  <hb-basic-page
      v-if="$isTenantAdmin || $isOfficeUser"
      :action-buttons="$vuetify.breakpoint.smAndDown ? {  } : {}"
      :extension-height="56"
      :fill-height="true"
      :loading="false"
      :no-gutters="true"
      :search-title="`${$t('warehousereceipt.search')}...`"
      :title="$tc('warehousereceipt.title', 2)"
      class="warehouse-receipts-view"
      fluid
      @handle-search="debouncedSearch"
  >
    <template v-slot:app-bar-extension>
    </template>
    <v-col class="gradient-icons" cols="12">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card outlined>
              <v-data-table
                  :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                  :headers="headers"
                  :items="pagedResult.content"
                  :items-per-page="50"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="pagedResult.totalElements"
                  class="h-100 gradient-info__table"
                  style="cursor: pointer"
                  @click:row="edit"
              >
                <template v-slot:item.rows="{ item }">
                  <span v-if="item.rows">{{ item.rows.length }} kpl</span>
                </template>
                <template v-slot:item.createdByUser="{ item }">
                <span v-if="item.createdByUser">{{ item.createdByUser.firstName }} {{
                    item.createdByUser.lastName
                  }}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import warehousereceipt from '@/api/warehousereceipt';
import debounce from 'lodash.debounce';

export default {
  name: 'WarehouseReceiptsView',
  components: {},
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      headers: [
        {
          text: this.$t('warehousereceipt.external_id'),
          value: 'externalId',
        },
        {
          text: this.$t('warehousereceipt.transferred_to_adminet'),
          value: 'externalId',
        },
        {
          text: this.$t('project.name'),
          value: 'project.name',
        },
        {
          text: this.$t('warehousereceipt.description'),
          value: 'description',
        },
        {
          text: this.$t('warehousereceipt.rows'),
          value: 'rows',
        },
        {
          text: this.$t('warehousereceipt.created_by'),
          value: 'createdByUser',
        },
        /*{
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },*/
      ],
      filter: [],
      parties: [],
      titleButton: {
        text: this.$t('warehousereceipt.buttons.new'),
        icon: 'mdi mdi-plus',
        callback: this.createNew,
        primary: true,
      },
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, {maxWait: 5000});
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    //this.reload();
    //this.filter = this.filterOptions.map((x) => x.value);
  },
  computed: {},
  methods: {
    async reload() {
      this.loading = true;
      try {
        this.pagedResult = await warehousereceipt.getPaged({
          searchString: this.searchString,
        }, this.options);
      } catch (e) {
        this.$showErrorNotification(this.$t('warehousereceipt.errors.error_loading_warehousereceipts'))
      }
      this.loading = false;
    },
    createNew() {
      this.$router.push({name: 'tenant_new_warehousereceipt'});
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    edit(/*project*/) {
      //this.$router.push({name: 'tenant_Xxxx', params: {id: project.id, tenantId: this.$tenant}});
    },
  },
  watch: {
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    searchString() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
ul.only-log {
  list-style: none;
}
</style>

<style lang="scss"></style>
