import axios from 'axios';
import Helper from '@/helper';
import i18n from "@/i18n";

export default {

  async getPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    return await axios({
      url: `/api/tenant/productionhistory/paged`,
      method: 'post',
      params: {...Helper.tableOptionsToRestPageParams(options)},
      data: filter,
    }).then(r => r.data);
  },
  async get(id) {
    return await axios({
      url: '/api/tenant/productionhistory/' + id,
    })
      .then(r => r.data)
      .then(r => {
        if (!r.visitingAddress) r.visitingAddress = {};
        if (!r.billingAddress) r.billingAddress = {};
        return r;
      });
  },
  async create(data) {
    return await axios({
      url: '/api/tenant/productionhistory',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async update(data) {
    return await axios({
      url: '/api/tenant/productionhistory/' + data.id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async delete(data) {
    return await axios({
      url: '/api/tenant/productionhistory/' + data.id,
      method: 'delete',
      data,
    }).then(r => r.data);
  },
  getTypes: function () {
    return [
      {text: i18n.t('production_history.types.START_PROJECT'), value: 'START_PROJECT'},
      {text: i18n.t('production_history.types.SHIFT_END'), value: 'SHIFT_END'},
      {text: i18n.t('production_history.types.PAUSE_PROJECT'), value: 'PAUSE_PROJECT'},
      {text: i18n.t('production_history.types.END_PROJECT'), value: 'END_PROJECT'},
      {text: i18n.t('production_history.types.LOSS'), value: 'LOSS'},
      {text: i18n.t('production_history.types.WAREHOUSE_RECEIPT'), value: 'WAREHOUSE_RECEIPT'},
    ]
  }

};
