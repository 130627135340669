<template>
  <div class="py-1 fill-height">
    <v-card color="project-card grey lighten-5 fill-height"
            elevation="2" @click="goToProject">
      <v-row no-gutters>
        <v-col :class="[showHandle ? 'handle' : '']"
               class="grey lighten-3 pa-0 d-flex align-center"
               style="max-width: 16px; width: 16px; max-height: 100%;">
          <v-icon v-if="showHandle" style="width: 14px;">mdi mdi-drag-vertical</v-icon>
        </v-col>
        <v-col>
          <v-card-title class="sans--text caption text-md-body-2 text-lg-body-1 font-weight-medium px-2 pt-2 pb-0"
                        style="line-height: 1.1rem;">
            <div style="word-break: break-word; width: 100%;">
              <v-row no-gutters>
                <status-chip :status="projectLog.status"/>
                <v-spacer></v-spacer>
                <div v-if="hasStartAndEndDate" class="caption text-right">

                  <div>
                    <template v-if="$vuetify.breakpoint.smAndUp && doneBeforeDeliveryDuration >= 0">
                      {{ $t('done') }}
                      <v-avatar :color="doneBeforeDeliveryDurationColor" class="ma-0 mt-n1 white--text" size="27">
                        {{ doneBeforeDeliveryDuration }}
                      </v-avatar>
                      {{ $t('production_timeline.before_delivery') }}
                    </template>
                    <template v-else-if="$vuetify.breakpoint.smAndUp && doneBeforeDeliveryDuration < 0">
                      {{ $t('production_timeline.will_be_late') }}
                      <v-avatar :color="doneBeforeDeliveryDurationColor" class="ma-0 mt-n1 white--text" size="27">
                        {{ doneBeforeDeliveryDuration * -1 }}
                      </v-avatar>
                      {{ $t('production_timeline.from_delivery') }}
                    </template>
                    <template v-else>
                      <v-avatar :color="doneBeforeDeliveryDurationColor" class="ma-0 mt-n1 white--text" size="27">
                        {{ doneBeforeDeliveryDuration }}
                      </v-avatar>
                    </template>
                  </div>

                </div>
              </v-row>
              <div class="mt-2">
                <span class="grey--text">{{ projectLog.project.code }}</span>
                {{ projectLog.project.name }}
              </div>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="py-0 px-2 caption mt-2">
            <div :key="projectLog.id">
              <div v-if="projectLog.log" :key="`${projectLog.id}`">
                <v-row no-gutters>
                  <v-col>
                    <strong>{{ projectLog.amount }} {{ projectLog.log.unit }}</strong> <span
                    v-if="projectLog.doneAmountPercent" class="font-italic">({{
                      $formatNumberInt(projectLog.doneAmountPercent)
                    }} %)</span>
                    {{ projectLog.log.description }}
                    {{ projectLog.log.description2 }}
                    <template v-if="projectLog.logSupplier">, <strong
                      class="grey--text text--darken-1">{{ projectLog.logSupplier }}</strong></template>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="projectLog.description" class="caption py-2">
              <v-icon :size="$vuetify.breakpoint.smAndDown ? '18' : '18'" class="mt-n1 mr-1">mdi mdi-information-outline
              </v-icon>
              {{ projectLog.description }}
            </div>

            <div v-if="projectLog.project.notice" class="font-italic mt-3 mt-md-2">
              <v-icon :size="$vuetify.breakpoint.smAndDown ? '18' : '18'" class="mt-n1 mr-1">mdi mdi-information-outline
              </v-icon>
              {{ projectLog.project.notice }}
            </div>

          </v-card-text>

          <v-card-text class="px-2 py-0 my-1 mt-2">
            <production-details-chip :check="projectLog.project.basicMeasurementGuide"
                                     :description="projectLog.project.basicMeasurementGuideDescription"
                                     :small="$vuetify.breakpoint.lgAndUp" :x-small="$vuetify.breakpoint.mdAndDown"
                                     title="PMO"/>
            <production-details-chip :check="projectLog.project.workPictures"
                                     :description="projectLog.project.workPicturesDescription"
                                     :small="$vuetify.breakpoint.lgAndUp" :x-small="$vuetify.breakpoint.mdAndDown"
                                     title="TYÖ"/>
            <production-details-chip :check="projectLog.project.constructionDrawings"
                                     :description="projectLog.project.constructionDrawingsDescription"
                                     :small="$vuetify.breakpoint.lgAndUp" :x-small="$vuetify.breakpoint.mdAndDown"
                                     title="RAK"/>
            <production-details-chip :check="projectLog.project.bvn" :description="projectLog.project.bvnDescription"
                                     :small="$vuetify.breakpoint.lgAndUp" :x-small="$vuetify.breakpoint.mdAndDown"
                                     title="BVN"/>
            <production-details-chip :check="projectLog.project.buildingPermission"
                                     :description="projectLog.project.buildingPermissionDescription"
                                     :small="$vuetify.breakpoint.lgAndUp" :x-small="$vuetify.breakpoint.mdAndDown"
                                     title="LUPA"/>
            <production-details-chip :check="projectLog.project.materialsList"
                                     :description="projectLog.project.materialsListDescription"
                                     :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                                     title="TAVL"/>
            <production-details-chip :check="projectLog.project.windowsAndDoors"
                                     :description="projectLog.project.windowsAndDoorsDescription"
                                     :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                                     title="I&O"/>
          </v-card-text>

          <v-card-text class="px-2 py-2 caption">
            <div>
              <v-row no-gutters>
                <v-col cols="8">
                  <template v-if="hasStartAndEndDate">{{ $t('production_timeline.production') }}:
                    <span class="text-uppercase">{{ $formatWeekDay(projectLog.productionStartDate) }} </span>
                    <span>{{ $formatDayMonth(projectLog.productionStartDate) }}</span>
                    -
                    <span class="text-uppercase">{{ $formatWeekDay(projectLog.productionEndDate) }} </span>
                    <span>{{ $formatDayMonth(projectLog.productionEndDate) }}</span>
                  </template>
                  <template v-if="deliveryDate">
                    <span :class="[hasStartAndEndDate ? 'ml-2' : '']">
                      {{
                        $t('production_timeline.delivery_date')
                      }}: {{ $formatDateNoTime(deliveryDate) }}</span>
                  </template>
                  <template v-if="projectLog.project.drawerName">
                    <span :class="[deliveryDate ? 'ml-2' : '']">
                      {{
                        $t('project.drawer_name_short')
                      }}: {{ projectLog.project.drawerName }}</span>
                  </template>
                  <template v-if="!projectLog.project.drawerName">
                    <span :class="[deliveryDate ? 'ml-2' : '', 'error--text']">
                      <v-icon color="error" size="18">mdi mdi-alert</v-icon> {{
                        $t('project.drawer_missing')
                      }}!</span>
                  </template>

                </v-col>
                <v-col class="text-right" cols="4">
                  {{ $t('production_timeline.production_time') }}:
                  {{ projectLog.productionTime ? `${projectLog.productionTime}h` : '-' }}
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import StatusChip from "@/views/tenant/ProductionTimelineView/components/StatusChip";
import ProductionDetailsChip from "@/views/tenant/ProductionTimelineView/components/ProductionDetailsChip";
import dayjs from "dayjs";

export default {
  name: "ProjectCard",
  components: {ProductionDetailsChip, StatusChip},
  props: {
    projectLog: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToProject() {
      this.$router.push({name: 'tenant_project', params: {id: this.projectLog.project.id}})
    },
  },
  computed: {
    showHandle() {
      return ['CANT_START', 'CAN_START', 'PAUSED'].includes(this.projectLog.status) && this.$isTenantAdmin
    },
    hasStartAndEndDate() {
      return this.projectLog.productionStartDate && this.projectLog.productionEndDate
    },
    deliveryDate() {
      // Support #138 Toimituspäivän muutos aikatauluun
      return this.projectLog.project.estimatedEnd || this.projectLog.project.deliveryDate
    },
    doneBeforeDeliveryDuration() {
      if (this.projectLog?.productionEndDate && this.deliveryDate) {
        return dayjs(this.deliveryDate).diff(dayjs(this.projectLog.productionEndDate), 'days');
      }
      return 0;
    },
    doneBeforeDeliveryDurationColor() {
      if (this.doneBeforeDeliveryDuration > 6) return 'grey';
      if (this.doneBeforeDeliveryDuration > 0) return 'orange';
      return 'red';
    },
  },
}
</script>

<style lang="scss" scoped>
.project-card {
  .handle {
    cursor: move;
  }
}
</style>
