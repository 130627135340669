<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">{{ $t('person.add_new') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5 gradient-icons">
        <v-row class="mb-3 mt-2">
          <v-col cols="12" lg="6" xl="6">
            <v-text-field
              :disabled="saving"
              outlined
              dense
              hide-details
              :label="$t('person.firstname')"
              light
              item-value=""
              autocomplete="off"
              clearable
              v-model="person.firstname"
            >
              <template v-slot:prepend>
                <v-icon>mdi mdi-account</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="6" xl="6">
            <v-text-field
              :disabled="saving"
              outlined
              dense
              hide-details
              :label="$t('person.lastname')"
              light
              item-value=""
              autocomplete="off"
              clearable
              v-model="person.lastname"
            >
              <template v-slot:prepend>
                <v-icon>mdi mdi-account</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          :disabled="saving"
          outlined
          dense
          hide-details
          class="mb-5"
          :label="$t('person.phone')"
          light
          item-value=""
          autocomplete="off"
          clearable
          v-model="person.phone"
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-phone</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          :disabled="saving"
          outlined
          dense
          hide-details
          class="mb-5"
          :label="$t('person.email')"
          light
          item-value=""
          autocomplete="off"
          v-model="person.email"
          clearable
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-email</v-icon>
          </template>
        </v-text-field>
        <party-combobox
          v-if="selectEmployer"
          :saving="saving"
          :value="person.employer"
          :parties="parties"
          :label="$t('person.employer')"
          icon="mdi-domain"
          @change="handleEmployerChange"
        />
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
          tabindex="6"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          color="default"
          @click.native="cancel()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-close</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          tabindex="5"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click="createPerson"
          :loading="saving"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-content-save</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import personApi from '@/api/person';
import partyApi from '@/api/party';
import PartyCombobox from '@/components/PartyCombobox';
export default {
  name: 'AddPersonDialog',
  components: { PartyCombobox },
  props: {
    manager: {
      type: Boolean,
      default: false,
    },
    selectEmployer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      personRole: null,
      type: null,
      parties: [],
      person: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        manager: this.manager,
        employer: null,
      },
    };
  },
  methods: {
    async open(employer, personRole, person = {}, type = null) {
      this.personRole = personRole;
      this.dialog = true;
      this.type = type;
      this.person = {
        ...{
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          manager: this.manager,
          employer: employer ? employer : null,
        },
        ...person,
      };
      this.parties = await partyApi.getParties();
    },
    handleEmployerChange(value) {
      this.person.employer = value;
    },
    async createPerson() {
      this.saving = true;
      const person = await personApi.createPerson(this.person);
      this.saving = false;
      this.$emit('person-created', person, this.personRole);
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
