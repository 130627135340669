<template>
  <v-tooltip top :disabled="!description">
    <template v-slot:activator="{on, attrs}">
      <v-chip v-on="on"
              v-bind="attrs"
              outlined
              color="grey darken-3"
              class="mr-1"
              :class="$vuetify.breakpoint.xsOnly && xSmall ? 'pl-2 pr-1' : 'pr-2'"
              :x-small="xSmall"
              :small="small"
              :style="{fontSize: $vuetify.breakpoint.xsOnly && xSmall ? '8px' : ''}">
        <v-icon v-if="check" :size="$vuetify.breakpoint.xsOnly && xSmall ? 10 : 12" color="green" left class="mr-0">mdi mdi-check</v-icon>
        <v-icon v-else :size="$vuetify.breakpoint.xsOnly && xSmall ? 10 : 12" color="red" left class="mr-0">mdi mdi-close</v-icon>
        {{ title }}
      </v-chip>
    </template>
    {{ description }}
  </v-tooltip>

</template>

<script>
export default {
  name: "ProductionDetailsChip",
  props: {
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    check: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
