<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
    persistent
  >
    <v-form ref="form" style="height: 100%">
      <v-card style="height: 100%">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{
              workTask && workTask.id ? $t('work_tasks.edit') : $t('work_tasks.new')
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="gradient-icons">
          <v-text-field
            v-model="workTask.title"
            :disabled="saving"
            :label="$t('work_tasks.subject')"
            autocomplete="off"
            class="mb-4"
            clearable
            dense
            hide-details
            light
            outlined
            tabindex="1"
            type="text"
            validate-on-blur
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-text-short</v-icon>
            </template>
          </v-text-field>
          <v-textarea
            v-model="workTask.message"
            :disabled="saving"
            :label="$t('work_tasks.message')"
            :rules="[$rules.required]"
            auto-grow
            autocomplete="off"
            class="mb-4 mt-4"
            clearable
            dense
            hide-details
            light
            outlined
            tabindex="2"
            type="text"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-message-text</v-icon>
            </template>
          </v-textarea>

          <v-autocomplete
            v-model="workTask.assignedUserId"
            :disabled="!($isAdmin || workTask.assignerUserId === $userInfo.id)"
            :item-text="
              item => {
                return `${item.lastName} ${item.firstName}`;
              }
            "
            :items="richerUsers"
            :label="$t('work_tasks.assigned_user')"
            class="mt-4"
            dense
            item-value="id"
            outlined
            tabindex="3"
            validate-on-blur
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-account</v-icon>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="workTask.object"
            :disabled="!!workTask.id && !!workTask._object"
            :items="objects"
            :label="$t('work_tasks.object')"
            class="mt-n3"
            dense
            item-text="label"
            item-value="value"
            outlined
            tabindex="4"
            validate-on-blur
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-package</v-icon>
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-model="workTask.status"
            :disabled="saving"
            :items="statuses"
            :label="$t('work_tasks.status.subtitle')"
            :rules="[$rules.required]"
            class="mb-4 mt-n2"
            dense
            item-text="desc"
            item-value="value"
            outlined
            tabindex="5"
            validate-on-blur
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-list-status</v-icon>
            </template>
          </v-autocomplete>

          <v-alert :value="showAlert" class="mt-4" outlined type="error">{{
              $t('validation.check_all_required_fields')
            }}.
          </v-alert>
        </v-card-text>

        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-actions>
          <div v-if="workTask.id && ($isAdmin || workTask.assignedUserId === $userInfo.id)" class="px-2"
               style="width: 100%">
            <v-btn :block="$vuetify.breakpoint.xsOnly" :disabled="saving" color="error" tabindex="5" text
                   @click="deleteWorkTask">
              <span>{{ $t('work_tasks.delete') }}</span>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            :bottom="$vuetify.breakpoint.xsOnly"
            :disabled="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
            tabindex="6"
            @click="dialog = false"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn
            :bottom="$vuetify.breakpoint.xsOnly"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :loading="saving"
            :right="$vuetify.breakpoint.xsOnly"
            color="primary"
            tabindex="7"
            @click="save"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import workTasksApi from '@/api/workTask';

export default {
  name: 'WorkTaskDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      showAlert: false,
      users: [],
      objects: [
        {
          label: this.$t('work_tasks.objects.time_entry'),
          value: 'timeEntry',
          disabled: true,
        },
        {
          label: this.$t('work_tasks.objects.service'),
          value: 'service',
        },
      ],

      statuses: [
        {desc: this.$t('work_tasks.status.RECEIVED'), value: 'RECEIVED'},
        {desc: this.$t('work_tasks.status.WAITING'), value: 'WAITING'},
        {desc: this.$t('work_tasks.status.INPROGRESS'), value: 'INPROGRESS'},
        {desc: this.$t('work_tasks.status.DONE'), value: 'DONE'},
      ],
      workTask: {
        title: '',
        message: '',
        status: 'RECEIVED',
        assignedUserId: null,
        assignerUserId: null,
        object: null,
        object_id: null,
      },
      workTaskTemplate: {
        title: '',
        message: '',
        status: 'RECEIVED',
        assignedUserId: null,
        assignerUserId: null,
        object: null,
        object_id: null,
      },
    };
  },
  mounted() {
  },
  computed: {
    richerUsers() {
      return this.$richerUsers(this.users);
    },
  },
  methods: {
    async deleteWorkTask() {
      const confirm = await this.$confirmDelete(this.$t('work_tasks.delete_dialog.title'), this.$t('work_tasks.delete_dialog.message'));
      if (confirm === true) {
        this.loading = true;
        workTasksApi.deleteWorkTask(this.workTask.id);
        this.loading = false;
        this.dialog = false;
        this.$showSuccessNotification(this.$t('work_tasks.deleted'));
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      if (!this.workTask.assignerUserId) {
        this.workTask.assignerUserId = this.$userInfo.id;
      }

      this.saving = true;
      if (this.workTask.id) {
        this.workTask = await workTasksApi.updateWorkTask(this.workTask.id, this.workTask);
      } else {
        this.workTask = await workTasksApi.createWorkTask(this.workTask);
      }
      this.$store.dispatch('reloadWorkTasks');
      this.$emit('save', this.workTask);

      this.saving = false;
      this.dialog = false;
    },
    async open(workTask) {
      this.dialog = true;
      this.workTask = {...workTask, _object: workTask.object};
      this.users = await this.$loadUsers();
    },
    async openNew() {
      this.dialog = true;
      this.workTask = Object.assign({}, this.workTaskTemplate);
      this.users = await this.$loadUsers();
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form?.reset();
        this.showAlert = false;

        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
