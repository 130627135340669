<template>
  <v-container class="white py-5" fill-height fluid>

    <div class="background-image"></div>

    <v-row v-if="!$userInfo" align="center" class="fill-height" no-gutters style="z-index: 1">
      <v-col class="login-holder px-xl-16" cols="12" lg="4" md="6" offset-lg="4" offset-md="3" offset-xl="4" xl="4">
        <div class="text-center pb-5">
          <v-avatar class="elevation-5" color="white" size="120">
            <img src="/img/logo.png" style="height: auto; width: 90%"/>
          </v-avatar>
        </div>

        <div class="headline font-weight-bold white--text text-center mb-8">{{ $t('title') }}</div>

        <v-row no-gutters>
          <v-card class="ma-0 pa-0" color="transparent" light elevation="0" style="width: 100%">
            <v-row>
              <template v-if="loginType === 'COGNITO'">
                <custom-sign-in
                  v-if="!isSignUp && !isForgotPassword && !isRequireNewPassword"
                  :is-confirm-signup="isConfirmSignUp"
                  :uiComponentProperties="uiComponentProperties"
                  v-bind:signInConfig="signInConfig"
                />

                <custom-require-new-password
                  v-if="isRequireNewPassword"
                  :uiComponentProperties="uiComponentProperties"
                  v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
                />

                <custom-forgot-password
                  v-if="isForgotPassword"
                  :uiComponentProperties="uiComponentProperties"
                  class="forgot-password"
                  v-bind:forgotPasswordConfig="forgotPasswordConfig"
                />
              </template>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import userApi from '@/api/user';
import UiEventBus from '../UiEventBus';
import UserService from '../service/user.service';
import {AmplifyEventBus, components as amplifyComponents} from 'aws-amplify-vue';
import CustomSignIn from '../components/Login/SignIn.vue';
import CustomForgotPassword from '../components/Login/ForgotPassword.vue';
import CustomRequireNewPassword from '../components/Login/RequireNewPassword.vue';

export default {
  name: 'LoginView',
  data() {
    return {
      loginType: 'COGNITO',
      loginTypes: [
        {value: 'COGNITO', text: 'Käyttäjätunnus ja salasana'},
      ],
      uiComponentProperties: {
        rounded: false,
        text: false,
        outlined: true,
        buttonColor: 'primary',
        buttonClass: '', // primary nappien luokat, esim. tekstin väri
        inputColor: 'primary lighten-5',
      },
      signInConfig: {
        header: this.$t('login.login'),
        isSignUpDisplayed: false,
      },
      signUpConfig: {
        defaultCountryCode: '358',
        signUpFields: [
          {
            label: this.$Amplify.I18n.get('Username'),
            key: 'username',
            required: true,
            type: 'string',
            displayOrder: 1,
          },
          {
            label: this.$Amplify.I18n.get('Email'),
            key: 'email',
            required: true,
            type: 'string',
            displayOrder: 2,
          },
        ],
        hiddenDefaults: ['phone_number', 'username'],
      },
      authConfig: {},
      forgotPasswordConfig: {},
      isSignUp: false,
      isConfirmSignUp: false,
      isForgotPassword: false,
      isRequireNewPassword: false,
    };
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {},
        signUpConfig: {},
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {},
      };
      return Object.assign(defaults, this.authConfig);
    },
  },
  methods: {
    async getCurrentUser() {
      try {
        const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.$store.commit('setLoadingOverlay', true);
        UserService.cognitoLogin(cognitoUser);
        const me = await userApi.getMe();
        UserService.setUserInfo(me);
        UiEventBus.$emit('loginSuccess');
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  created() {
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', state => {
      this.isRequireNewPassword = state === 'requireNewPassword';
      this.isSignUp = state === 'signUp';
      this.isConfirmSignUp = state === 'confirmSignUp';
      this.isForgotPassword = state === 'forgotPassword';
      if (state === 'signedIn') {
        this.getCurrentUser();
      }
    });
  },
  beforeDestroy() {
    AmplifyEventBus.$off('localUser');
    AmplifyEventBus.$off('authState');
  },
  mounted() {
    if (this.$isLoggedIn) {
      this.$store.commit('setLoadingOverlay', false);
      this.$router.push({name: 'start'});
    } else {
      if (this.$route?.query?.email_confirmed == "1") {
        this.$showSuccessNotification(this.$t('login.email_confirmed'));
      }
    }
  },
  components: {
    CustomSignIn,
    CustomForgotPassword,
    CustomRequireNewPassword,
    ...amplifyComponents,
  },
};
</script>

<style lang="scss" scoped>
.background-image {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-position: bottom right;
  opacity: 0.07;
}

@media only screen and (min-width: 600px) {
  .background-image {
    opacity: 0.2;
  }
}
</style>
