<template>
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="saving"
                :title="$t('user_management.edit_user_settings')">
    <v-card-text class="scroll-content">
      <v-form ref="form">
        <v-text-field
          v-model="currentUser.firstName"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_management.first_name')"
          class="mt-6 pt-4 firstname"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="currentUser.lastName"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_management.last_name')"
          class="lastname"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="currentUser.phone"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('user_management.phone')"
          class="phone"
          outlined
          type="tel"
          validate-on-blur
        ></v-text-field>
      </v-form>

      <v-alert :value="showAlert" class="mt-4" outlined type="error">{{
          $t('validation.check_all_required_fields')
        }}.
      </v-alert>
    </v-card-text>
  </basic-dialog>
</template>

<script>
import userApi from '@/api/user';
import BasicDialog from "@/components/BasicDialog";

export default {
  name: 'EditUserProfileDialog',
  components: {BasicDialog},
  data() {
    return {
      dialog: false,
      saving: false,
      currentUser: {},
      showAlert: false,
      actionButtons: {
        cancel: {
          click: this.cancel,
        },
        save: {
          click: this.saveUser,
        }
      }
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    async saveUser() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;

      try {
        const user = await userApi.updateUserInfo(
          this.currentUser.email,
          this.currentUser.firstName,
          this.currentUser.lastName,
          this.currentUser.phone
        );
        this.dialog = false;
        this.$emit('user-profile-updated', user);
        this.$showSuccessNotification(this.$t('user_management.user_info_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_info_update_failed'));
      }
      this.saving = false;
    },
    openUser(user) {
      this.dialog = true;
      this.currentUser = Object.assign({}, user);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.$refs.form.reset();
        this.currentUser = {};
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-content {
  max-height: calc(100vh - 240px);
  overflow: auto;
}

@media screen and (min-width: 600px) {
  .scroll-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
</style>
