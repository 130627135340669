<template>
  <hb-basic-page :fill-height="true" :loading="loading">
    <v-col class="pa-0 fill-height" cols="12">
      <v-row align="center" class="fill-height" no-gutters>
        <v-container fluid pa-0>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row align="center" justify="center" no-gutters>
                <div class="text-center px-5 pb-8 pb-md-16">
                  <div :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="font-weight-bold mt-5 mb-5">
                    {{ $t('access_denied.title') }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly ? 'subtitle-2' : 'subtitle-1'">
                    {{ $t('access_denied.text') }}
                  </div>
                  <div>
                    <v-btn class="mt-5" color="primary" @click="goBack">
                      <v-icon left>mdi mdi-arrow-left</v-icon>
                      {{ $t('access_denied.back') }}
                    </v-btn>
                  </div>
                  <div class="caption primary--text text--darken-1 mt-10">{{ $t('access_denied.contact_admin') }}</div>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-col>
  </hb-basic-page>
</template>

<script>
export default {
  name: "AccessDeniedView",
  data() {
    return {
      loading: true,
      imageIsLoaded: false,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.image-holder {
  position: relative;
  margin: auto;
  width: 45vw;
  height: 45vw;
}

@media screen and (min-width: 600px) {
  .image-holder {
    width: 15vw;
    height: 15vw;
  }
}
</style>
