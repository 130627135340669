import axios from 'axios';
/*
 * Used for tenant API calls.
 * for example: get tenants users, get tenants data
 *
 * X-TENANT-ID header required for ALL calls
 */

const tenantApi = {
  setSelectedTenant(tenant) {
    axios.defaults.headers.common['X-tenant-id'] = tenant;
  },
  async getCurrentTenant() {
    const response = await axios.get('/api/tenant');
    return response.data;
  },
  /**
   *
   * @param {!object} data
   * @param {!string} data.name
   * @returns {Promise<any>}
   */
  async updateCurrentTenant(data) {
    const response = await axios.post('/api/tenant', data);
    return response.data;
  },
  async getHellos() {
    const response = await axios.get('/api/tenant/hello');
    return response.data;
  },
  async addHello(msg) {
    const response = await axios.post('/api/tenant/hello', {msg});
    return response.data;
  },
  async deleteHello(helloId) {
    const response = await axios.delete(`/api/tenant/hello/${helloId}`);
    return response.data;
  },
  async loadTenantUsers() {
    const response = await axios.get('/api/tenant/user');
    return response.data;
  },
  /**
   *
   * @param {!Object} data
   * @param {!string} data.firstName
   * @param {!string} data.lastName
   * @param {!string} data.email
   * @returns {Promise<any>}
   */
  async addNewUserToTenant(data) {
    const response = await axios.post('/api/tenant/user', {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
    return response.data;
  },
  async removeUserFromTenant(userId) {
    const response = await axios.delete(`/api/tenant/user/${userId}`);
    return response.data;
  },
  async getRoles() {
    const response = await axios.get('/api/tenant/roles');
    return response.data;
  },
  async addRole(subject, role) {
    const response = await axios.post(`/api/tenant/user/${subject}/role/${role}`);
    return response.data;
  },
  async deleteRole(subject, role) {
    const response = await axios.delete(`/api/tenant/user/${subject}/role/${role}`);
    return response.data;
  },
  async updateUserDesignerMissingEmail(subject, enabled) {
    const response = await axios.post(`/api/tenant/user/${subject}/designerMissingEmail/${enabled}`);
    return response.data;
  }
};

export default tenantApi;
