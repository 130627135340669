<template>
  <div>
    <v-select v-if="solo" v-model="selectedCountry" :class="required ? 'required' : ''" :dark="dark"
              :dense="$vuetify.breakpoint.xsOnly"
              :items="countries"
              :label="$t('tenants.details.country')"
              :rules="rules"
              autocomplete="country"
              background-color="#f4f4f4"
              class="mt-2"
              item-text="name"
              item-value="code"
              outlined
              solo
              validate-on-blur>
    </v-select>
    <v-select v-else v-model="selectedCountry" :class="required ? 'required' : ''" :dark="dark"
              :dense="$vuetify.breakpoint.xsOnly"
              :items="countries"
              :label="$t('tenants.details.country')"
              :rules="rules"
              autocomplete="country"
              class="mt-2"
              item-text="name"
              item-value="code"
              outlined
              validate-on-blur>
    </v-select>
  </div>
</template>

<script>
import countries from "../config/countries";

export default {
  name: 'CountrySelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: [Array, String, Function],
    },
    required: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: [Boolean],
    },
    dark: {
      type: [Boolean],
    }
  },
  data() {
    return {
      selectedCountry: '',
    }
  },
  watch: {
    selectedCountry(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.selectedCountry = newValue;
    }
  },
  computed: {
    countries() {
      return countries;
    },
  },
  mounted() {
    if (this.value != null && this.value.length > 0) {
      this.selectedCountry = this.value;
    }
  }
}
</script>

<style scoped>

</style>
