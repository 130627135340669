<template>
  <v-col cols="12">
    <div>
      <v-alert
        v-if="error"
        :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : uiComponentProperties.outlined ? 'rounded-0' : ''"
        :text="uiComponentProperties.text"
        :value="true"
        class="text-left mb-5"
        type="error"
      >
        {{ error }}
      </v-alert>

      <v-alert
        v-if="isConfirmSignup && !confirmationSent"
        :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : uiComponentProperties.outlined ? 'rounded-0' : ''"
        :text="uiComponentProperties.text"
        :value="true"
        class="text-left mb-5"
        type="error"
      >
        <div>{{ $t('login.confirm_email') }}</div>
        <v-btn :loading="sendingConfirmation" class="mt-2" color="error" d outline small @click="sendConfirmation">{{
            $t('login.resend_confirmation')
          }}
        </v-btn>
      </v-alert>

      <v-alert
        v-if="confirmationSent"
        :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : uiComponentProperties.outlined ? 'rounded-0' : ''"
        :text="uiComponentProperties.text"
        :value="true"
        class="text-left mb-5"
        type="info"
      >
        <div>{{ $t('login.confirm_email_sent') }}</div>
      </v-alert>
    </div>

    <v-form>
      <v-text-field
        v-model="options.username"
        :color="uiComponentProperties.inputColor"
        :label="$t('login.username')"
        :outlined="uiComponentProperties.outlined"
        :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
        autocomplete="username"
        autofocus
        class="mb-4"
        hide-details
        tabindex="1"
        v-on:keyup.enter="signIn"
      />

      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"
        :color="uiComponentProperties.inputColor"
        :label="$t('login.password')"
        :outlined="uiComponentProperties.outlined"
        :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="current-password"
        class="mb-8"
        hide-details
        tabindex="2"
        @click:append="showPassword = !showPassword"
        v-on:keyup.enter="signIn"
      />

      <div class="text-center">
        <v-btn
          :class="uiComponentProperties.buttonClass"
          :color="uiComponentProperties.buttonColor"
          :loading="signingIn"
          :rounded="uiComponentProperties.rounded"
          block
          class="font-weight-bold"
          large
          tabindex="3"
          @click="signIn"
        >
          <v-icon left>mdi mdi-login</v-icon>
          {{ $t('login.sign_in') }}
        </v-btn
        >

        <v-btn
          :color="uiComponentProperties.buttonColor"
          :rounded="uiComponentProperties.rounded"
          block
          class="mt-3 font-weight-regular"
          small
          tabindex="4"
          text
          @click="forgot"
        >
          {{ $t('login.forgot_password') }}
        </v-btn>
      </div>
    </v-form>
  </v-col>
</template>

<script>
// import Auth from '@aws-amplify/auth';
import {AmplifyEventBus} from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'SignIn',
  props: ['signInConfig', 'isConfirmSignup', 'uiComponentProperties'],
  data() {
    return {
      sendingConfirmation: false,
      confirmationSent: false,
      password: '',
      error: '',
      amplifyUI: AmplifyUI,
      logger: {},
      signingIn: false,
      showPassword: false,
    };
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Sign In Account'),
        username: '',
        isSignUpDisplayed: true,
      };
      return Object.assign(defaults, this.signInConfig || {});
    },
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    async sendConfirmation() {
      this.sendingConfirmation = true;
      try {
        await this.$Amplify.Auth.resendSignUp(this.options.username);
        this.confirmationSent = true;
        this.setError(false);
      } catch (e) {
        this.setError(e);
      }
      this.sendingConfirmation = false;
    },
    async signIn(/*event*/) {
      this.options.username = this.options.username.toLowerCase().trim();
      this.signingIn = true;
      try {
        const data = await this.$Amplify.Auth.signIn(this.options.username, this.password);

        this.logger.info('sign in success');
        this.setError(false);
        try {
          this.$gtag.event('login', {method: 'Cognito'})
        } catch (e) {
          console.error(e)
        }
        if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
          AmplifyEventBus.$emit('localUser', data);
          return AmplifyEventBus.$emit('authState', 'confirmSignIn');
        } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
          AmplifyEventBus.$emit('localUser', data);
          return AmplifyEventBus.$emit('authState', 'requireNewPassword');
        } else if (data.challengeName === 'MFA_SETUP') {
          AmplifyEventBus.$emit('localUser', data);
          return AmplifyEventBus.$emit('authState', 'setMfa');
        } else {
          return AmplifyEventBus.$emit('authState', 'signedIn');
        }
      } catch (e) {
        if (e.code && e.code === 'UserNotConfirmedException') {
          AmplifyEventBus.$emit('localUser', {username: this.options.username});
          AmplifyEventBus.$emit('authState', 'confirmSignUp');
        } else {
          this.setError(e);
        }
      }
      this.signingIn = false;
    },
    forgot: function () {
      AmplifyEventBus.$emit('authState', 'forgotPassword');
    },
    signUp: function () {
      AmplifyEventBus.$emit('authState', 'signUp');
    },
    setError: function (e) {
      if (!e) {
        this.error = '';
        return;
      }
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
  },
};
</script>
