import Vue from 'vue';
import translationApi from '@/api/translation';
import UserService from '@/service/user.service';
import HelperPlugin from "@/plugins/helper.plugin";
import UiEventBus from "@/UiEventBus";

Vue.use(HelperPlugin);
export default new Vue({
  name: 'TranslationService',
  data() {
    return {
      translations: [],
      language: 'en',
      languages: ['en', 'fi'],
    };
  },
  computed: {
    ct() {
      const tids = {}

      this.translations.filter(t => t.language === this.language).forEach(t => {
        tids[t.tid] = t
      });
      return Array.from(new Set(this.translations.map(t => t.tid))).map(k => {
        return tids[k] || {
          tid: k,
          language: this.language,
          translation: this.translations.filter(t => t.tid === k && t.translation).map(t => [`[` + t.language + `]`, t.translation].join(': ')).join(" - ")
        };
      });
    },
    at() {
      const tl = {};
      [...this.translations].forEach(k => {
        const tid = k.tid;
        if (!tl[tid]) {
          tl[tid] = {};
        }

        tl[tid][k.language] = k.translation || this.translations.filter(t => t.tid === tid && t.translation).map(t => [`[` + t.language + `]`, t.translation].join(': ')).join(" - ");
      });
      Object.keys(tl).forEach(tid => {

        tl[tid]["translation"] = tl[tid][this.language] || this.translations.filter(t => t.tid === tid && t.translation).map(t => [`[` + t.language + `]`, t.translation].join(': ')).join(" - ");
      })
      return tl;
    },
    atArray() {
      return Object.keys(this.at).map(tid => {
        const t = {
          tid: tid,
          translation: this.at[tid]["translation"],
        }
        this.languages.forEach(l => {
          t[l] = this.at[tid][l];
        })


        return t;
      });
    },
  },
  methods: {
    t(tid) { // current translation
      const translation = this.ct.find(t => t.tid === tid);
      if (translation) {
        return translation.translation;
      }
      return tid;
    },
    alt(tid) { // all language translation
      const translation = this.translations.filter(t => t.tid === tid);
      return translation;
    },
    async getTranslations() {
      console.log('[TranslationService] Get translations');
      try {
        this.translations = await translationApi.getTranslations();
      } catch (e) {
        console.error(e);
      }
    },
    setNewTranslation(translation, language, prefix) {
      if (!translation) return;
      if (!language) language = this.language;
      const newTranslation = {
        language: language,
        translation: translation,
        tid: [prefix && prefix.substring(0, 255 - 33), this.$uuid()].filter(Boolean).join('.'),
        _new: true
      }
      this.translations.splice(0, 0, newTranslation);
      return newTranslation;
    },
    setTranslation(tid, translation, language) {
      if (!translation) return;
      if (!language) language = this.language;
      const idx = this.translations.findIndex(t => t.tid === tid && t.language === language);
      if (idx > -1) {
        this.translations[idx].translation = translation;
      }
      return this.translations[idx];
    },
    async saveNewTranslations() {

      const translationsToSave = this.translations.filter(t => t._new);
      if (translationsToSave.length > 0) {
        await translationApi.saveTranslations(translationsToSave);
        await this.getTranslations();
      }
    },
    init() {
      const userInfo = UserService.getUserInfo()
      if (userInfo?.language) this.language = userInfo?.language;

      if (userInfo) this.getTranslations();
    }
  },
  watch: {
    translations() {
      this.$emit('translations-changed');
    }
  },
  created() {
    UiEventBus.$on('userInfoChanged', this.init);
  },
  beforeDestroy() {
    UiEventBus.$off('userInfoChanged', this.init);
  },


});
