<template>
  <basic-button color="primary" v-bind="$attrs" v-on="$listeners">
    <v-icon v-if="$vuetify.breakpoint.smAndUp" left>{{ icon }}</v-icon>
    <span>{{ text || $t('save') }}</span>
  </basic-button>
</template>

<script>
import BasicButton from "@/components/BasicButton";

export default {
  name: "SaveButton",
  components: {BasicButton},
  props: {
    text: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: "mdi mdi-check"
    }
  }
}
</script>

<style scoped>

</style>
