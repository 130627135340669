<template>

  <tbody v-if="log">
  <tr>
    <th>{{ $t('project.logs.code') }}</th>
    <th colspan="2" style="min-width: 200px;">{{ $t('project.log') }}</th>
    <th>{{ $t('project.logs.log_supplier') }}</th>
    <th colspan="2">{{ $t('project.logs.profile') }}</th>
    <th colspan="2" style="min-width: 130px;">{{ $t('project.logs.amount') }}</th>
  </tr>
  <tr class="no-border">
    <td v-if="edit" colspan="3">
      <item-autocomplete v-model="log.log" :filter="logFilter" single-line/>
    </td>
    <td v-if="!edit">
      <div class="d-flex justify-space-between flex-column" style="height: 100%;">
        <span v-if="log.log">{{ log.log.code }}</span>
      </div>
    </td>
    <td v-if="!edit" colspan="2" style="min-width: 200px;">
      <span v-if="log.log">
        {{ log.log.description }} {{ log.log.description2 }}
      </span>
      <v-btn icon x-small @click="edit = !edit">
        <v-icon>mdi mdi-pencil</v-icon>
      </v-btn>
    </td>

    <td>
      <v-select
        v-model="log.logSupplier"
        :disabled="saving"
        :item-text="logSupplier => logSupplier.label"
        :items="logSuppliers"
        :label="$t('project.logs.log_supplier')"
        autocomplete="off"
        clearable
        dense
        hide-details
        light
        outlined
        single-line
      >
      </v-select>
    </td>
    <td colspan="2">
      <v-select
        v-model="log.profile"
        :disabled="saving"
        :item-text="profile => profile.label"
        :items="profiles"
        :label="$t('project.logs.profile')"
        autocomplete="off"
        clearable
        dense
        hide-details
        light
        outlined
        single-line
      >
      </v-select>
    </td>
    <td colspan="2" style="white-space: nowrap;">
      <v-text-field
        v-model.number="log.amount"
        :disabled="saving"
        :label="$t('project.logs.amount')"
        :readonly="false"
        :title="log?.offeredAmount ? $t('project.logs.offered_amount_x', {amount: log?.offeredAmount}) : $t('project.logs.no_offered_amount')"
        autocomplete="off"
        class="custom-input number-field"
        dense
        hide-details
        inputmode="decimal"
        light
        outlined
        single-line
        type="number"
        validate-on-blur
        @blur="onBlurAmount"
      >
        <template v-slot:append>
          <span v-if="log.log">&nbsp;{{ log.log.unit }}</span>
        </template>
      </v-text-field>
    </td>
  </tr>
  <tr class="no-border">
    <td v-if="!edit">
      <div class="d-flex justify-space-between flex-column">
        <span v-if="logBlank" class="log-blank">{{ logBlank.code }}</span>
      </div>
    </td>
    <td v-if="!edit" colspan="2" style="min-width: 200px;">
      <span v-if="logBlank" class="log-blank">{{ logBlank.description }} {{ logBlank.description2 }}</span>
      <div v-if="log.productionStartDate" :title="$t('project.logs.productionDate')" class="second-row-text">
        {{ $formatDateNoTime(log.productionStartDate) }} - {{
          $formatDateNoTime(log.productionEndDate)
        }}
      </div>
    </td>
    <td v-if="edit" colspan="3" style="min-width: 200px;">
      <v-btn :loading="loadingDelete" color="error" icon @click="deleteLog">
        <v-icon>mdi mdi-delete</v-icon>
      </v-btn>
    </td>
    <td>
      <v-textarea
        v-model.number="log.description"
        :disabled="saving"
        :label="$t('project.logs.description')"
        :readonly="false"
        auto-grow
        autocomplete="off"
        class="mt-2 mb-1"
        dense
        hide-details
        light
        outlined
        rows="1"
      >

      </v-textarea>
    </td>
    <td colspan="2">
      <v-text-field
        v-model.number="log.productionTime"
        :disabled="saving"
        :label="$t('project.production_time')"
        :readonly="false"
        autocomplete="off"
        class="custom-input number-field"
        dense
        hide-details
        inputmode="decimal"
        light
        outlined
        type="number"
        validate-on-blur
      >
        <template v-slot:append>
          &nbsp;h
        </template>
      </v-text-field>
    </td>
    <td colspan="2" style="white-space: nowrap;">

      <v-autocomplete
        v-model="log.status"
        :disabled="saving"
        :items="selectableStatuses"
        :label="$t('project.status._')"
        :readonly="false"
        autocomplete="off"
        dense
        hide-details
        item-text="label"
        item-value="value"
        light
        outlined
      >
      </v-autocomplete>
    </td>
  </tr>
  <template v-if="loadingProductionHistories">
    <tr>
      <td colspan="1">
        <v-skeleton-loader type="table-cell"/>
      </td>
      <td colspan="1">
        <v-skeleton-loader type="table-cell"/>
      </td>
      <td colspan="1">
        <v-skeleton-loader type="table-cell"/>
      </td>
      <td colspan="1">
        <v-skeleton-loader type="table-cell"/>
      </td>
      <td colspan="2">
        <v-skeleton-loader type="table-cell"/>
      </td>
      <td colspan="1">
        <v-skeleton-loader type="table-cell"/>
      </td>
      <td colspan="1">

      </td>
    </tr>
  </template>
  <template v-if="!loadingProductionHistories && productionHistories && productionHistories.length > 0">
    <tr>
      <th>{{ $t('production_history.shift_initials') }}</th>
      <th>{{ $t('production_history.meters') }}</th>
      <th>{{ $t('production_history.start_date') }}</th>
      <th>{{ $t('production_history.end_date') }}</th>
      <th colspan="2">{{ $t('production_history.notice') }}</th>
      <th>{{ $t('production_history.type') }}</th>
      <th></th>
    </tr>
    <tr v-for="ph in productionHistories" :key="ph.id">
      <td>
        {{ ph.shiftInitials }}
      </td>
      <td style="min-width: 100px">{{ ph.meters }}
        <template v-if="ph && ph.log && ph.log.unit">{{ ph.log.unit }}</template>
      </td>
      <td>
        <span v-if="ph.type === 'LOSS' || ph.type === 'WAREHOUSE_RECEIPT'"></span>
        <span
          v-else>{{
            $formatDateTime(ph.startTime)
          }}</span>
      </td>
      <td><span v-if="ph.type !== 'START_PROJECT'">{{ $formatDateTime(ph.endTime) }}</span></td>
      <td colspan="2">
        <div v-if="ph.logHeight || ph.logWidth ">
          <strong>{{ $t('production_history.log_width') }} x {{
              $t('production_history.log_height')
            }}:</strong> {{ ph.logHeight }} mm x {{ ph.logWidth }} mm
        </div>
        <div v-if="ph.humidity">
          <strong>{{ $t('production_history.humidity') }}:</strong> {{ ph.humidity }}%
        </div>
        <div v-if="ph.logCentering != null" :class="[!ph.logCentering && 'error--text']">
          <strong>{{ $t('production_history.log_centering') }}:</strong>
          {{ ph.logCentering ? $t('ok') : $t('not_ok') }}
        </div>
        <div v-if="ph.cornerMeasurement != null" :class="[!ph.cornerMeasurement && 'error--text']">
          <strong>{{ $t('production_history.corner_measurement') }}:</strong>
          {{ ph.cornerMeasurement ? $t('ok') : $t('not_ok') }}
        </div>
        <div v-if="ph.cornerTightness != null" :class="[!ph.cornerTightness && 'error--text']">
          <strong>{{ $t('production_history.corner_tightness') }}:</strong>
          {{ ph.cornerTightness ? $t('ok') : $t('not_ok') }}
        </div>
        <div v-if="ph.notice != null">
          {{ ph.notice }}
        </div>


      </td>
      <td colspan="2">
        <div class="d-flex flex-row">
          <div>{{ getProductionHistoryType(ph.type) }}</div>

          <v-spacer/>
          <v-btn icon small @click="editProductionHistory(ph)">
            <v-icon small>mdi mdi-pencil</v-icon>
          </v-btn>

        </div>
      </td>
    </tr>

  </template>

  </tbody>
</template>
<script>
import ItemAutocomplete from "@/components/Items/ItemAutocomplete.vue";
import productionhistory from "@/api/productionhistory";
import projectApi from "@/api/project";

let DEFAULT_PRODUCTION_LENGTH_PER_HOUR = 18;
export default {
  name: 'ProjectLogRow',
  components: {ItemAutocomplete},
  emits: ['edit-production-history'],
  data() {
    return {
      log: null,
      edit: false,
      productionHistories: [],
      loadingProductionHistories: false,
      loadingDelete: false,
    }
  },
  props: {
    logSuppliers: {},
    profiles: {},
    project: {},
    saving: {},
    value: {},
    statuses: {},

  },
  computed: {
    productionHistoryTypes() {
      return productionhistory.getTypes();
    },
    logFilter() {
      return {itemGroupCodes: this.$store.state.logGroupCodes}
    },
    logBlank() {
      return this.log?.log?.subItems?.find(item => this.$store.state.logBlankGroupCodes.includes(item?.group?.code));
    },
    logStatusName() {
      return this.statuses?.find(s => s.value === this.log.status)?.label
    },
    selectableStatuses() {
      const statuses = structuredClone([...this.statuses]);
      const projectStatusIndex = statuses.findIndex(s => s.value === this.project.status);
      return statuses?.map((s, i) => {
        if (i < projectStatusIndex) {
          s.disabled = true;
        }
        return s;
      });
    }
  },
  methods: {
    editProductionHistory(ph) {
      this.$emit('edit-production-history', ph);
    },

    getProductionHistoryType(type) {
      return this.productionHistoryTypes.find(t => t.value === type)?.text;
    },
    async getProductionHistory() {
      if (!this.log?.id) return null;
      this.loadingProductionHistories = true;
      try {
        const productionHistories = await productionhistory.getPaged({searchString: null, projectLogId: this.log?.id}, {
          page: 1,
          itemsPerPage: 999
        })
        this.productionHistories = productionHistories?.content;
      } catch (e) {
        console.error(e);
        this.$handleApiError(e, this.$t('production_history.errors.error_loading_production_histories'));
      }
      this.loadingProductionHistories = false;
    },
    onBlurAmount() {
      if (!this.log.productionTime) {
        this.log.productionTime = parseInt(Math.ceil(Math.ceil(this.log.amount) / DEFAULT_PRODUCTION_LENGTH_PER_HOUR));
      }
    },
    async deleteLog() {
      const c = await this.$confirmDelete(this.$t('project.logs.delete_log'), this.$t('project.logs.delete_log_text'));
      if (c) {
        this.loadingDelete = true;
        try {
          if (this.log.id && this.log.id > 0) {
            await projectApi.deleteLog(this.log?.projectId, this.log?.id);
          }
          this.$emit('delete-log', this.log);
        } catch (e) {
          console.error(e);
          this.$handleApiError(e, this.$t('project.logs.errors.error_deleting_log'));
        }
        this.loadingDelete = false;
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.log = this.value;
      },
      deep: true
    },
    [`log.log`](val) {
      if (val) this.edit = false;
    },
    log() {
      this.$emit('input', this.log);

    }
  },
  mounted() {
    this.log = this.value;
    this.getProductionHistory();
  }
}
</script>

<style lang="scss" scoped>
.log-blank, .second-row-text {
  color: #7c7c7c;
  display: block;
}

.no-border {
  border-bottom: none !important;
}

tbody > tr:first-child > th {
  border-top: 1px solid black !important;
}
</style>
