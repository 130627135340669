<template>
  <v-form v-if="translationEditDialog || translation && translation.tid" ref="form">
    <v-text-field
      v-if="translation" v-model="translation.tid" :disabled="isTidDisabled" :label="$tc('translations.tid')"
      :readonly="isTidDisabled"/>

    <template v-for="language in languages">
      <v-textarea
        :key="language"
        v-model="translation[language]"
        :label="$t(`translations.languages.${language}`)"
        @focus="dirty = true"

      ></v-textarea>
    </template>
  </v-form>
</template>

<script>

//:rules="[v => !!v || $t('translations.translation_required')]"
import TranslationService from "@/service/translation.service";

export default {
  name: "TranslationEditForm",
  data() {
    return {
      originalTid: null,
      translation: this.value /*{
        tid: null,
        ...TranslationService.languages.reduce((acc, curr) => (acc[curr] = '', acc), {})
      }*/,
      dirty: false,
    }
  },
  computed: {
    languages() {
      return TranslationService.languages;
    },
    isTidDisabled() {
      return this.originalTid != null;
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    translationEditDialog: Boolean,
  },
  watch: {
    value(value) {
      this.translation = value;
    },

    translation(translation) {
      this.$emit('input', translation);
    },
  },
  methods: {},
  created() {
    this.originalTid = this.translation.tid
  },

}
</script>

<style scoped>

</style>
