<template>
  <hb-basic-page
    v-if="$isTenantAdmin || $isTenantBasic"
    :fill-height="true"
    :search-title="$t('production.search')"
    :subtle-loading="subtleLoading"
    :title="$tc('production.title', 2)"
    class="production-view"
    fluid
    @handle-search="handleSearch"
  >
    <v-col :style="{ marginBottom: $vuetify.breakpoint.xsOnly ? '76px' : '68px' }" class="pa-0 fill-height" cols="12">
      <v-row class="fill-height">
        <v-col
          class="fill-height"
          lg="10"
          md="12"
          offset-lg="1"
          offset-md="0">
          <v-fade-transition group hide-on-leave>
            <template v-if="loading">
              <project-card-skeleton-loader v-for="i in [1,2,3]" :key="i"/>
            </template>
          </v-fade-transition>

          <v-fade-transition group hide-on-leave>
            <template v-if="!loading">
              <project-card v-for="projectLog in projectLogs"
                            :key="projectLog.id"
                            ref="projectCard"
                            :can-start-new-project="canStartNewProject"
                            :production-line="productionLine"
                            :project-log="projectLog"
                            class="list-group-item"
                            @saved="reload"
                            @start-project="startProject"
                            @end-project="endProject"
                            @shift-change="shiftChange"
                            @pause-project="pauseProject"
              ></project-card>

              <v-row v-if="!loading && projectLogs.length === 0" key="nocontent" align="center" class="fill-height"
                     justify="center" no-gutters>
                <no-content-view :class="$vuetify.breakpoint.smAndUp ? 'pt-10' : ''"
                                 :description="$t('production_timeline.no_projects_found_description')"
                                 :function-button="true"
                                 :function-button-text="$t('production_timeline.reload_projects')"
                                 :prop-function="reload"
                                 :reload-button="false"
                                 :title="$t('production_timeline.no_projects_found')"
                                 dense
                                 function-button-icon="mdi-refresh"
                                 title-icon="mdi mdi-store-24-hour"/>
              </v-row>

            </template>
          </v-fade-transition>
          <project-blank-warehouse-receipt-dialog
            ref="projectProcessedDialog"

            @close="reloadProductionHistoryForProjectCard"
          ></project-blank-warehouse-receipt-dialog>
          <production-history-dialog ref="productionHistoryDialog"
                                     @close="reloadProductionHistoryForProjectCard"
                                     @end-project="endProject2"
          ></production-history-dialog>
        </v-col>
      </v-row>
    </v-col>
  </hb-basic-page>
</template>

<script>
import projectApi from "@/api/project";
import productionLineApi from "@/api/productionline";
import ProjectCardSkeletonLoader from "@/views/tenant/ProductionTimelineView/components/ProjectCardSkeletonLoader";
import ProjectCard from "@/views/tenant/ProductionView/components/ProjectCard";
import NoContentView from "@/components/NoContentView";
import orderBy from "lodash/orderBy";
import ProjectBlankWarehouseReceiptDialog from "@/components/Project/ProjectBlankWarehouseReceiptDialog.vue";
import ProductionHistoryDialog from "@/components/Production/ProductionHistoryDialog.vue";

export default {
  name: "Production",
  components: {
    ProductionHistoryDialog,
    ProjectBlankWarehouseReceiptDialog, NoContentView, ProjectCard, ProjectCardSkeletonLoader
  },
  data() {
    return {
      loading: true,
      options: {},
      pagedResult: {},
      searchString: '',
      projectLogs: [],
      saving: false,
      subtleLoading: false,
      productionLines: [],
      productionLine: null,
    };
  },
  methods: {
    /*startProject(projectLog) {
      //this.$refs.projectProcessedDialog.open(projectLog);
    },*/
    handleSearch(searchString) {
      this.searchString = searchString;
      this.reload();
    },
    shiftChange(projectLog, type) {
      this.$refs.productionHistoryDialog.open(projectLog, type);
    },
    pauseProject(projectLog) {
      this.$refs.productionHistoryDialog.open(projectLog, "PAUSE_PROJECT");
    },
    endProject(projectLog) {
      this.$refs.productionHistoryDialog.open(projectLog, "END_PROJECT");
    },
    endProject2(projectLog) { // Kutsutaan heti tuotantoilmoituksen dialogin sulkemisen jälkeen.
      this.$refs.projectProcessedDialog.open(projectLog);
    },
    async reloadProductionHistoryForProjectCard(projectLog) {
      await this.reload();
      this.$nextTick(() => {
        const ref = this.$refs?.projectCard?.find(p => p?.projectLog?.id === projectLog?.id);
        if (ref) {
          ref.getProductionHistory();
        }
      })

    },
    startProject(projectLog) {
      this.$refs.productionHistoryDialog.open(projectLog, "START_PROJECT");
    },
    async reload() {
      if (this.loading === false) {
        this.subtleLoading = true;
      }
      try {
        const projectLogs = (await projectApi.getLogsPaged({
          searchString: this.searchString,
          done: false,
          //status: ["CANT_START", "CAN_START", "MANUFACTURING"],
          noScheduling: false,
          status: ["CANT_START", "CAN_START", "PAUSED", "MANUFACTURING"]
        }, {page: 1, itemsPerPage: 1000}))?.content;
        this.projectLogs = orderBy(projectLogs.filter(p => p.productionStartDate && p.productionTime != null), ['productionStartDate'], ['asc']);

        const productionLines = await productionLineApi.getProductionLines();
        this.productionLine = productionLines[0];
        this.productionLines = productionLines;
      } catch (e) {
        this.$showErrorNotification(this.$t('projects.errors.error_loading_projects'));
      }
      this.subtleLoading = false;
      this.loading = false;
    },
  },
  computed: {
    canStartNewProject() {
      return this.projectLogs.findIndex(p => p.status === 'MANUFACTURING') === -1;
    },
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_basic'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    this.reload();

  }
}
</script>

<style scoped>

</style>
