<template>
  <router-view></router-view>
</template>

<script>
import tenantApi from '../api/tenant';
export default {
  props: {
    tenantId: {
      type: String,
    },
  },
  created() {
    tenantApi.setSelectedTenant(this.tenantId);
  },
};
</script>

<style scoped></style>
