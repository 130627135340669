<template>
  <v-card elevation="0" class="mb-2 grey lighten-5">
    <v-card-text class="pa-2">
      <v-row no-gutters justify="space-between">
        <div style="width: 30%;">
          <v-skeleton-loader type="text" />
        </div>
        <div style="width: 30%;">
          <v-skeleton-loader type="text" />
        </div>
      </v-row>
    </v-card-text>
    <v-card-text class="pa-2 pt-0 pb-2">
      <v-skeleton-loader
        max-width="100%"
        type="paragraph"
      ></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProjectCardSkeletonLoader"
}
</script>

<style scoped>

</style>
