<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('user_management.edit_user_settings') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <!--            :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"-->
          <v-text-field
            :disabled="true"
            validate-on-blur
            class="mt-5 email"
            :label="$t('user_management.email')"
            autocomplete="username"
            type="text"
            v-model="currentUser.email"
          ></v-text-field>

          <v-text-field
            class="mt-5 firstname"
            :label="$t('user_management.first_name')"
            validate-on-blur
            type="text"
            v-model="currentUser.firstName"
          ></v-text-field>

          <v-text-field
            class="mt-5 lastname"
            :label="$t('user_management.last_name')"
            validate-on-blur
            type="text"
            v-model="currentUser.lastName"
          ></v-text-field>

          <v-text-field
            class="mt-5 phone"
            :label="$t('user_management.phone')"
            validate-on-blur
            type="tel"
            v-model="currentUser.phone"
          ></v-text-field>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
          color="default"
          class="ma-1"
          @click="dialog = false"
          :disabled="saving"
          :large="$vuetify.breakpoint.xsOnly"
          :block="$vuetify.breakpoint.xsOnly"
        >
          <v-icon left>close</v-icon>
          <span>{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="ma-1"
          @click="saveUser"
          :loading="saving"
          :large="$vuetify.breakpoint.xsOnly"
          :block="$vuetify.breakpoint.xsOnly"
        >
          <v-icon left>check</v-icon>
          <span>{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import adminApi from '../../api/admin';

export default {
  name: 'UserDetailsDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      currentUser: {},
      showAlert: false,
    };
  },
  methods: {
    async saveUser() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;

      try {
        await adminApi.updateUser(this.currentUser.subject, {
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          phone: this.currentUser.phone,
        });
        this.dialog = false;
        this.$showSuccessNotification(this.$t('user_management.user_info_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_info_update_failed'));
      }
      this.saving = false;
    },
    openUser(user) {
      this.dialog = true;
      this.currentUser = Object.assign({}, user);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.$refs.form.reset();
        this.currentUser = {};
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
