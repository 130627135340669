<template>
  <hb-basic-page
    :title="$tc('party.title', 2)"
    fluid
    :loading="false"
    :fill-height="true"
    :search-title="`${$t('party.search')}...`"
    @handle-search="debouncedSearch"
    class="parties-view"
    :no-gutters="true"
    v-if="$isTenantAdmin"
    :title-button="titleButton"
    :action-buttons="$vuetify.breakpoint.smAndDown ? { add: titleButton } : {}"
    :extension-height="56"
  >
    <template v-slot:app-bar-extension>
      <checkbox-filter
        :filter="filter"
        :filter-options="filterOptions"
        :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '44%' : '' }"
        @change="handleFilterChange"
        @blur="reloadParties"
      />
    </template>
    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="pagedResult.content"
              class="h-100 gradient-info__table"
              :options.sync="options"
              :server-items-length="pagedResult.totalElements"
              :loading="loading"
              :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
              :items-per-page="50"
              @click:row="editParty"
              style="cursor: pointer"
            >
              <template v-slot:item.type="{ item }"> {{ $t(`party.types.${item.type}`) }} </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import partyApi from '@/api/party';
import debounce from 'lodash.debounce';
import CheckboxFilter from '@/components/CheckboxFilter';
export default {
  name: 'PartiesView',
  components: { CheckboxFilter },
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      headers: [
        {
          text: this.$t('party.company_name'),
          value: 'companyName',
        },
        {
          text: this.$t('party.business_code'),
          value: 'businessCode',
        },
        {
          text: this.$t('party.type'),
          value: 'type',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      filter: ['customer', 'supplier', 'employer', 'contractor'],
      filterOptions: [
        { desc: this.$t('party.types.customer'), value: 'customer' },
        { desc: this.$t('party.types.supplier'), value: 'supplier' },
        { desc: this.$t('party.types.employer'), value: 'employer' },
        { desc: this.$t('party.types.contractor'), value: 'contractor' },
      ],
      parties: [],
      titleButton: {
        text: this.$t('party.buttons.new_party'),
        icon: 'mdi mdi-plus',
        callback: this.createNewParty,
        primary: true,
      },
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, { maxWait: 5000 });
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    //this.reloadParties();
  },
  methods: {
    async reloadParties() {
      this.loading = true;
      this.pagedResult = await partyApi.getPartiesPaged({ search: this.searchString, types: this.filter }, this.options);
      this.loading = false;
    },
    createNewParty() {
      this.$router.push({ name: 'tenant_new_party' });
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    editParty(party) {
      this.$router.push({ name: 'tenant_party', params: { id: party.id, tenantId: this.$tenant } });
    },
  },
  watch: {
    options: {
      handler() {
        this.reloadParties();
      },
      deep: true,
    },
    searchString() {
      this.reloadParties();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss"></style>
