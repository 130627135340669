<template>
  <v-row :class="[value && value._deleted && 'deleted-row']">
    <v-col cols="12">
      <v-autocomplete
        :disabled="disabled"
        :items="filteredItems"
        :label="label || $tc('item.title', 1)"
        :loading="loading"
        :readonly="readonly"
        :search-input.sync="searchString"
        :single-line="singleLine"
        :value="value"
        dense
        hide-details
        item-value="id"
        outlined
        persistent-placeholder
        return-object
        @input="$emit('input', $event)"
      >
        <!--<template v-slot:append-outer>
          <v-btn
            :disabled="loading"
            class="mt-n2"
            icon
            @click="$emit('remove', value)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>-->
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import itemApi from "@/api/item";
import {debounce} from "lodash";

export default {
  name: 'ItemAutocomplete',
  props: {
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    filter: {
      type: Object,
      required: false,
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      valueCandidates: [],
      loading: false,
      searchString: null,
    }
  },
  computed: {
    filteredItems() {
      return [this.value, ...this.valueCandidates].filter(Boolean).map(item => ({
        ...item,
        text: [item.code, [item.description, item.description2].filter(Boolean).join(' ')].filter(Boolean).join(' - '),
      }))
    }
  },
  watch: {
    searchString(val) {
      if (val?.length > 2) {
        this.getItemCandidates(val);
      }
    }
  },
  methods: {
    async getItemCandidates(searchString) {
      this.loading = true;
      try {
        const filter = {
          searchString,
          ...(this.filter || {}),
        };
        const response = await itemApi.getPaged(filter, {page: 1, itemsPerPage: 100});
        this.valueCandidates = response.content;
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loading = false;
    }
  },
  created() {
    this.getItemCandidates = debounce(this.getItemCandidates, 500);
  },
  mounted() {
    this.getItemCandidates(null);
  }
}
</script>

<style lang="scss" scoped>

</style>
