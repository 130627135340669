<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('tenants.tenant_users') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="loading" style="padding: 45px">
        <hb-loading-indicator align-middle/>
      </v-card-text>

      <v-fade-transition>
        <div v-if="!loading">
          <v-card-text class="pa-0">
            <v-text-field
              v-model="searchPhrase"
              :placeholder="`${$t('user_management.search')}...`"
              class="users-search-input pt-0"
              clearable
              full-width
              hide-details
              single-line
            >
              <v-icon slot="append" style="cursor: pointer">search</v-icon>
            </v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pa-0">
            <v-row class="users-wrapper ma-0" no-gutters>
              <v-col class="users-list app-users" cols="12" order="2" order-sm="1" sm="6">
                <v-list color="transparent">
                  <v-subheader>{{ $t('tenants.all_users_for_app') }}</v-subheader>
                  <template v-if="filteredAllUsers.length > 0">
                    <v-list-item v-for="user in filteredAllUsers" :key="`${user.id}_app`">
                      <v-list-item-action>
                        <v-btn icon @click="addUserToTenant(user)">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ user.email ? user.email : user.subject }}</v-list-item-title>
                        <v-list-item-subtitle v-if="user.firstName && user.lastName"
                        >{{ user.firstName }} {{ user.lastName }}
                        </v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-alert :value="true" class="ma-4" dense text type="info">
                      {{ $t('tenants.no_users_in_app') }}
                    </v-alert>
                  </template>
                </v-list>
              </v-col>
              <v-col class="users-list tenant-users" cols="12" order="1" order-sm="2" sm="6">
                <v-list color="transparent">
                  <v-subheader>{{ $t('tenants.tenant_users') }}</v-subheader>
                  <template v-if="filteredTenantUsers.length > 0">
                    <v-list-item v-for="user in filteredTenantUsers" :key="`${user.id}_tenant`">
                      <v-list-item-action v-if="$vuetify.breakpoint.xsOnly">
                        <v-btn icon @click="removeUserFromTenant(user)">
                          <v-icon color="warning">remove_circle_outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ user.email ? user.email : user.subject }}</v-list-item-title>
                        <v-list-item-subtitle v-if="user.firstName && user.lastName"
                        >{{ user.firstName }} {{ user.lastName }}
                        </v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                        <v-btn icon @click="removeUserFromTenant(user)">
                          <v-icon color="warning">remove_circle_outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-alert :value="true" class="ma-4" dense text type="info">
                      {{ $t('tenants.no_users_in_tenant') }}
                    </v-alert>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-fade-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import adminApi from '../../api/admin';

export default {
  name: 'UsersDialog',
  data() {
    return {
      dialog: false,
      loading: false,
      currentTenant: {},
      allUsers: [],
      tenantUsers: [],
      searchPhrase: '',
    };
  },
  methods: {
    async open(tenant) {
      this.dialog = true;
      this.currentTenant = Object.assign({}, tenant);
      this.loading = true;
      await this.getTenantUsers();
      await this.getAllUsers();
      this.loading = false;
    },
    async getTenantUsers() {
      try {
        const users = await adminApi.getTenantUsers(this.currentTenant.id);
        this.tenantUsers = users;
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async getAllUsers() {
      try {
        const allUsers = await adminApi.getAllUsers();
        allUsers.forEach(user => {
          user.isInTenant = this.tenantUsers.findIndex(tu => tu.id === user.id) !== -1;
        });
        this.allUsers = allUsers;
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async addUserToTenant(user) {
      try {
        await adminApi.addUserToTenant(user.id, this.currentTenant.id);
        user.isInTenant = true;
        this.tenantUsers.push(user);
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async removeUserFromTenant(user) {
      try {
        const userIndex = this.tenantUsers.findIndex(u => u.id === user.id);
        if (userIndex > -1) {
          await adminApi.removeUserFromTenant(user.id, this.currentTenant.id);
          const allUsersUser = this.allUsers.find(u => u.id === user.id);
          allUsersUser.isInTenant = false;
          this.tenantUsers.splice(userIndex, 1);
        }
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    filterUsers(users, filterOutIsInTenant) {
      const filteredUsers = users;

      const searchPhraseLower = this.searchPhrase !== null ? this.searchPhrase.toLowerCase() : '';
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredUsers.filter(user => {
        let found = [];
        const lowerSearchedString = (user.email ? user.email.toLowerCase() : '') + (user.subject ? user.subject.toLowerCase() : '');
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });
        if (filterOutIsInTenant && user.isInTenant) found.push(false);
        return found.indexOf(false) === -1;
      });
    },
  },
  computed: {
    filteredAllUsers() {
      return this.filterUsers(this.allUsers, true);
    },
    filteredTenantUsers() {
      return this.filterUsers(this.tenantUsers, false);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.currentTenant = {};
        this.tenantUsers = [];
        this.allUsers.map(user => (user.isInTenant = false));
        this.searchPhrase = '';
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 599px) {
  .app-users {
    height: calc(100vh - 320px);
  }
  .tenant-users {
    height: 200px;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .users-list {
    overflow: auto;
  }
}

@media only screen and (min-width: 600px) {
  .users-wrapper {
    height: 400px;
  }

  .users-list {
    height: 400px;
    overflow: auto;
  }

  .app-users {
    border-right: 1px solid #eeeeee;
  }
}
</style>

<style lang="scss">
.users-search-input {
  .v-input__slot {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
