<template>
  <v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('production_history.dialog.title') }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="loading">
        <loading-indicator/>
      </v-card-text>
      <v-card-text v-if="!loading" class="pt-5">
        <!--<v-alert type="info">
          {{ $t('production_history..info') }}

        </v-alert>-->
        <v-form ref="form">
          <v-row>
            <v-col v-if="projectLog && projectLog.project" cols="12">
              <div class="mt-1 sans--text caption text-md-subtitle-1 text-xl-h6 font-weight-medium">
                <span class="grey--text">{{ projectLog.project.code }}</span>
                {{ projectLog.project.name }}
              </div>
              <div v-if="projectLog.log" :key="`${projectLog.id}`" class="mt-1">
                <v-row no-gutters>
                  <v-col>
                    <strong>{{ projectLog.amount }} {{ projectLog.log.unit }}</strong>
                    {{ projectLog.log.description }}
                    {{ projectLog.log.description2 }}
                    <template v-if="projectLog.logSupplier">, <strong
                      class="grey--text text--darken-1">{{ projectLog.logSupplier }}</strong></template>
                  </v-col>
                </v-row>
              </div>

            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-4"></v-divider>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="productionHistory.shiftInitials"
                            :disabled="saving"
                            :label="$t('production_history.shift_initials')"
                            :readonly="false"
                            :rules="[$rules.required]"
                            autocomplete="off"

                            class="custom-input required"
                            dense
                            hide-details
                            light
                            outlined
                            validate-on-blur/>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="productionHistory.meters"
                :disabled="saving || productionHistory.type === 'START_PROJECT'"
                :label="$t('production_history.meters')"
                :readonly="false"
                :rules="[$rules.required]"
                autocomplete="off"
                class="custom-input number-field required"
                dense
                hide-details
                inputmode="decimal"
                light
                outlined
                type="number"
                validate-on-blur
              >
                <template v-slot:append>
                  <span v-if="projectLog.log">&nbsp;{{ projectLog.log.unit }}</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="productionHistory.type"

                :items="productionHistoryTypes"
                :label="$t('production_history.type')"
                :readonly="true"
                autocomplete="off"
                dense
                disabled
                hide-details
                item-text="text"
                item-value="value"
                light
                outlined
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="startDate">
            <v-col cols="6">
              <date-picker v-model="startDate" :input-styles="{
                rules: [$rules.required],
                class: 'required'
              }" :label="$t('production_history.start_date')" dense outlined></date-picker>
            </v-col>
            <v-col cols="6">
              <time-picker v-model="startTime" :input-styles="{
                rules: [$rules.required],
                class: 'required'
              }" :label="$t('production_history.start_time')" outlined></time-picker>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col cols="6">
              <date-picker v-model="endDate" :input-styles="{
                rules: [$rules.required],
                class: 'required'
              }" :label="$t('production_history.end_date')" dense outlined></date-picker>
            </v-col>
            <v-col cols="6">
              <time-picker v-model="endTime" :input-styles="{
                rules: [$rules.required],
                class: 'required'
              }" :label="$t('production_history.end_time')" outlined></time-picker>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row v-if="productionHistory.type !== 'END_PROJECT' && productionHistory.type !== 'PAUSE_PROJECT'"
                 class="my-1">
            <v-col cols="12">
              <h3>{{ $t('production_history.quality_checks') }}</h3>
            </v-col>
            <v-col v-if="productionHistory.type === 'START_PROJECT'" cols="6">
              <v-text-field
                v-model.number="productionHistory.logWidth"
                :disabled="saving"
                :label="$t('production_history.log_width')"
                :readonly="false"
                :rules="[$rules.required,
                  (value => value && (value > expectedLogWidth + 0.3 || value < expectedLogWidth -0.3) ? $t('production_history.log_width_error', {width: expectedLogWidth, limit: 0.3}) : true)]"
                autocomplete="off"
                class="custom-input number-field required"
                dense

                inputmode="decimal"
                light
                outlined
                type="number"
                validate-on-blur
              >
                <template v-slot:append>
                  <span v-if="projectLog.log">&nbsp;mm</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="productionHistory.type === 'START_PROJECT'" cols="6">
              <v-text-field
                v-model.number="productionHistory.logHeight"
                :disabled="saving"
                :label="$t('production_history.log_height')"
                :readonly="false"
                :rules="[$rules.required,
                  (value => value && (value > expectedLogHeight + 0.3 || value < expectedLogHeight -0.3) ? $t('production_history.log_height_error', {width: expectedLogHeight, limit: 0.3}) : true)]"
                autocomplete="off"
                class="custom-input number-field required"
                dense

                inputmode="decimal"
                light
                outlined
                type="number"
                validate-on-blur
              >
                <template v-slot:append>
                  <span v-if="projectLog.log">&nbsp;mm</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="productionHistory.type !== 'END_PROJECT' && productionHistory.type !== 'PAUSE_PROJECT'"
                   cols="6">
              <v-text-field
                v-model.number="productionHistory.humidity"
                :disabled="saving"
                :label="$t('production_history.humidity')"
                :readonly="false"
                :rules="[$rules.required,
                  (value => value && value > humidityLimit ? $t('production_history.humidity_error', {limit: humidityLimit}) : true)]"
                autocomplete="off"
                class="custom-input number-field required"
                dense
                inputmode="decimal"
                light
                outlined
                type="number"
                validate-on-blur

              >
                <template v-slot:append>
                  <span v-if="projectLog.log">&nbsp;%</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="productionHistory.type !== 'END_PROJECT' && productionHistory.type !== 'PAUSE_PROJECT'"
                   cols="6">
              <v-checkbox
                v-model="productionHistory.logCentering"
                :disabled="saving"
                :label="$t('production_history.log_centering')"
                :readonly="false"
                autocomplete="off"
                class="required"
                dense
                hide-details
                light
                outlined
              >
              </v-checkbox>
            </v-col>
            <v-col v-if="productionHistory.type !== 'END_PROJECT' && productionHistory.type !== 'PAUSE_PROJECT'"
                   cols="6">
              <v-checkbox
                v-model="productionHistory.cornerMeasurement"
                :disabled="saving"
                :label="$t('production_history.corner_measurement')"
                :readonly="false"
                autocomplete="off"
                class="required"
                dense
                hide-details
                light
                outlined
              >
              </v-checkbox>
            </v-col>
            <v-col v-if="productionHistory.type !== 'END_PROJECT' && productionHistory.type !== 'PAUSE_PROJECT'"
                   cols="6">
              <v-checkbox
                v-model="productionHistory.cornerTightness"
                :disabled="saving"
                :label="$t('production_history.corner_tightness')"
                :readonly="false"
                autocomplete="off"
                class="required"
                dense
                hide-details
                light
                outlined
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="productionHistory.notice"
                :disabled="saving"
                :label="$t('project.notice')"
                auto-grow
                autocomplete="off"
                dense
                hide-details
                light
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions :class="[$vuetify.breakpoint.xsOnly && 'd-flex flex-column']">
        <v-btn v-if="productionHistory.id" :block="$vuetify.breakpoint.xsOnly" :loading="loadingDelete" color="error"
               text
               @click="deleteProductionHistory">
          {{ $t('production_history.dialog.delete') }}
        </v-btn>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn :block="$vuetify.breakpoint.xsOnly" color="default" text @click="show = false">
          {{ $t('production_history.dialog.close') }}
        </v-btn>
        <v-btn :block="$vuetify.breakpoint.xsOnly" :class="[$vuetify.breakpoint.xsOnly && 'mt-2']" :loading="saving"
               color="primary"
               @click="save">
          {{ $t('production_history.dialog.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
<script>
import TimePicker from "@/components/TimePicker.vue";
import DatePicker from "@/components/DatePicker.vue";
import productionhistory from "@/api/productionhistory";
import dayjs from "dayjs";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import orderBy from "lodash/orderBy";

export default {
  name: 'ProductionHistoryDialog',
  components: {LoadingIndicator, DatePicker, TimePicker},
  emits: ['close', 'end-project'],
  data() {
    return {
      show: false,
      saving: false,
      projectLog: {},
      project: {},
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      productionHistories: [],
      loading: false,
      loadingDelete: false,
      productionHistory: {
        project: null,
        notice: null,
        startTime: null,
        endTime: null,
        shiftInitials: null,
        log: null,
        blank: null,
        meters: null,
        type: null,
        logWidth: null,
        logHeight: null,
        logCentering: null,
        cornerMeasurement: null,
        cornerTightness: null,
        humidity: null,
      }
    }
  },
  props: {},
  computed: {
    productionHistoryTypes() {
      return productionhistory.getTypes();
    },
    blankFilter() {
      return {itemGroupCodes: this.$store.state.logBlankGroupCodes}
    },
    logBlank() {
      return this.projectLog?.log?.subItems?.find(item => this.$store.state?.logBlankGroupCodes?.includes(item?.group?.code));
    },
    humidityLimit() {
      const groupCode = this.logBlank?.group?.code
      return groupCode === '100000' ? 16 : groupCode === '150000' ? 25 : 25;
    },
    expectedLogWidth() {
      return this.projectLog?.log?.width;
    },
    expectedLogHeight() {
      return this.projectLog?.log?.height;
    }
  },
  methods: {
    async deleteProductionHistory() {
      if (await this.$confirmDelete(this.$t('production_history.delete_confirm_title'), this.$t('production_history.delete_confirm_message'))) {
        this.loadingDelete = true;
        try {
          await productionhistory.delete(this.productionHistory);
          this.show = false;
        } catch (e) {
          console.error(e);
          this.$handleApiError(e);
        }
        this.loadingDelete = false;
      }
    },
    getProductionHistoryType(type) {
      return this.productionHistoryTypes.find(t => t.value === type)?.text;
    },
    async openEdit(productionHistory) {
      this.productionHistory = structuredClone(productionHistory);

      this.projectLog = structuredClone(productionHistory.projectLog);
      this.project = structuredClone(productionHistory.project);
      this.setDateFields();
      this.show = true;

    },
    async open(projectLog, type) {
      this.show = true;
      this.project = structuredClone(projectLog?.project);
      this.projectLog = structuredClone(projectLog);
      this.loading = true;


      try {
        const response = await productionhistory.getPaged({searchString: null, projectLogId: projectLog?.id}, {
          page: 1,
          itemsPerPage: 999
        });
        this.productionHistories = orderBy(response?.content, ['endTime'], ['asc']);
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
      let latestHistory = null;
      if (this.productionHistories.length > 0) {
        latestHistory = this.productionHistories[this.productionHistories.length - 1];
      }


      this.productionHistory = {
        ...this.productionHistory,
        meters: type === 'START_PROJECT' ? 0 : null,
        notice: null,
        shiftInitials: null,
        project: projectLog.project,
        projectLog: projectLog,
        log: projectLog.log,
        blank: this.getLogBlank(projectLog),
        type: type || 'SHIFT_END',
        startTime: latestHistory?.endTime || projectLog.productionStartDate || dayjs().toISOString(),
        endTime: dayjs().toISOString(),
        logWidth: null,
        logHeight: null,
        logCentering: null,
        cornerMeasurement: null,
        cornerTightness: null,
        humidity: null
      }
      this.setDateFields();

      this.loading = false;
    },
    getLogBlank(log) {
      return log?.log?.subItems?.find(item => this.$store.state.logBlankGroupCodes.includes(item?.group?.code));
    },
    setDateFields() {

      if (this.productionHistory?.startTime) {
        const start = dayjs(this.productionHistory.startTime);
        this.startDate = dayjs(start).format('YYYY-MM-DD');
        this.startTime = dayjs(start).format('HH:mm');
      }
      if (this.productionHistory?.endTime) {
        const end = dayjs(this.productionHistory.endTime);

        this.endDate = dayjs(end).format('YYYY-MM-DD');
        this.endTime = dayjs(end).format('HH:mm');
      }
    },

    async save() {
      this.saving = true;
      if (!this.$refs.form.validate()) {
        this.saving = false;
        return;
      }
      try {
        this.productionHistory.startTime = dayjs(`${this.startDate} ${this.startTime}`).toISOString();
        this.productionHistory.endTime = dayjs(`${this.endDate} ${this.endTime}`).toISOString();
        await productionhistory.create(this.productionHistory);

        this.show = false;
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
      this.saving = false;
    }

  },
  watch: {
    show() {
      if (!this.show) {
        if (this.productionHistory?.type === 'END_PROJECT') {
          this.$emit('end-project', this.projectLog)
        }
        this.productionHistory = {
          project: null,
          notice: null,
          startTime: null,
          endTime: null,
          shiftInitials: null,
          log: null,
          blank: null,
          meters: null,
          type: null,
        }
        this.$emit('close', this.projectLog)
      }

    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.log-blank {
  color: #7c7c7c;
  display: block;
}

</style>
