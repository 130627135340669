/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue';
import md5 from 'md5';
import numeral from 'numeral';
import adminApi from '../api/admin';
import userApi from '../api/user';
import systemApi from '../api/system';
import tenantApi from '../api/tenant';
import tenantService from '../service/tenant.service';
import i18n from '../i18n';
import UiEventBus from '../UiEventBus';
import FileNameValidator from '@/plugins/filenamevalidator.plugin';
import dayjs from 'dayjs';
import {v4 as uuidv4} from 'uuid';

let loadingCategories = false;
let categories = [];
let thisYear = dayjs().year();
const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $tenant() {
          const savedTenant = tenantService.tenant;
          if (savedTenant != null) {
            return savedTenant;
          }

          if (savedTenant == null && this.$userInfo && this.$userInfo.tenants.length > 0) {
            return this.$userInfo.tenants[0].id;
          }

          return null;
        },
        $isTenantRoute() {
          if ('tenantId' in this.$route.params) {
            return true;
          }
          return false;
        },
        $userApi() {
          return userApi;
        },
        $adminApi() {
          return adminApi;
        },
        $systemApi() {
          return systemApi;
        },
        $tenantApi() {
          return tenantApi;
        },
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        },
        $bottomNavigationStyle() {
          if (this.$vuetify.breakpoint.xsOnly) {
            return 'z-index: 5; bottom: 72px';
          }
          return '';
        },
      },
      methods: {
        $isValidEmail(mail) {
          if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true;
          }
          return false;
        },
        $showSuccessNotification(message) {
          UiEventBus.$emit('showNotification', {
            color: 'success',
            message,
            showButton: false,
          });
        },
        $showWarningNotification(message) {
          UiEventBus.$emit('showNotification', {
            color: 'warning',
            message,
            showButton: false,
          });
        },
        $showErrorNotification(message) {
          const msg = message != null ? message : i18n.t('generic_error');
          UiEventBus.$emit('showNotification', {
            color: 'error',
            message: msg,
            showButton: false,
          });
        },
        $numeral: numeral,

        $formatNumber1Dec(num) {
          return this.$numeral(parseFloat(num)).format('0.0');
        },

        $formatNumberInt(num) {
          return this.$numeral(parseFloat(num)).format('0,0');
        },
        $formatNumber(num, format = '0,0.0') {
          return this.$numeral(parseFloat(num)).format(format);
        },
        $formatNumberIntAppendix(num, appendix) {
          return this.$numeral(parseFloat(num)).format('0,0') + ' ' + appendix;
        },

        $formatNumber2DecAppendix(num, appendix) {
          return this.$numeral(parseFloat(num)).format('0,0.[00]') + ' ' + appendix;
        },
        /**
         *
         * @param {!error} err
         * @param {msg} [msg] message to show
         */
        $handleApiError(err, msg) {
          console.error(err);
          if (err.response != null && err.response.data && err.response.data.error_key != null) {
            this.$showErrorNotification(i18n.t(err.response.data.error_key));
          } else {
            this.$showErrorNotification(msg);
          }
        },
        $reloadApp() {
          location.reload();
        },
        $formatTimeStamp(date) {
          return dayjs(date).format('LLLL');
        },
        $formatDateNoTimeWithWeekday(date) {
          return date ? dayjs(date).format('dd L') : '';
        },
        $formatDateNoTime(date) {
          return date ? dayjs(date).format('L') : '';
        },
        /*$formatDateTime(date) {
          return date ? dayjs(date).format('DD.MM.YYYY HH:mm:ss') : ''
        },*/
        $formatDateTime(date) { // International version
          return date ? dayjs(date).format('L LT') : ''
        },
        $formatYear(date) {
          return date ? dayjs(date).format('YYYY') : '';
        },
        $formatWeekDay(date) {
          return date ? dayjs(date).format('dd') : '';
        },
        $formatWeekDayLong(date) {
          if (i18n.locale === 'fi') {
            return date ? dayjs(date).format('dddd') + 'na' : '';
          } else if (i18n.locale === 'en') {
            return date ? 'On ' + dayjs(date).format('dddd') : '';
          }
        },
        $formatWeekNumber(date) { // International version  (ISO week number)
          return date ? dayjs(date).format('W') : '';
        },
        $formatDayMonth(date) {
          const d = date && dayjs(date);
          let format = "D.M";
          if (d?.year() !== thisYear) {
            format = "D.M.YYYY";
          }
          return date ? dayjs(date).format(format) : '';
        },
        $formatTimeNoDate(date) {
          if (date) {
            return dayjs(date).format('LT');
          }
        },
        $formatMonthAndYear(date) {
          if (date) {
            return dayjs(date).format('MMM YYYY')
          }
        },
        $formatMonthAndYearLong(date) {
          if (date) {
            return dayjs(date).format('MMMM YYYY')
          }
        },
        $humanizeDuration(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return duration.hours() + 'h ' + duration.minutes() + 'min ' + (showSeconds ? duration.seconds() + 's' : '');
        },
        $randomizeColorFromString(string, saturation = 75, lightness = 40) {
          let hash = 0;
          if (string?.length > 0) {
            for (let i = 0; i < string.length; i++) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
              hash = hash & hash; // Convert to 32bit integer
            }
          }

          if (hash < 0) hash = hash * -1;

          let shortened = hash % 200;
          return `hsl(${shortened}, ${saturation}%, ${lightness}%)`;
        },
        $getImageUrl(imageKey) {
          return `${this.$baseUrl}/api/public/image/${imageKey}`;
        },
        $getGravatarUrl(email, size) {
          const picSize = size ? size : '68';
          const hash = md5(email.toLowerCase().trim());
          return `https://www.gravatar.com/avatar/${hash}?s=${picSize}&d=mm`; // eslint-disable-line no-undef
        },
        $workTaskStatusIcon(status) {
          switch (status) {
            case 'RECEIVED':
              return 'mdi-archive-outline';
            case 'WAITING':
              return 'mdi-timer-sand';
            case 'INPROGRESS':
              return 'mdi-progress-wrench';
            case 'DONE':
              return 'mdi-check';
            default:
              return '';
          }
        },
        $snakeCase(str) {
          return str?.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        },
        $fileNameValidator(filename) {
          return FileNameValidator(filename);
        },
        $confirm(type, title, message) {
          return this.$root.$confirm(title, message, {color: type});
        },
        $confirmDelete(title, message) {
          return this.$root.$confirm(title, message, {color: 'error', agree: this.$t('delete')});
        },
        $randomString() {
          return (Math.random() + 1).toString(36).substring(2);
        },
        async $loadUsers() {
          try {
            return await this.$userApi.getCloudUsers();
          } catch (error) {
            this.$store.dispatch('showNotification', {
              color: 'error',
              message: this.$t('errors.error_loading_users'),
              timeOut: 10000,
            });
            console.log(error);
          }
          return null;
        },
        $tidTextField(field) {
          return field?.replace(new RegExp('Tid$'), 'Text');
        },
        $uuid() {
          return uuidv4();
        },
      },
    });
  },
};
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
export default HelperPlugin;
