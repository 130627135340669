<template>
  <v-row :align="alignMiddle ? 'center' : 'start'" class="py-10" justify="center" row>
    <v-progress-circular :size="70" :width="3" color="primary" indeterminate></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    alignMiddle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
