import axios from 'axios';

export default {
  async getApiKeys() {
    return await axios({
      url: '/api/tenant/apikey',
    }).then(r => r.data);
  },
  async updateApiKey(publicKey, /* ApiKey */ data) {
    return await axios({
      url: '/api/tenant/apikey/' + publicKey,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async deleteApiKey(publicKey) {
    return await axios({
      url: `/api/tenant/apikey/${publicKey}`,
      method: 'delete',
    }).then(r => r.data);
  },
  async createApiKey(data) {
    return await axios({
      url: '/api/tenant/apikey/',
      method: 'post',
      data,
    }).then(r => r.data);
  },

};
