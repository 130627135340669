import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fi from 'vuetify/lib/locale/fi';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { fi },
    current: 'fi',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light: {
        primary: '#000000',
        secondary: '#767676',
        accent: '#5d5246',
        error: '#f44336',
        warning: '#ff9400',
        info: '#2993c0',
        success: '#00a105',
      },
    },
  },
});
