<template>
  <hb-basic-page :loading="loading" :subtle-loading="subtleLoading" :title="$t('tenants.management_title')"
                 name="tenant-management">
    <v-col cols="12" md="8" offset-md="2" offset-xl="3" xl="6">
      <v-card outlined>
        <v-card-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''" primary-title>
          <div>
            <v-avatar
              :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'"
              :color="tenant.status === 'ACTIVE' ? $randomizeColorFromString(tenant.id, 75, 70) : 'grey lighten-2'"
              :size="$vuetify.breakpoint.xsOnly ? '68' : '40'"
              class="tenant-avatar"
            >
              {{ tenant.name ? tenant.name.substring(0, 2) : '' }}
            </v-avatar>
          </div>
          <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
            <section-header class="text-center text-sm-left">
              {{ tenant.name }}
            </section-header>
            <div class="body-1 text-center text-sm-left">
              <span>{{ $t('tenants.statuses.' + tenant.status) }}</span>
            </div>
          </div>
        </v-card-title>

        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''">
          <v-btn :block="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
                 :outlined="$vuetify.breakpoint.xsOnly"
                 color="accent"
                 text
                 @click="openEditTenantDialog">
            <v-icon left>edit</v-icon>
            {{ $t('tenants.edit_organization') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col v-if="false" cols="12" md="10" offset-md="1">
      <v-row>
        <api-keys/>
      </v-row>
    </v-col>
    <tenant-details-dialog ref="tenantDetailsDialog" @on-dismiss="onTenantDetailsDialogDismiss"/>
  </hb-basic-page>
</template>

<script>
import TenantDetailsDialog from '@/components/Tenant/TenantManagement/TenantDetailsDialog';
import tenantApi from '@/api/tenant';
import ApiKeys from '@/components/Tenant/TenantManagement/ApiKeys';
import SectionHeader from "@/components/SectionHeader";

export default {
  name: 'TenantManagementView',
  components: {
    SectionHeader,
    ApiKeys,
    TenantDetailsDialog: TenantDetailsDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      tenant: {},
    };
  },
  methods: {
    openEditTenantDialog() {
      this.$refs.tenantDetailsDialog.open(this.tenant);
    },
    onTenantDetailsDialogDismiss() {
      this.getTenant();
    },
    async getTenant() {
      if (this.tenant.id) {
        this.subtleLoading = true;
      } else {
        this.loading = true;
      }
      try {
        this.tenant = await tenantApi.getCurrentTenant();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
      this.subtleLoading = false;
    },
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin'])) {
      this.$router.replace({ name: 'access_denied' });
      return;
    }
    this.getTenant();
  },
};
</script>

<style lang="scss">
.user-avatar {
  overflow: hidden;
}

.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
