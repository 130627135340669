import axios from 'axios';

/*
 * Used for api calls for current user.
 * for example: profile, get my tenants, ...
 */
const userApi = {
  async createSubscription(newSubscription) {
    await axios.post('/api/user/subscription', newSubscription);
  },
  async enableNotifications() {
    await axios.post('/api/user/subscription/enable');
  },
  async disableNotifications() {
    await axios.post('/api/user/subscription/disable');
  },
  async setUserLanguage(language) {
    await axios.put('/api/user/set-language/' + language);
  },
  /**
   *
   * @param {!Object} data
   * @param {!String} data.name
   * @param {!String} data.streetAddress
   * @param {!String} data.city
   * @param {!String} data.zipCode
   * @param {!Number} data.country
   * @param {!Number} data.business
   * @param {!Number} data.headcount
   * @param {!Number} data.turnover
   * @param {!String} data.priceId
   * @returns {Promise<any>}
   */
  async createTenant(data) {
    const response = await axios.post('/api/user/tenant', data);
    return response.data;
  },
  async getMe() {
    const response = await axios.get('/api/user');
    return response.data;
  },
  async updateUserInfo(email, firstName, lastName, phone) {
    const data = {
      email,
      firstName,
      lastName,
      phone,
    };
    const response = await axios.post('/api/user', data);
    return response.data;
  },
  async uploadProfileImage(file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);
    const response = await axios.post(`/api/user/image`, formData);
    return response.data;
  },
  async logout() {
    await axios.post('/api/public/logout');
  },
  /** USERS **/
  getCloudUsers: async () => {
    const response = await axios.get('/api/user/list-cloud');
    return response.data;
  },
  getTenantUsers: async () => {
    const response = await axios.get('/api/user/tenant-users');
    return response.data;
  },
};

export default userApi;
