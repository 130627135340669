<template>
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="loading"
                :title="this.translation.tid ? $t('translations.edit') : $t('translations.create_new')">

    <v-card-text class="pt-4">
      <translation-edit-form v-model="translation" ref="translationEditForm" translation-edit-dialog/>
    </v-card-text>

  </basic-dialog>
</template>

<script>
import TranslationService from "@/service/translation.service";
import BasicDialog from "@/components/BasicDialog";
import translationApi from "@/api/translation";
import TranslationEditForm from "@/components/Translations/TranslationEditForm";

export default {
  name: "EditDialog",
  components: {TranslationEditForm, BasicDialog},
  data() {
    return {
      loading: false,
      dialog: false,
      translation: {},
      actionButtons: {
        cancel: {
          click: this.close,
        },
        save: {
          click: this.save
        }
      }
    };
  },
  computed: {
    languages() {
      return TranslationService.languages;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    open(item) {
      this.translation = {...item};
      this.dialog = true;
    },
    async save() {

      if (!this.$refs.translationEditForm.$refs.form.validate()) return;
      this.loading = true;
      try {
        const translations = [];
        this.languages.forEach(language => {
          translations.push({
            language,
            tid: this.translation.tid,
            translation: this.translation[language]
          });
        });
        await translationApi.saveTranslations(translations);
        this.dialog = false;
      } catch (e) {
        this.$handleApiError(e, this.$t('errors.error_translation_save_failed'));
        console.error(e);
      }
      this.loading = false;
      this.$emit('refresh');
    }
  }
}
</script>

<style scoped>

</style>
