<template>
  <hb-basic-page :loading="loading" :subtle-loading="subtleLoading" :title="$t('user_profile.title')"
                 name="user-profile">
    <v-col cols="12" md="8" offset-md="2" offset-xl="3" xl="6">
      <v-row>
        <v-col cols="12">
          <v-card v-if="user" outlined>
            <v-card-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''" primary-title>
              <div>
                <v-avatar
                  :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'"
                  :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject) : 'grey lighten-2'"
                  :size="$vuetify.breakpoint.xsOnly ? '68' : '48'"
                  class="user-avatar"
                >
                  <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
                  <v-img v-if="user.imageKey != null" :src="profileImageUrl"></v-img>
                  <v-icon
                    v-else-if="!user.agentUser"
                    :size="$vuetify.breakpoint.xsOnly ? '60' : '42'"
                    color="white"
                    style="margin-top: 8px"
                  >fas fa-user
                  </v-icon
                  >
                  <v-icon
                    v-else
                    :size="$vuetify.breakpoint.xsOnly ? '68' : '46'"
                    color="white"
                    style="margin-top: 8px"
                  >fas fa-users-cog
                  </v-icon
                  >
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">
                  {{ user.email ? user.email : user.sub }}
                </h3>
                <div class="body-1 text-center text-sm-left">
                  <span v-for="role in tenantRoles" :key="role"
                        :style="{ color: $randomizeColorFromString(role, 60, 45) }">
                    {{ getRoleText(role) }}
                  </span>
                </div>
                <div v-if="false" class="body-1 text-center text-sm-left">
                  <span v-for="role in systemRoles" :key="role"
                        :style="{ color: $randomizeColorFromString(role, 60, 45) }">
                    {{ getRoleText(role) }}
                  </span>
                </div>
              </div>
            </v-card-title>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''">
              <v-btn v-if="false" :block="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
                     :outlined="$vuetify.breakpoint.xsOnly"
                     color="accent" text @click="changeImage">
                <v-icon left>mdi mdi-image</v-icon>
                {{ $t('user_profile.change_image') }}
              </v-btn>
              <v-btn :block="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
                     :outlined="$vuetify.breakpoint.xsOnly"
                     color="accent" text @click="editUserProfile">
                <v-icon left>edit</v-icon>
                {{ $t('user_profile.edit_profile') }}
              </v-btn>
              <v-btn :block="$vuetify.breakpoint.xsOnly" :class="$vuetify.breakpoint.xsOnly ? 'ma-1' : ''"
                     :outlined="$vuetify.breakpoint.xsOnly"
                     color="accent" text @click="openChangePasswordDialog">
                <v-icon left>mdi mdi-form-textbox-password</v-icon>
                {{ $t('user_profile.change_password') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col v-if="false" cols="12">
          <v-card>
            <v-card-title primary-title>
              <div>
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="mb-0">
                  {{ $t('user_profile.push_notifications') }}</h3>
                <div class="body-1" style="word-break: normal;">
                  {{ $t('user_profile.push_notifications_explained') }}
                </div>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-switch
                v-model="allowPushNotifications"
                :label="$t('user_profile.allow_notifications')"
                class="mt-0 mb-2 ml-2"
                color="primary"
                hide-details
              ></v-switch>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <input v-if="false" ref="imageFileInput" :multiple="false" accept="image/*" style="display: none" type="file"
           @change="onImageFileChanged"/>
    <edit-user-profile-dialog ref="editUserProfileDialog"
                              @user-profile-updated="handleUserProfileUpdated"></edit-user-profile-dialog>
    <change-password-dialog ref="changePasswordDialog" :user="user"/>
  </hb-basic-page>
</template>

<script>
import ChangePasswordDialog from '../components/UserProfile/ChangePasswordDialog.vue';
import EditUserProfileDialog from '@/components/UserProfile/EditUserProfileDialog.vue';
import UserService from '../service/user.service';
import userApi from '@/api/user';

export default {
  name: 'UserProfile',
  components: {
    EditUserProfileDialog,
    ChangePasswordDialog: ChangePasswordDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      savingImage: false,
      allowPushNotifications: false,
    };
  },
  methods: {
    changeImage() {
      this.$refs.imageFileInput.click();
    },
    async onImageFileChanged(fileAddedEvent) {
      const image = {
        file: fileAddedEvent.target.files[0],
        filename: fileAddedEvent.target.value.split('\\').pop(),
      };

      this.savingImage = true;
      try {
        const user = await userApi.uploadProfileImage(image.file, image.filename);
        UserService.setUserInfo(user);
      } catch (error) {
        this.$handleApiError(error);
      }
      this.savingImage = false;
    },
    handleUserProfileUpdated(user) {
      UserService.setUserInfo(user);
    },
    editUserProfile() {
      this.$refs.editUserProfileDialog.openUser(this.user);
    },
    getRoleText(role) {
      return this.$t('user_management.role.' + role);
    },
    openChangePasswordDialog() {
      this.$refs.changePasswordDialog.open();
    },
  },
  computed: {
    profileImageUrl() {
      return this.$getImageUrl(this.user.imageKey);
    },
    user() {
      if (this.$isLoggedIn) return this.$userInfo;

      return {};
    },
    tenantRoles() {
      return this.user.roles.filter(r => r.startsWith('tenant_'));
    },
    systemRoles() {
      return this.user.roles.filter(r => !r.startsWith('tenant_'));
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style lang="scss">
.user-avatar {
  overflow: hidden;
}

.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
