<template>
  <v-dialog ref="timeDialog" v-model="timeModal" :disabled="disabled" persistent width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-model="time" :disabled="disabled" :label="label" dense outlined readonly v-bind="{ ...inputStyles }"
                    v-on="on">
        <template slot="prepend">
          <div style="width: 25px; text-align: center">
            <v-icon>{{ icon }}</v-icon>
          </div>
        </template>
      </v-text-field>
    </template>
    <v-time-picker v-if="timeModal" v-model="time" color="secondary" format="24hr" full-width
                   light>
      <v-btn text @click="timeModal = false">{{ $t('cancel') }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          elevation="0"
          @click="
          () => {
            this.$emit('input', this.time);
            this.timeModal = false;
          }
        "
      >{{ $t('ok') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>

export default {
  name: 'TimePicker',
  data() {
    return {
      time: null,
      timeModal: false,
    };
  },
  emits: ['input'],
  props: {
    inputStyles: Object,
    label: String,
    disabled: Boolean,
    icon: {
      type: String,
      default: 'fas fa-clock',
    },
    value: String,
  },
  methods: {},
  mounted() {
    this.time = this.value;
  },
  watch: {
    /*dateTime() {
      if (!this.dateTime) {
        this.time = null;
        return;
      }
      this.time = dayjs(this.dateTime).clone().format('HH:mm');
    },*/

    value() {
      this.time = this.value;
    },
  },
};
</script>

<style scoped></style>
