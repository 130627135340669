<template>
  <v-card color="project-card white mb-4" elevation="2">
    <v-card-title
      class="sans--text caption text-md-subtitle-1 text-xl-h6 font-weight-medium px-2 pt-2 px-md-4 pt-md-4 pb-0"
      style="line-height: 1.1rem;">
      <div style="word-break: break-word; width: 100%;">
        <v-row no-gutters>
          <status-chip :large="true" :status="projectLog.status"/>
          <v-spacer></v-spacer>
          <div class="caption text-right">
            <template v-if="$vuetify.breakpoint.smAndUp && doneBeforeDeliveryDuration >= 0">
              {{ $t('done') }}
              <v-avatar :color="doneBeforeDeliveryDurationColor" class="ma-0 mt-n1 white--text" size="27">
                {{ doneBeforeDeliveryDuration }}
              </v-avatar>
              {{ $t('production_timeline.before_delivery') }}
            </template>
            <template v-else-if="$vuetify.breakpoint.smAndUp && doneBeforeDeliveryDuration < 0">
              {{ $t('production_timeline.will_be_late') }}
              <v-avatar :color="doneBeforeDeliveryDurationColor" class="ma-0 mt-n1 white--text" size="27">
                {{ doneBeforeDeliveryDuration * -1 }}
              </v-avatar>
              {{ $t('production_timeline.from_delivery') }}
            </template>
            <template v-else>
              <v-avatar :color="doneBeforeDeliveryDurationColor" class="ma-0 white--text" size="27">
                {{ doneBeforeDeliveryDuration }}
              </v-avatar>
            </template>
          </div>
        </v-row>
        <div class="mt-3">
          <span class="grey--text">{{ projectLog.project.code }}</span>
          {{ projectLog.project.name }}
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="py-0 my-2 caption">
      <div :key="projectLog.id">
        <div v-if="projectLog.log" :key="`${projectLog.id}`">
          <v-row no-gutters>
            <v-col>
              <strong>{{ projectLog.amount }} {{ projectLog.log.unit }}</strong> <span
              v-if="projectLog.doneAmountPercent" class="font-italic">({{
                $formatNumberInt(projectLog.doneAmountPercent)
              }} %)</span>
              {{ projectLog.log.description }}
              {{ projectLog.log.description2 }}
              <template v-if="projectLog.logSupplier">, <strong
                class="grey--text text--darken-1">{{ projectLog.logSupplier }}</strong></template>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="projectLog.description" class="caption py-2">
        <v-icon :size="$vuetify.breakpoint.smAndDown ? '18' : '18'" class="mt-n1 mr-1">mdi mdi-information-outline
        </v-icon>
        {{ projectLog.description }}
      </div>
      <div v-if="projectLog.project.notice" class="font-italic mt-3 mt-md-2">
        <v-icon :size="$vuetify.breakpoint.smAndDown ? '18' : '18'" class="mt-n1 mr-1">mdi mdi-information-outline
        </v-icon>
        {{ projectLog.project.notice }}
      </div>
    </v-card-text>

    <v-card-text class="px-2 py-0 my-3 px-md-4">
      <production-details-chip :check="projectLog.project.basicMeasurementGuide"
                               :description="projectLog.project.basicMeasurementGuideDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                               title="PMO"/>
      <production-details-chip :check="projectLog.project.workPictures"
                               :description="projectLog.project.workPicturesDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                               title="TYÖ"/>
      <production-details-chip :check="projectLog.project.constructionDrawings"
                               :description="projectLog.project.constructionDrawingsDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                               title="RAK"/>
      <production-details-chip :check="projectLog.project.bvn"
                               :description="projectLog.project.bvnDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" title="BVN"/>
      <production-details-chip :check="projectLog.project.buildingPermission"
                               :description="projectLog.project.buildingPermissionDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                               title="LUPA"/>
      <production-details-chip :check="projectLog.project.materialsList"
                               :description="projectLog.project.materialsListDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                               title="TAVL"/>
      <production-details-chip :check="projectLog.project.windowsAndDoors"
                               :description="projectLog.project.windowsAndDoorsDescription"
                               :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly"
                               title="I&O"/>
    </v-card-text>
    <v-card-text class="px-2 px-md-4 py-0 my-3 caption">
      <div>
        <v-row no-gutters>
          <v-col class="mb-2" cols="12">
            <div class="mr-3 d-sm-inline-block">{{ $t('production_timeline.corner_type') }}:
              {{
                projectLog.project.cornerType ? getCornerType(projectLog.project.cornerType) : '-'
              }}
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="mr-3 d-sm-inline-block">{{ $t('production_timeline.estimated_start') }}:
              {{
                projectLog.productionStartDate ? $formatDateNoTime(projectLog.productionStartDate) : '-'
              }}
            </div>

            <div class="mr-3 d-sm-inline-block">{{ $t('production_timeline.production_time') }}:
              {{
                projectLog.productionTime ? `${projectLog.productionTime}h` : '-'
              }}
            </div>
            <div class="mr-3 d-sm-inline-block">{{ $t('production_timeline.delivery_date') }}:
              {{
                deliveryDate ? $formatDateNoTime(deliveryDate) : '-'
              }}
            </div>
            <!--<div class="mr-3">{{ $t('production_timeline.production_time') }}:
              {{
                projectLog.productionTime ? `${projectLog.productionTime}h` : '-'
              }}
            </div>
            <div class="mr-3">{{ $t('production_timeline.production_time') }}:
              {{
                projectLog.productionStartDate ? `${projectLog.productionStartDate}h` : '-'
              }}
            </div>
            <div class="mr-3">{{ $t('production_timeline.production_time') }}:
              {{
                projectLog.productionEndDate ? `${projectLog.productionEndDate}h` : '-'
              }}
            </div>-->
          </v-col>
        </v-row>

        <template v-if="productionHistories && productionHistories.length > 0">
          <v-row>
            <v-col>
              <v-simple-table>
                <tbody>

                <tr>
                  <th>{{ $t('production_history.shift_initials') }}</th>
                  <th>{{ $t('production_history.meters') }}</th>
                  <th>{{ $t('production_history.start_date') }}</th>
                  <th>{{ $t('production_history.end_date') }}</th>
                  <th>{{ $t('production_history.notice') }}</th>
                  <th>
                    <div class="d-flex justify-space-between align-center">
                      {{ $t('production_history.type') }}

                      <v-btn :loading="loadingProductionHistory" class="mt-2 float-right" icon small
                             @click="getProductionHistory">
                        <v-icon>mdi mdi-refresh</v-icon>
                      </v-btn>
                    </div>
                  </th>
                </tr>
                <tr v-for="ph in productionHistories" :key="ph.id">
                  <td>
                    {{ ph.shiftInitials }}
                  </td>
                  <td class="text-no-wrap">{{ ph.meters }}
                    <template v-if="ph && ph.log && ph.log.unit">{{ ph.log.unit }}</template>
                  </td>
                  <td><span
                    v-if="ph.type === 'LOSS' || ph.type === 'WAREHOUSE_RECEIPT'"></span><span
                    v-else>{{
                      $formatDateTime(ph.startTime)
                    }}</span></td>
                  <td><span v-if="ph.type !== 'START_PROJECT'">{{ $formatDateTime(ph.endTime) }}</span></td>
                  <td>
                    <div v-if="ph.logHeight || ph.logWidth">
                      <strong>{{ $t('production_history.log_width') }} x {{
                          $t('production_history.log_height')
                        }}:</strong> {{ ph.logHeight }} mm x {{ ph.logWidth }} mm
                    </div>
                    <div v-if="ph.humidity">
                      <strong>{{ $t('production_history.humidity') }}:</strong> {{ ph.humidity }}%
                    </div>
                    <div v-if="ph.logCentering != null" :class="[!ph.logCentering && 'error--text']">
                      <strong>{{ $t('production_history.log_centering') }}:</strong>
                      {{ ph.logCentering ? $t('ok') : $t('not_ok') }}
                    </div>
                    <div v-if="ph.cornerMeasurement != null" :class="[!ph.cornerMeasurement && 'error--text']">
                      <strong>{{ $t('production_history.corner_measurement') }}:</strong>
                      {{ ph.cornerMeasurement ? $t('ok') : $t('not_ok') }}
                    </div>
                    <div v-if="ph.cornerTightness != null" :class="[!ph.cornerTightness && 'error--text']">
                      <strong>{{ $t('production_history.corner_tightness') }}:</strong>
                      {{ ph.cornerTightness ? $t('ok') : $t('not_ok') }}
                    </div>
                    <div v-if="ph.notice != null">
                      {{ ph.notice }}
                    </div>


                  </td>
                  <td>{{ getProductionHistoryType(ph.type) }}</td>
                </tr>

                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-btn :loading="loadingProductionHistory" class="mt-2" small text @click="getProductionHistory">
            <v-icon>mdi mdi-refresh</v-icon>
            {{ $t('production_history.load') }}
          </v-btn>
        </template>

      </div>
    </v-card-text>
    <v-card-actions>


      <template v-if="projectLog.status === 'MANUFACTURING'">
        <v-btn :disabled="disabledButtons" :loading="loadingProjectStatusPause" color="default" outlined
               @click="pauseProject">{{
            $t('production.pause')
          }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="disabledButtons" color="default" outlined @click="shiftChange">
          {{ $t('production.shift_change') }}
        </v-btn>
        <!--<v-btn color="primary" outlined @click="pauseProject">{{ $t('production.pause') }}</v-btn>-->

        <v-btn :disabled="disabledButtons" :loading="loadingProjectStatusEnd" color="primary" @click="endProject">
          {{ $t('production.end') }}
        </v-btn>
      </template>
      <template v-if="projectLog.status !== 'MANUFACTURING'">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="projectLog.project.status === 'CANT_START' || projectLog.status === 'CANT_START' || !canStartNewProject || disabledButtons"
          :loading="loadingProjectStatusStart"
          color="primary" @click="startProject">
          {{ projectLog.status === 'PAUSED' ? $t('production.continue') : $t('production.start') }}
        </v-btn>
      </template>
    </v-card-actions>

  </v-card>
</template>

<script>
import StatusChip from "@/views/tenant/ProductionTimelineView/components/StatusChip";
import ProductionDetailsChip from "@/views/tenant/ProductionTimelineView/components/ProductionDetailsChip";
import dayjs from "dayjs";
import projectApi from "@/api/project";
import productionhistory from "@/api/productionhistory";

export default {
  name: "ProjectCard",
  components: {ProductionDetailsChip, StatusChip},
  data() {
    return {
      loadingProductionHistory: false,
      loadingProjectStatusStart: false,
      loadingProjectStatusEnd: false,
      loadingProjectStatusPause: false,
      productionHistories: [],
    }
  },
  props: {
    projectLog: {
      type: Object,
      required: true
    },
    productionLine: {
      type: Object,
      required: true
    },
    canStartNewProject: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['saved', 'start-project', 'shift-change', 'end-project', 'pause-project'],
  methods: {
    getProductionHistoryType(type) {
      return this.productionHistoryTypes.find(t => t.value === type)?.text;
    },
    async startProject() {
      this.loadingProjectStatusStart = true;
      try {
        await projectApi.updateProjectLogStatus(this.projectLog.id, 'MANUFACTURING');
        await projectApi.reschedule(this.productionLine.id, this.projectLog.id, dayjs().toISOString(), null, {moveForward: true})
        this.$emit('saved');
        this.$emit('start-project', this.projectLog);
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loadingProjectStatusStart = false;
    },
    async endProject() {
      //this.$refs.productionNoticeDialog.open();
      this.loadingProjectStatusEnd = true;
      try {
        await projectApi.updateProjectLogStatus(this.projectLog.id, 'PROCESSED');
        await projectApi.reschedule(this.productionLine.id, this.projectLog.id, dayjs().toISOString(), null, {markAsProcessed: true})
        this.$emit('saved');
        this.$emit('end-project', this.projectLog)
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loadingProjectStatusEnd = false;
    },
    async pauseProject() {
      this.loadingProjectStatusPause = true;
      try {
        await projectApi.updateProjectLogStatus(this.projectLog.id, 'PAUSED');
        this.$emit('saved');
        this.$emit('pause-project', this.projectLog)
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loadingProjectStatusPause = false;
    },
    async shiftChange() {
      this.$emit('shift-change', this.projectLog);
    },
    async getProductionHistory() {
      this.loadingProductionHistory = true;
      try {
        const productionHistories = await productionhistory.getPaged({
          searchString: null,
          projectLogId: this.projectLog?.id
        }, {
          page: 1,
          itemsPerPage: 999
        })
        this.productionHistories = productionHistories?.content;
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loadingProductionHistory = false;
    },
    getCornerType(cornerType) {
      if (!cornerType) return null;
      return this.cornerTypes.find(t => t.value === cornerType)?.label;
    }
  },
  computed: {
    disabledButtons() {
      return this.loadingProjectStatusEnd || this.loadingProjectStatusPause || this.loadingProjectStatusStart;
    },
    cornerTypes() {
      return [{label: 'Jiiri', value: 'jiiri'},
        {label: 'Risti', value: 'risti'},
        {label: 'Tiroli', value: 'tiroli'},]
    },
    productionHistoryTypes() {
      return productionhistory.getTypes();
    },
    deliveryDate() {
      // Support #138 Toimituspäivän muutos aikatauluun
      return this.projectLog.project.estimatedEnd || this.projectLog.project.deliveryDate
    },
    doneBeforeDeliveryDuration() {
      if (this.projectLog?.productionEndDate && this.deliveryDate) {
        return dayjs(this.deliveryDate).diff(dayjs(this.projectLog.productionEndDate), 'days');
      }
      return 0;
    },
    doneBeforeDeliveryDurationColor() {
      if (this.doneBeforeDeliveryDuration > 6) return 'grey';
      if (this.doneBeforeDeliveryDuration > 0) return 'orange';
      return 'red';
    }
  },
  mounted() {
    if (this.projectLog.status === 'MANUFACTURING') this.getProductionHistory();
  }
}
</script>

<style lang="scss" scoped>
</style>
