<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('user_management.create_new_user') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-subheader class="pl-0">{{ $t('user_management.user_type') }}</v-subheader>
          <v-switch
            color="primary"
            v-model="localUser"
            :label="$t('user_management.local_user')"
            persistent-hint
            :hint="$t('user_management.local_user_hint')"
            class="mt-2 mb-4"
          ></v-switch>

          <v-subheader class="pl-0">{{ $t('user_management.user_info') }}</v-subheader>
          <v-text-field
            v-if="localUser"
            class="mt-0 username"
            :label="$t('user_management.username')"
            :rules="[$rules.required, $rules.spacesNotAllowed]"
            validate-on-blur
            type="text"
            autocomplete="username"
            v-model="username"
          ></v-text-field>
          <v-text-field
            v-if="!localUser"
            :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"
            validate-on-blur
            class="mt-0 email"
            :label="$t('user_management.email')"
            autocomplete="username"
            type="text"
            v-model="username"
          ></v-text-field>
          <v-text-field
            v-if="localUser"
            class="mt-0 password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('user_management.password')"
            v-model="password"
            :rules="[$rules.required]"
            validate-on-blur
            autocomplete="new-password"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-subheader class="pl-0">{{ $t('user_management.user_roles') }}</v-subheader>
          <v-btn-toggle v-if="$vuetify.breakpoint.smAndUp" v-model="roleSet" mandatory multiple class="elevation-0">
            <template v-for="role in roles">
              <v-btn :key="role" text outlined :color="$randomizeColorFromString(role, 60, 45)" :value="role">{{ role }}</v-btn>
            </template>
          </v-btn-toggle>

          <v-select
            v-if="$vuetify.breakpoint.xsOnly"
            v-model="roleSet"
            :items="roles"
            attach
            chips
            :label="$t('user_management.roles')"
            multiple
            style="width: 100%"
          >
            <template v-slot:selection="data">
              <v-chip
                :input-value="data.selected"
                close
                small
                outlined
                @input="removeRole(data.item)"
                class="ml-0"
                :color="$randomizeColorFromString(data.item, 60, 45)"
              >
                <strong>{{ data.item }}</strong>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-list-item-action>
                <v-icon v-if="roleSet.findIndex(r => r === data.item) > -1" :color="$randomizeColorFromString(data.item, 60, 45)"
                  >check_box</v-icon
                >
                <v-icon v-else :color="$randomizeColorFromString(data.item, 60, 45)">check_box_outline_blank</v-icon>
              </v-list-item-action>
              <v-list-item-title :style="{ color: $randomizeColorFromString(data.item, 60, 45) }">{{ data.item }}</v-list-item-title>
            </template>
          </v-select>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('check_all_required_fields') }}</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn class="ma-1" color="default" :large="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" @click="dialog = false">
          <v-icon left>close</v-icon>
          <span>{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn class="ma-1" color="primary" :large="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" @click="saveUser">
          <v-icon left>check</v-icon>
          <span>{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import adminApi from '../../api/admin';

export default {
  name: 'AddUserDialog',
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      localUser: false,
      username: '',
      password: '',
      showPassword: false,
      roleSet: this.roles.length > 0 ? [this.roles[0]] : [],
      showAlert: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    removeRole(item) {
      this.roleSet.splice(this.roleSet.indexOf(item), 1);
      this.roleSet = [...this.roleSet];
    },
    async saveUser() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      let user = {
        roleSet: this.roleSet,
        username: this.username,
        email: this.username,
        type: this.type,
      };

      user.type = this.localUser ? 'LOCAL' : 'COGNITO';

      if (this.localUser) user.password = this.password;

      try {
        await adminApi.createUser(user);
        this.dialog = false;
        this.$showSuccessNotification(this.$t('user_management.user_created_success'));
      } catch (err) {
        this.$handleApiError(err);
      }
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.localUser = false;
        this.username = '';
        this.password = '';
        this.showPassword = false;
        this.roleSet = [];
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
