<template>
  <hb-basic-page
    :action-buttons="actionButtons"
    :back-button="backButton"
    :extension-height="$vuetify.breakpoint.smAndUp && $isTenantAdmin ? 47 : 47"
    :fill-height="loading"
    :loading="loading"
    :title="this.$route.params.id ? $t('project.edit_project') : $t('project.new_project')"
    style="background-color: transparent"
  >
    <v-col :style="{ marginBottom: $vuetify.breakpoint.xsOnly ? '76px' : '68px' }" class="pa-0" cols="12">
      <v-row no-gutters>
        <v-col
          :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : 'mt-5 gradient-icons'"
          lg="10"
          md="12"
          offset-lg="1"
          offset-md="0"
        >
          <v-card outlined>
            <v-card-title>
              {{ $tc('project.title', 1) }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="project.code"
                    :disabled="saving"
                    :label="$t('project.code')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    item-value=""
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="project.name"
                    :disabled="saving"
                    :label="$t('project.name')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    item-value=""
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="project.responsiblePerson"
                    :disabled="saving"
                    :label="$t('project.responsible_person')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details

                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <date-picker
                    v-model="project.estimatedStart"
                    :disabled="saving"
                    :input-styles="{
                        hideDetails: true,
                        light: true,
                        outlined: true,
                      }"
                    :label="$t('project.estimated_start')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                  >
                  </date-picker>
                </v-col>
                <v-col cols="12" md="4">
                  <date-picker
                    v-model="project.deliveryDate"
                    :disabled="saving"
                    :input-styles="{
                        dense: true,
                        hideDetails: true,
                        light: true,
                        outlined: true,
                      }"
                    :label="$t('project.delivery_date')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                  ></date-picker>
                </v-col>
                <v-col cols="12" md="4">

                  <date-picker
                    v-model="project.installationDate"
                    :disabled="saving"
                    :input-styles="{
                        dense: true,
                        hideDetails: true,
                        light: true,
                        outlined: true,
                      }"
                    :label="$t('project.installation_date')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                  ></date-picker>
                </v-col>

              </v-row>

              <v-row>
                <!--<v-col cols="12" md="4">

                  <date-picker
                    v-model="project.productionStartDate"
                    :disabled="saving"
                    :input-styles="{
                        hideDetails: true,
                        light: true,
                        outlined: true,
                      }"
                    :label="$t('project.production_start_date')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                  >
                  </date-picker>
                </v-col>
                <v-col cols="12" md="4">

                  <date-picker
                    v-model="project.productionEndDate"
                    :disabled="saving"
                    :input-styles="{
                        dense: true,
                        hideDetails: true,
                        light: true,
                        outlined: true,
                      }"
                    :label="$t('project.production_end_date')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details

                  >
                  </date-picker>

                </v-col>-->
                <v-col cols="12" md="4">


                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="project.status"
                    :disabled="saving"
                    :items="statuses"
                    :label="$t('project.status._')"
                    :readonly="false"
                    autocomplete="off"
                    dense
                    hide-details
                    item-text="label"
                    item-value="value"
                    light
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="project.productionTime"
                    :disabled="saving"
                    :label="$t('project.production_time')"
                    autocomplete="off"
                    clearable
                    dense
                    hide-details

                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4"><!--<v-checkbox
                      v-model="project.done"
                      :disabled="saving"
                      :label="$t('project.done')"
                      :readonly="true"
                      autocomplete="off"
                      dense
                      hide-details
                      light
                      outlined>
                  </v-checkbox>-->
                  <date-picker
                    v-model="project.estimatedEnd"
                    :disabled="saving"
                    :hint="$t('project.estimated_end_hint')"
                    :label="$t('project.estimated_end')"
                    :persistent-hint="true"
                    autocomplete="off"
                    clearable
                    dense
                    light
                    outlined
                  >
                  </date-picker>
                </v-col>


              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="project.roofType"
                    :disabled="saving"
                    :items="roofTypes"
                    :label="$t('project.roof_type')"
                    autocomplete="off"
                    clearable
                    dense
                    hide-details
                    item-text="label"
                    light
                    outlined

                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="project.cornerType"
                    :disabled="saving"
                    :items="cornerTypes"
                    :label="$t('project.corner_type')"
                    autocomplete="off"
                    clearable
                    dense
                    hide-details
                    item-text="label"
                    light
                    outlined

                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="project.foundationType"
                    :disabled="saving"
                    :items="foundationTypes"
                    :label="$t('project.foundation_type')"
                    autocomplete="off"
                    clearable
                    dense
                    hide-details
                    item-text="label"
                    light
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model.number="project.buildingModel"
                    :disabled="saving"
                    :label="$t('project.building_model')"
                    autocomplete="off"
                    clearable
                    dense
                    hide-details

                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="project.noScheduling"
                    :disabled="saving"
                    :label="$t('project.no_scheduling')"

                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined>
                  </v-checkbox>
                </v-col>
              </v-row>


            </v-card-text>
            <v-card-title>
              {{ $t('project.customer_info') }}
            </v-card-title>
            <v-card-text>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="project.deliveryRecipient"
                    :disabled="saving"
                    :label="$t('project.delivery_recipient')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="project.deliveryAddress"
                    :disabled="saving"
                    :label="$t('project.delivery_address')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="project.postalCode"
                    :disabled="saving"
                    :label="$t('project.postal_code')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="project.city"
                    :disabled="saving"
                    :label="$t('project.city')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="project.phone"
                    :disabled="saving"
                    :label="$t('project.phone')"
                    :readonly="true"
                    autocomplete="off"
                    dense
                    hide-details
                    item-value=""
                    light
                    outlined
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="project.email"
                    :disabled="saving"
                    :label="$t('project.email')"
                    :readonly="true"
                    :rules="[$rules.email, $rules.spacesNotAllowed]"
                    autocomplete="off"
                    dense
                    item-value=""
                    light
                    outlined
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>

              </v-row>

            </v-card-text>
          </v-card>

          <v-card class="mt-5" outlined>
            <v-card-title>
              {{ $t('project.logs._') }}
              <v-spacer/>
              <v-btn icon @click="addNewLog">
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-for="(log,i) in project.logs">
                  <project-log-row :key="`${log.id}_${i}`"
                                   ref="projectLogRow"
                                   :log-suppliers="logSuppliers"
                                   :profiles="profiles"
                                   :project="project"
                                   :saving="saving"
                                   :statuses="statuses"
                                   :value="log"
                                   @input="(log) => onLogUpdate(log, i)"
                                   @edit-production-history="openProductionHistoryDialog"
                                   @delete-log="(log) => onDeleteLog(log, i)"
                  />

                </template>
              </v-simple-table>
              <production-history-dialog ref="productionHistoryDialog"
                                         @close="reloadProductionHistoryForProjectLog"></production-history-dialog>
            </v-card-text>
          </v-card>
          <v-card class="mt-5" outlined>
            <v-card-title>
              {{ $t('project.site_contact_name') }}
            </v-card-title>
            <v-card-text>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="project.siteContactName"
                    :disabled="saving"
                    :label="$t('project.site_contact_name')"

                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="project.siteContactPhone"
                    :disabled="saving"
                    :label="$t('project.site_contact_phone_short')"

                    autocomplete="off"
                    dense
                    hide-details
                    item-value=""
                    light
                    outlined
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="project.siteContactEmail"
                    :disabled="saving"
                    :label="$t('project.site_contact_email_short')"

                    :rules="[$rules.email, $rules.spacesNotAllowed]"
                    autocomplete="off"
                    dense
                    item-value=""
                    light
                    outlined
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>

              </v-row>

            </v-card-text>
          </v-card>
          <v-card class="mt-5" outlined>
            <v-card-title>
              {{ $t('project.contacts._') }}
              <v-spacer/>
              <v-btn icon @click="addNewContact">
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn>

            </v-card-title>
            <v-card-text>
              <v-divider class="mb-0" style="border-color: #000;"/>
            </v-card-text>
            <v-card-text v-if="!project.contacts || project.contacts.length === 0">
              <v-row>
                <v-col>{{ $t('project.contacts.no_contacts') }}</v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-for="(contact,i) in project.contacts" :key="contact._id">
              <v-divider v-if="i > 0" class="mb-6 mt-n7"></v-divider>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="contact.name"
                    :disabled="saving"
                    :label="$t('project.contacts.name')"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="9" md="11">
                      <v-text-field
                        v-model="contact.role"
                        :disabled="saving"
                        :label="$t('project.contacts.role')"

                        autocomplete="off"
                        dense
                        hide-details
                        light
                        outlined
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="justify-end d-flex" cols="3" md="1">
                      <v-btn :loading="contact._loading" color="error" icon tabindex="-1"
                             @click="deleteContact(contact)">
                        <v-icon>mdi mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="contact.phone"
                    :disabled="saving"
                    :label="$t('project.contacts.phone')"

                    autocomplete="off"
                    dense
                    hide-details
                    item-value=""
                    light
                    outlined
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="contact.email"
                    :disabled="saving"
                    :label="$t('project.contacts.email')"
                    :rules="[$rules.email, $rules.spacesNotAllowed]"
                    autocomplete="off"
                    dense
                    item-value=""
                    light
                    outlined
                    validate-on-blur
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-5" outlined>
            <v-card-title>
              {{ $t('project.readiness') }}
            </v-card-title>
            <v-card-text>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="project.drawerName"
                    :disabled="saving"
                    :label="$t('project.drawer_name')"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="project.notice"
                    :disabled="saving"
                    :label="$t('project.notice')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="project.installation" :disabled="saving"
                              :label="$t('project.installation')"
                              autocomplete="off"
                              dense
                              hide-details
                              light
                              outlined></v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.installationDescription"
                    :disabled="saving"
                    :label="$t('project.installation_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="project.basicMeasurementGuide" :disabled="saving"
                              :label="$t('project.basic_measurement_guide')"
                              autocomplete="off"
                              dense
                              hide-details
                              light
                              outlined></v-checkbox>
                  <v-text-field v-model="project.basicMeasurementGuideDrawer" :disabled="saving"
                                :label="$t('project.basic_measurement_guide_drawer')"
                                autocomplete="off"
                                class="mt-2"
                                dense
                                hide-details
                                light
                                outlined
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>mdi mdi-draw</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.basicMeasurementGuideDescription"
                    :disabled="saving"
                    :label="$t('project.basic_measurement_guide_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox
                    v-model="project.workPictures" :disabled="saving"
                    :label="$t('project.work_pictures')"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined></v-checkbox>
                  <!--<v-text-field v-model="project.workPicturesDrawer" :disabled="saving"
                                :label="$t('project.work_pictures_drawer')"
                                autocomplete="off"
                                class="mt-2"
                                dense
                                hide-details
                                light
                                outlined
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>mdi mdi-draw</v-icon>
                    </template>
                  </v-text-field>-->
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.workPicturesDescription"
                    :disabled="saving"
                    :label="$t('project.work_pictures_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox
                    v-model="project.constructionDrawings" :disabled="saving"
                    :label="$t('project.construction_drawings')"
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined></v-checkbox>
                  <!--<v-text-field v-model="project.constructionDrawingsDrawer" :disabled="saving"
                                :label="$t('project.construction_drawings_drawer')"
                                autocomplete="off"
                                class="mt-2"
                                dense
                                hide-details
                                light
                                outlined
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>mdi mdi-draw</v-icon>
                    </template>
                  </v-text-field>-->
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.constructionDrawingsDescription"
                    :disabled="saving"
                    :label="$t('project.construction_drawings_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="project.buildingPermission" :disabled="saving"
                              :label="$t('project.building_permission')"
                              autocomplete="off"
                              dense
                              hide-details
                              light
                              outlined></v-checkbox>
                  <v-text-field v-model="project.buildinPermissionDrawer" :disabled="saving"
                                :label="$t('project.building_permission_drawer')"
                                autocomplete="off"
                                class="mt-2"
                                dense
                                hide-details
                                light
                                outlined
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>mdi mdi-draw</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.buildingPermissionDescription"
                    :disabled="saving"
                    :label="$t('project.building_permission_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="project.bvn" :disabled="saving"
                              :label="$t('project.bvn')"
                              autocomplete="off"
                              dense
                              hide-details
                              light
                              outlined></v-checkbox>
                  <v-text-field v-model="project.bvnDrawer" :disabled="saving"
                                :label="$t('project.bvn_drawer')"
                                autocomplete="off"
                                class="mt-2"
                                dense
                                hide-details
                                light
                                outlined
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>mdi mdi-draw</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.bvnDescription"
                    :disabled="saving"
                    :label="$t('project.bvn_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="project.materialsList" :disabled="saving"
                              :label="$t('project.materials_list')"
                              autocomplete="off"
                              dense
                              hide-details
                              light
                              outlined></v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.materialsListDescription"
                    :disabled="saving"
                    :label="$t('project.materials_list_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-2"/>
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox v-model="project.windowsAndDoors" :disabled="saving"
                              :label="$t('project.windows_and_doors')"
                              autocomplete="off"
                              dense
                              hide-details
                              light
                              outlined></v-checkbox>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    v-model="project.windowsAndDoorsDescription"
                    :disabled="saving"
                    :label="$t('project.windows_and_doors_description')"
                    auto-grow
                    autocomplete="off"
                    dense
                    hide-details
                    light
                    outlined
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-col>
  </hb-basic-page>
</template>

<script>
import projectApi from '@/api/project';
import DatePicker from "@/components/DatePicker.vue";
import ProjectLogRow from "@/components/Project/ProjectLogRow.vue";
import ProductionHistoryDialog from "@/components/Production/ProductionHistoryDialog.vue";
import {v4 as uuidv4} from 'uuid';
//import ProjectBlankWarehouseReceiptDialog from "@/components/Project/ProjectBlankWarehouseReceiptDialog.vue";

export default {
  name: 'ProjectView',
  components: {ProductionHistoryDialog, ProjectLogRow, DatePicker},
  data() {
    return {
      tab: 0,
      loading: false,
      loadingParties: false,
      searchPhrase: '',
      showAlert: false,
      backButton: {
        show: true,
        fallback: 'tenant_projects',
      },

      saving: false,
      project: {
        code: '',
        deliveryDate: null,
        estimatedStart: null,
        estimatedEnd: null,
        logs: [],
      },
      parties: [],
      logSuppliers: [
        {label: 'VAARA', value: 'VAARA'},
        {label: 'HONKA', value: 'HONKA'},
      ],
      profiles: [
        {label: 'Perinteinen', value: 'perinteinen'},
        {label: 'Design', value: 'design'},
      ],
      roofTypes: [
        {label: 'Harja', value: 'harja'},
        {label: 'Pulpetti', value: 'pulpetti'},
        {label: 'Tasakerta', value: 'tasakerta'},
      ],
      cornerTypes: [
        {label: 'Jiiri', value: 'jiiri'},
        {label: 'Risti', value: 'risti'},
        {label: 'Tiroli', value: 'tiroli'},
      ],
      foundationTypes: [
        {label: 'Pilari', value: 'pilari'},
        {label: 'Laatta', value: 'laatta'},
      ],

    };
  },
  computed: {
    actionButtons() {
      return {
        save: {
          show: true,
          disabled: this.saving,
          callback: this.save,
          text: "Tallenna",
          primary: true,
          icon: 'mdi mdi-content-save',
        },
      }
    },
    statuses() {
      return projectApi.getProjectStatuses();
    },
  },
  methods: {
    addNewLog() {
      this.project.logs = [...this.project.logs, {
        code: '',
        description: '',
        logSupplier: '',
        profile: '',
        amount: null,
        status: this.project.status || 'CANT_START',
        productionTime: null,
      }];
    },
    addNewContact() {
      this.project.contacts = [...(this.project.contacts || []), {
        name: '',
        role: '',
        phone: '',
        email: '',
        description: '',
        _id: uuidv4(),
        _loading: false,
      }];
    },
    async deleteContact(contact) {
      if (await this.$confirmDelete(this.$t('project.contacts.delete_contact_text'), this.$t('project.contacts.delete_contact_text2'))) {
        contact._loading = true;
        if (contact.id) {
          try {
            await projectApi.deleteContact(this.project.id, contact.id);
            this.project.contacts = this.project.contacts?.filter(c => c._id !== contact._id) || [];
          } catch (e) {
            contact._loading = false;
            this.$showErrorNotification(this.$t('project.contacts.errors.delete'));
          }
        } else {
          this.project.contacts = this.project.contacts?.filter(c => c._id !== contact._id) || [];
        }

      }

    },
    reloadProductionHistoryForProjectLog(pl) {
      const plr = this.$refs.projectLogRow.find(p => p.log?.id === pl.id);
      plr?.getProductionHistory();
    },
    openProductionHistoryDialog(productionHistory) {
      this.$refs.productionHistoryDialog.openEdit(productionHistory)
    },
    async getProject() {
      this.loading = true;
      const project = await projectApi.getProject(this.$route.params.id);
      project.contacts = project.contacts?.map(c => ({...c, _id: uuidv4(), _loading: false}));
      this.project = project;
      this.loading = false;
      //
    },
    async save() {
      this.loading = true;


      try {
        await projectApi.saveProject(this.project);
        this.$showSuccessNotification(this.$t('project.updated'));
        await this.$router.push({name: 'tenant_projects'});
      } catch (e) {
        this.$showErrorNotification(this.$t('project.errors.update'));
      }

      this.loading = false;

    },
    onLogUpdate(log, i) {
      this.project.logs[i] = log;
    },
    onDeleteLog(log, i) {
      this.project.logs.splice(i, 1)
      //this.project.logs = this.project.logs.splice(i,1)
    },
    cancel() {
      this.$router.go(-1);
    },

  },
  async mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office', 'tenant_basic'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }

    if (this.$route.params.id) {
      await this.getProject();
    }

  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}

.toggle_group_buttons {
  height: 48px !important;
  width: 50% !important;
}

</style>
