<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="saving"
                :title="$t('tenants.add_new_tenant')">
    <v-card-text class="scroll-content">
      <v-form ref="form">
        <div :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''" class="mt-4 mb-6">
          {{ $t('required_fields_are_marked_with_asterisk') }} (<span class="red--text">*</span>).
        </div>

        <v-text-field
          v-model="name"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.organization_name')"
          :rules="[$rules.required]"
          autocomplete="organization"
          class="mt-5 pt-4 organization_name required"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="registrationNumber"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.registration_number')"
          autocomplete="registrationNumber"
          class="mt-2 organization_registration_number"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="streetAddress"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.details.street_address')"
          :rules="[$rules.required]"
          autocomplete="streetAddress"
          class="mt-2 organization_street_address_line_1 required"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="streetAddressLine2"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.details.street_address_2')"
          autocomplete="streetAddressLine2"
          class="mt-2 organization_street_address_line_2"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="zipCode"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.details.zip_code')"
          :rules="[$rules.required]"
          autocomplete="zipCode"
          class="mt-2 organization_zip_code required"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="city"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.details.city')"
          :rules="[$rules.required]"
          autocomplete="city"
          class="mt-2 organization_city required"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>

        <country-select v-model="country" :rules="[$rules.required]"
                        required></country-select>

        <v-text-field
          v-model="addressCounty"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.details.state_province_region')"
          autocomplete="county"
          class="mt-2 organization_county"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="dunsNumber"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.details.duns_number')"
          autocomplete="duns"
          class="mt-2 organization_duns"
          color="primary"
          hide-details
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>
      </v-form>

      <v-alert :value="showAlert" class="mt-4" outlined type="error">{{ $t('check_all_required_fields') }}</v-alert>
    </v-card-text>

  </basic-dialog>
</template>

<script>
import BasicDialog from "@/components/BasicDialog";
import CountrySelect from "@/components/CountrySelect";

export default {
  name: 'AddTenantDialog',
  components: {CountrySelect, BasicDialog},
  data() {
    return {
      loading: false,
      saving: false,
      dialog: false,
      name: '',
      registrationNumber: '',
      streetAddress: '',
      streetAddressLine2: '',
      addressCounty: '',
      city: '',
      zipCode: '',
      country: null,
      dunsNumber: null,
      showAlert: false,
      actionButtons: {
        cancel: {
          click: this.cancel,
        },
        save: {
          click: this.saveOrganization,
        }
      }
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    async saveOrganization() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      this.saving = true;

      const data = {
        name: this.name,
        registrationNumber: this.registrationNumber,
        streetAddress: this.streetAddress,
        streetAddressLine2: this.streetAddressLine2,
        city: this.city,
        zipCode: this.zipCode,
        country: this.country,
        addressCounty: this.addressCounty,
        dunsNumber: this.dunsNumber,
      }

      try {
        await this.$adminApi.addTenant(data);
        this.dialog = false;
        this.$showSuccessNotification(this.$t('tenants.tenant_created_success'));
      } catch (err) {
        this.$handleApiError(err, this.$t('tenants.tenant_creation_failed'));
      }
      this.saving = false;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.organization = '';
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
