import axios from 'axios';

const translationApi = {
  async getTranslations(language) {
    const response = await axios.get(language ? `/api/translation/${language}` : '/api/translation');
    return response.data;
  },
  async saveTranslations(translations) {
    const response = await axios({url: `/api/translation`, method: 'POST', data: translations});
    return response.data;
  },
};

export default translationApi;
