<template>
  <hb-basic-page
    :action-buttons="$vuetify.breakpoint.smAndDown ? { add: titleButton } : {}"
    :extension-height="56"
    :loading="loading"
    :noContainerPadding="$vuetify.breakpoint.xsOnly"
    :search-title="`${$t('user_management.search')}...`"
    :subtle-loading="subtleLoading"
    :title="$t('user_management.title')"
    :title-button="titleButton"
    name="app-users-list"
    no-gutters
    @handle-search="handleSearch"
  >
    <!--<template v-slot:app-bar-extension>
      <v-select
        v-model="filter"
        :class="$vuetify.breakpoint.smAndDown ? 'px-1' : $vuetify.breakpoint.mdOnly ? '' : 'px-2'"
        :items="filterOptions"
        :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
        :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '44%' : '' }"
        hide-details
        item-text="desc"
        item-value="value"
        multiple
        prepend-inner-icon="filter_list"
        single-line
        solo
      >
        <template v-slot:selection="{ item, index }">
          <template v-if="filter.length !== filterOptions.length && index === 0">
            <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
            <span class="grey--text caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
          </template>
          <template v-else>
            <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
          </template>
        </template>
      </v-select>
    </template>-->


    <v-col
      v-if="(filteredLocalUsers.length > 0 && !cloudUsersAreFilteredOut) || (filteredCloudUsers.length > 0 && !localUsersAreFilteredOut)"
      :pb-5="$vuetify.breakpoint.xsOnly"
      cols="12"
    >

      <v-data-table :footer-props="{itemsPerPageOptions:[100, 200, 500, -1]}" :headers="headers" :items="filteredUsers"
                    :items-per-page="100" class="transparent">
        <template v-slot:item.email="{item}">
          <span :class="!item.enabled ? 'grey--text text--lighten-1 body-2' : 'body-2'">{{
              item.email || item.username
            }}</span>
          <br/><span class="caption grey--text">{{ [item.firstName, item.lastName].join(' ') }}</span>
        </template>
        <template v-slot:item.tenantRoles="{item}">
          <template v-for="t in item.tenantRoles">
            <div :key="t.tenant" :style="{ color: $randomizeColorFromString(t.name, 60, 55) }">{{ t.name }}</div>
          </template>
        </template>
        <template v-slot:item._roles="{item, isMobile,}">

          <template v-if="!isMobile">
            <template v-for="role in userRoles(item)">
              <v-chip
                :key="role"
                :color="$randomizeColorFromString(role, 60, 45)"
                class="mr-1"
                close
                outlined
                small
                @click:close="removeRoleFromUser(item, role)"
              ><strong>{{ $t('user_management.role.' + role) }}</strong></v-chip
              >
            </template>
          </template>
          <template v-else>
            <template v-for="role in userRoles(item)">
            <span :key="role" :style="{color: $randomizeColorFromString(role, 60, 45)}" class="mb-1 mr-1">{{
                $t('user_management.role.' + role)
              }}</span>
            </template>
          </template>


          <v-menu v-if="!isMobile && availableRolesForUser(item).length > 0" bottom right>
            <template v-slot:activator="{ on }">
              <v-btn class="ma-0" color="primary" icon text v-on="on">
                <v-icon size="22">add_circle</v-icon>
              </v-btn>
            </template>
            <v-list class="pt-0 pb-0" dense>
              <v-subheader>{{ $t('user_management.add_role_to_user') }}:</v-subheader>
              <v-list-item v-for="role in availableRolesForUser(item)" :key="role" @click="addRoleToUser(item, role)">
                <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                  <v-list-item-title class="font-weight-bold">{{
                      $t('user_management.role.' + role)
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item._action="{item}">
          <v-menu :close-on-content-click="$vuetify.breakpoint.smAndUp" bottom left>
            <template v-slot:activator="{ on }">
              <v-btn class="mt-0 mb-0" color="grey" icon text>
                <v-icon size="28" v-on="on">more_vert</v-icon>
              </v-btn>
            </template>
            <v-list class="pt-0 pb-0" dense>
              <template v-if="$vuetify.breakpoint.xsOnly">
                <v-subheader>{{
                    item.agentUser ? $t('user_management.machine_id') : $t('user_management.email')
                  }}:
                </v-subheader>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img v-if="item.imageKey" :src="$getImageUrl(item.imageKey)"></v-img>
                    <v-icon v-else size="18">fas fa-user</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ item.email || item.username }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-subheader>{{ $t('user_management.manage_user') }}:</v-subheader>
              <v-list-item @click="openUserDetails(item)">
                <v-list-item-avatar>
                  <v-icon>settings</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                      $t('user_management.user_settings')
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.enabled" @click="toggleUserState(item)">
                <v-list-item-avatar>
                  <v-icon size="18">fas fa-user-slash</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ $t('user_management.disable') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!item.enabled" @click="toggleUserState(item)">
                <v-list-item-avatar>
                  <v-icon size="18">fas fa-user-check</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ $t('user_management.enable') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template v-if="$vuetify.breakpoint.xsOnly">
                <v-subheader>{{ $t('user_management.manage_user_roles') }}:</v-subheader>
                <v-list-item v-for="role in roles" :key="role" @click="toggleUserRole(item, role)">
                  <v-list-item-avatar>
                    <v-icon v-if="item.roles.includes(role)" :color="$randomizeColorFromString(role, 60, 45)">
                      check_box
                    </v-icon>
                    <v-icon v-else :color="$randomizeColorFromString(role, 60, 45)">check_box_outline_blank</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                    <v-list-item-title class="font-weight-bold">{{
                        $t('user_management.role.' + role)
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>

      </v-data-table>
      <!--<v-list class="pa-0 pb-15" color="transparent" two-line>
        <v-list-item v-if="$vuetify.breakpoint.smAndUp" class="users-header">
          <v-list-item-avatar></v-list-item-avatar>
          <v-list-item-content class="grey--text">
            <v-container fluid ma-0 pa-0>
              <v-row align="center" class="fill-height" justify="center" ma-0>
                <v-col cols="12" lg="5" sm="6" xl="3">
                  <span class="caption">{{ $t('user_management.user') }}</span>
                </v-col>
                <v-col cols="12" lg="7" sm="6" xl="9">
                  <span class="caption">{{ $t('user_management.roles') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action style="width: 36px"></v-list-item-action>
        </v-list-item>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <users-list
          v-if="filter.findIndex(f => f === 1) > -1"
          :allRoles="roles"
          :users="filteredLocalUsers"
          type="local"
          @reload-users="getAllUsers"
        />
        <users-list
          v-if="filter.findIndex(f => f === 0) > -1"
          :allRoles="roles"
          :users="filteredCloudUsers"
          type="user"
          @reload-users="getAllUsers"
        />
      </v-list>-->
    </v-col>

    <user-details-dialog ref="userDetailsDialog" @on-dismiss="getAllUsers"/>
    <add-user-dialog ref="addUserDialog" :roles="roles" @on-dismiss="onAddUserDialogDismiss"/>
  </hb-basic-page>
</template>

<script>
import AddUserDialog from '../components/UserManagement/AddUserDialog.vue';
import adminApi from '../api/admin';
import systemApi from '../api/system';
import orderBy from "lodash/orderBy";
import UserDetailsDialog from "@/components/UserManagement/UserDetailsDialog";

export default {
  name: 'UserManagementView',
  components: {
    UserDetailsDialog,
    /*UsersList: UsersList,*/
    AddUserDialog: AddUserDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      cloudUsers: [],
      localUsers: [],
      roles: [],
      filter: [0, 1, 2, 3],
      filterOptions: [
        {desc: 'Tavalliset käyttäjät', value: 0},
        {desc: 'Jaetut käyttäjät', value: 1},
        {desc: 'Aktiiviset', value: 2},
        {desc: 'Ei aktiiviset', value: 3},
      ],
      titleButton: {
        text: this.$t('user_management.create_new_user'),
        icon: 'person_add',
        callback: this.openAddUserDialog,
        primary: true,
      },
    };
  },
  methods: {
    openUserDetails(user) {
      this.$refs.userDetailsDialog.openUser(user);
    },
    availableRolesForUser(user) {
      const availableRoles = this.roles.filter(role => !role.startsWith('tenant_') && !user?.roles.includes(role));
      return availableRoles;
    },
    toggleUserRole(user, role) {
      if (user.roles.findIndex(r => r === role) > -1) {
        this.removeRoleFromUser(user, role);
      } else {
        this.addRoleToUser(user, role);
      }
    },
    async addRoleToUser(user, role) {
      try {
        const response = await adminApi.addRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
    },
    async removeRoleFromUser(user, role) {
      try {
        const response = await adminApi.deleteRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
    },
    async toggleUserState(user) {
      if (user.username === this.$userInfo.username) {
        const message = user.enabled
          ? this.$t('user_management.you_cannot_disable_yourself')
          : this.$t('user_management.you_cannot_enable_yourself');
        this.$showWarningNotification(message);
        return;
      }

      let response = {};
      let error = false;

      this.subtleLoading = true;

      try {
        if (user.enabled) {
          response = await adminApi.disableUser(user.username);
        } else if (!user.enabled) {
          response = await adminApi.enableUser(user.username);
        }
      } catch (e) {
        error = e;
      }

      if (response && response.result) {
        user.enabled = !user.enabled;
        this.$showSuccessNotification(
          user.enabled ? this.$t('user_management.user_state_set_to_enabled') : this.$t('user_management.user_state_set_to_disabled')
        );
      } else if (error) {
        this.$handleApiError(error, this.$t('user_management.user_state_change_failed'));
      }

      this.subtleLoading = false;
    },
    userRoles(user) {
      return this.roles.filter(role => user?.roles.includes(role));
    },
    openAddUserDialog() {
      this.$refs.addUserDialog.open();
    },
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    onAddUserDialogDismiss() {
      this.getAllUsers();
    },
    async reloadLocalUsers() {
      try {
        this.localUsers = await adminApi.getLocalUsers();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async reloadCloudUsers() {
      try {
        this.cloudUsers = await adminApi.getCloudUsers();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async getAllUsers() {
      if (this.localUsers.length > 0 || this.cloudUsers.length > 0) {
        this.subtleLoading = true;
      } else {
        this.loading = true;
      }
      await this.reloadCloudUsers();
      await this.reloadLocalUsers();
      this.loading = false;
      this.subtleLoading = false;
    },
    filterUsers(users) {
      const filteredUsers = users;

      const filterOutActive = this.filter.findIndex(f => f === 2) === -1;
      const filterOutInactive = this.filter.findIndex(f => f === 3) === -1;

      if ((this.searchPhrase == null || this.searchPhrase.length === 0) && !filterOutActive && !filterOutInactive) {
        return filteredUsers;
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredUsers.filter(user => {
        let found = [];
        const lowerSearchedString =
          (user.email ? user.email.toLowerCase() : '') +
          (user.subject ? user.subject.toLowerCase() : '') +
          (user.tenantRoles ? user.tenantRoles.map(t => t.name).filter(Boolean).join(' ').toLowerCase() : '') +
          (user.roleSet && user.roleSet.length > 0 ? user.roleSet.join(' ').toLowerCase() : '');
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });
        if (filterOutActive && user.enabled) found.push(false);
        if (filterOutInactive && !user.enabled) found.push(false);
        return found.indexOf(false) === -1;
      });
      //return users;
    },
    async getRoles() {
      try {
        this.roles = await systemApi.getRoles();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  computed: {
    headers() {
      const headers = [
        {
          text: this.$t('user_management.user'),
          align: 'start',
          sortable: true,
          value: 'email',
        },
        {
          text: this.$t('user_management.roles'),
          align: 'start',
          sortable: false,
          value: '_roles',
          filterable: false,
        },
        {
          text: this.$t('user_management.memberships'),
          align: 'start',
          sortable: true,
          value: 'tenantRoles',
          filterable: false,
          sort: (a, b) => {

            const aa = a?.map(t => t.name)?.filter(Boolean).join(' ')?.toLowerCase()
            const bb = b?.map(t => t.name)?.filter(Boolean).join(' ')?.toLowerCase()
            if (aa === bb) {
              return 0;
            }
            return aa.localeCompare(bb);
          },
        },
        {
          text: this.$vuetify.breakpoint.smAndDown ? this.$t('user_management.user_settings') : '',
          value: '_action',
          sortable: false,
          filterable: false,
        }
      ];
      return headers;
    },
    filteredUsers() {
      return [...this.filteredLocalUsers, ...this.filteredCloudUsers].map(user => {
        user.tenantRoles = orderBy(user.tenantRoles, ["name"], ["asc"]);
        return user;
      });
    },
    filteredLocalUsers() {
      return this.filterUsers(this.localUsers);
    },
    filteredCloudUsers() {
      return this.filterUsers(this.cloudUsers);
    },
    localUsersAreFilteredOut() {
      return this.filter.findIndex(f => f === 1) === -1;
    },
    cloudUsersAreFilteredOut() {
      return this.filter.findIndex(f => f === 0) === -1;
    },
  },
  mounted() {
    if (!this.$hasMatchingRoles(['admin'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    this.getAllUsers();
    this.getRoles();
  },
};
</script>

<style lang="scss">
.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
