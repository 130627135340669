<template>
  <v-row :class="[subItem && subItem._deleted && 'deleted-row']">
    <v-col cols="12">
      <v-autocomplete
        :items="filteredSubItemCandidates"
        :label="$tc('item.sub_item', 1)"
        :loading="loadingSubItemCandidates"
        :placeholder="$t('item.sub_item_placeholder')"
        :search-input.sync="subItemCandidatesSearchString"
        :value="subItem"
        dense
        hide-details
        item-value="id"
        outlined
        persistent-placeholder
        return-object
        @input="$emit('input', $event)"
      >
        <template v-slot:append-outer>
          <v-btn
            :disabled="loadingSubItemCandidates"
            class="mt-n2"
            icon
            @click="$emit('remove', subItem)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import itemApi from "@/api/item";
import {debounce} from "lodash";

export default {
  name: 'add-or-edit-item-dialog-sub-item',
  props: {
    subItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      subItemCandidates: [],
      loadingSubItemCandidates: false,
      subItemCandidatesSearchString: null,
    }
  },
  computed: {
    filteredSubItemCandidates() {
      return [this.subItem, ...this.subItemCandidates].filter(Boolean).map(item => ({
        ...item,
        text: [item.code, [item.description, item.description2].filter(Boolean).join(' ')].filter(Boolean).join(' - '),
      }))
    }
  },
  watch: {
    subItemCandidatesSearchString(val) {
      if (val?.length > 2) {
        this.getSubItemCandidates(val);
      }
    }
  },
  methods: {
    async getSubItemCandidates(searchString) {
      this.loadingSubItemCandidates = true;
      try {
        const filter = {
          searchString,
        };
        const response = await itemApi.getPaged(filter, {page: 1, itemsPerPage: 50});
        this.subItemCandidates = response.content;
      } catch (err) {
        this.$handleApiError(err);
      }
      this.loadingSubItemCandidates = false;
    }
  },
  created() {
    this.getSubItemCandidates = debounce(this.getSubItemCandidates, 500);
  },
  mounted() {
    this.getSubItemCandidates(null);
  }
}
</script>

<style lang="scss" scoped>

</style>
