import axios from 'axios';
import Helper from '@/helper';
import i18n from '../i18n';

export default {

  async getPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {

    return await axios({
      url: `/api/tenant/project/paged`,
      method: 'post',
      params: {...Helper.tableOptionsToRestPageParams(options)},
      data: filter,
    }).then(r => r.data);
  },
  async getLogsPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    console.log(filter, options)
    return await axios({
      url: `/api/tenant/project/logs/paged`,
      method: 'post',
      params: {...Helper.tableOptionsToRestPageParams(options)},
      data: filter,
    }).then(r => r.data);
  },
  async getProject(id) {
    return await axios({
      url: '/api/tenant/project/' + id,
    })
      .then(r => r.data)
      .then(r => {
        if (!r.visitingAddress) r.visitingAddress = {};
        if (!r.billingAddress) r.billingAddress = {};
        return r;
      });
  },
  async saveProject(data) {
    return await axios({
      url: '/api/tenant/project/save',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async updateProjectStatus(projectId, status) {
    return await axios({
      url: `/api/tenant/project/${projectId}/status`,
      method: 'put',
      data: {status},
    }).then(r => r.data);
  },
  async updateProjectLogStatus(projectLogId, status) {
    return await axios({
      url: `/api/tenant/project/logs/${projectLogId}/status`,
      method: 'put',
      data: {status, projectLogId},
    }).then(r => r.data);
  },
  async deleteLog(projectId, logId) {
    return await axios({
      url: `/api/tenant/project/${projectId}/log/${logId}`,
      method: 'delete',
    }).then(r => r.data);
  },
  async deleteContact(projectId, contactId) {
    return await axios({
      url: `/api/tenant/project/${projectId}/contact/${contactId}`,
      method: 'delete',
    }).then(r => r.data);
  },
  async runRobot(continueFromCode /* String */ = null) {
    return await axios({
      url: '/api/tenant/robot/project-fetch',
      method: 'post',
      data: {
        continueFromCode: continueFromCode
      }
    }).then(r => r.data);
  },
  async refreshScheduledTimes(productionLineId) {
    return this.reschedule(productionLineId, null, null, null);
  },

  async reschedule(productionLineId, projectLogId, startDate, timelineStartDate, overrides = {}) {
    return await axios({
      url: '/api/tenant/project/reschedule',
      data: {
        productionLineId: productionLineId,
        projectLogId: projectLogId,
        startDate: startDate,
        timelineStartDate: timelineStartDate,
        ...overrides
      },
      method: 'post',
    }).then(r => r.data);
  },
  getProjectStatuses() {
    return [
      {label: i18n.t('project.status.CANT_START'), value: 'CANT_START', color: 'error'},
      {label: i18n.t('project.status.CAN_START'), value: 'CAN_START', color: 'info'},
      {label: i18n.t('project.status.PAUSED'), value: 'PAUSED', color: 'info'},
      {label: i18n.t('project.status.MANUFACTURING'), value: 'MANUFACTURING', color: 'warning'},
      {label: i18n.t('project.status.PROCESSED'), value: 'PROCESSED', color: 'success'},
      {label: i18n.t('project.status.DELIVERED'), value: 'DELIVERED', color: 'success'},
      //{label: i18n.t('project.status.INSTALLED'), value: 'INSTALLED'},
      {label: i18n.t('project.status.DONE'), value: 'DONE', color: 'success'},
    ];
  },
};
