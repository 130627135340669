import axios from 'axios';
import Helper from '@/helper';

export default {

    async getPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
        return await axios({
            url: `/api/tenant/warehousereceipt/paged`,
            method: 'post',
            params: {...Helper.tableOptionsToRestPageParams(options)},
            data: filter,
        }).then(r => r.data);
    },
    async get(id) {
        return await axios({
            url: '/api/tenant/warehousereceipt/' + id,
        })
            .then(r => r.data)
            .then(r => {
                return r;
            });
    },
    async create(data) {
        return await axios({
            url: '/api/tenant/warehousereceipt',
            method: 'post',
            data,
        }).then(r => r.data);
    },
    async update(data) {
        return await axios({
            url: '/api/tenant/warehousereceipt/' + data.id,
            method: 'put',
            data,
        }).then(r => r.data);
    },
    async transferToAdminet(data) {
        return await axios({
            url: `/api/tenant/warehousereceipt/${data.id}/transfer`,
            method: 'post',
            data,
        }).then(r => r.data);
    },
};
