<template>
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="saving"
                :title="$t('tenants.edit_organization')">
    <v-card-text class="scroll-content">
      <v-form ref="form">
        <div :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''" class="mt-4 mb-6">
          {{ $t('required_fields_are_marked_with_asterisk') }} (<span class="red--text">*</span>).
        </div>

        <v-text-field
          v-model="currentTenant.name"
          :dense="$vuetify.breakpoint.xsOnly"
          :label="$t('tenants.organization_name')"
          :rules="[$rules.required]"
          autocomplete="organization"
          class="mt-5 pt-4 organization_name required"
          color="primary"
          outlined
          type="text"
          validate-on-blur
        ></v-text-field>

      </v-form>

      <v-alert :value="showAlert" class="mt-4" outlined type="error">
        {{ $t('check_all_required_fields') }}
      </v-alert>
    </v-card-text>
  </basic-dialog>
</template>

<script>
import BasicDialog from "@/components/BasicDialog";

export default {
  name: 'TenantDetailsDialog',
  components: { BasicDialog },
  data() {
    return {
      dialog: false,
      saving: false,
      organization: '',
      showAlert: false,
      dunsLocked: false,
      currentTenant: {},
      actionButtons: {
        cancel: {
          click: this.cancel,
        },
        save: {
          click: this.saveOrganization,
        }
      }
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    async saveOrganization() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      this.saving = true;
      try {
        await this.$tenantApi.updateCurrentTenant(this.currentTenant);
        this.dialog = false;
        this.$showSuccessNotification(this.$t('tenants.organization_update_success'));
      } catch (err) {
        this.$handleApiError(err, this.$t('tenants.organization_update_failed'));
      }
      this.saving = false;
    },
    open(tenant) {
      this.dialog = true;
      this.currentTenant = Object.assign({}, tenant);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.currentTenant = {};
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-content {
  max-height: calc(100vh - 240px);
  overflow: auto;
}

@media screen and (min-width: 600px) {
  .scroll-content {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
</style>
