<template>
  <v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('project.blank_warehousereceipt.title') }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-alert type="info">
          {{ $t('project.blank_warehousereceipt.info') }}
        </v-alert>
        <v-simple-table class="pt-3">
          <tbody>
          <tr :key="`${log.id}`">
            <td class="py-2">
              <!--<span v-if="log && log.log">{{ log.log.code }} - {{ log.log.description }} {{
                  log.log.description2
                }} </span>-->
              <item-autocomplete :disabled="saving" :filter="blankFilter" :label="$t('warehousereceipt.blank')"
                                 :value="getLogBlank(log)"
                                 readonly/>
            </td>
            <td class="py-2" style="width: 180px">
              <v-text-field
                v-model.number="log.amount"
                :disabled="saving"
                :label="$t('project.logs.amount')"
                :readonly="false"
                autocomplete="off"
                class="custom-input number-field"
                dense
                hide-details
                inputmode="decimal"
                light
                outlined
                type="number"
                validate-on-blur
              >
                <template v-slot:append>
                  <span v-if="log.log">&nbsp;{{ log.log.unit }}</span>
                </template>
              </v-text-field>
            </td>
          </tr>
          <tr>
            <th colspan="1">
              {{ $t('project.loss_production_history_text') }}
            </th>
            <td>
              <v-text-field
                v-model.number="lossAmount"
                :disabled="saving"
                :label="$t('project.loss_amount')"
                :readonly="false"
                autocomplete="off"
                class="custom-input number-field"
                dense
                hide-details
                inputmode="decimal"
                light
                outlined
                type="number"
                validate-on-blur
                @input="lossAmountChangedManually = true"
              >
                <template v-slot:append>
                  <span v-if="log.log">&nbsp;{{ log.log.unit }}</span>
                </template>
              </v-text-field>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :block="$vuetify.breakpoint.xsOnly" color="default" text @click="show = false">
          {{ $t('project.blank_warehousereceipt.close') }}
        </v-btn>
        <v-btn :block="$vuetify.breakpoint.xsOnly" :loading="saving" color="primary"
               @click="addWarehouseReceiptFromLog">
          {{ $t('project.blank_warehousereceipt.create_warehousereceipt') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
<script>

import warehousereceipt from "@/api/warehousereceipt";
import ItemAutocomplete from "@/components/Items/ItemAutocomplete.vue";
import dayjs from "dayjs";
import productionhistory from "@/api/productionhistory";

export default {
  name: 'ProjectBlankWarehouseReceiptDialog',
  components: {ItemAutocomplete},
  emits: ['close'],
  data() {
    return {
      show: false,
      saving: false,
      log: [],
      project: {},
      originalAmount: 0,
      lossAmount: 0,
      lossAmountChangedManually: false,
    }
  },
  props: {},
  computed: {
    blankFilter() {
      return {itemGroupCodes: this.$store.state.logBlankGroupCodes}
    },
  },
  methods: {
    open(projectLog) {
      this.show = true;
      this.project = projectLog.project;
      this.log = structuredClone(projectLog);
      this.originalAmount = structuredClone(projectLog.amount);
      this.lossAmount = 0;
      this.lossAmountChangedManually = false;

    },
    getLogBlank(log) {
      return log?.log?.subItems?.find(item => this.$store.state.logBlankGroupCodes.includes(item?.group?.code));
    },
    async addWarehouseReceiptFromLog() {
      this.saving = true;
      try {
        const receipt = {
          project: {id: this.project.id},
          description: `${this.$t('warehousereceipt.blank')} - ${this.project.code} ${this.project.name}`,
          transferToAdminet: true,
          rows: (() => {
            const l = this.log;
            const blank = l.log.subItems?.find(item => this.$store.state.logBlankGroupCodes.includes(item?.group?.code));
            return structuredClone([blank]).filter(Boolean).map(b => {
              return {
                item: {...b},
                littera: this.$store.state.blankLittera,
                amount: l.amount,
                description: this.$t('warehousereceipt.blank'),
              }
            });
          })(),
        };


        await this.createProductionHistoryLossAmount();
        await this.createProductionHistoryWarehouseReceipt();
        await warehousereceipt.create(receipt);


        this.$showSuccessNotification(this.$t('warehousereceipt.created'));
        this.show = false;
      } catch (e) {
        this.$showErrorNotification(e);
      }
      this.saving = false;
    },
    async createProductionHistoryLossAmount() {

      console.log('amnakjakjh')
      if (this.lossAmount <= 0) {
        return;
      }

      const productionHistory = {
        meters: this.lossAmount,
        notice: null,
        shiftInitials: '-',
        project: this.project,
        projectLog: this.log,
        log: this.log.log,
        blank: this.getLogBlank(this.log),
        type: 'LOSS',
        startTime: dayjs().toISOString(),
        endTime: dayjs().toISOString(),
      }
      try {
        await productionhistory.create(productionHistory);
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
    },
    async createProductionHistoryWarehouseReceipt() {
      if (this.log.amount <= 0) {
        return;
      }

      this.productionHistory = {
        meters: this.log.amount,
        notice: null,
        shiftInitials: '-',
        project: this.project,
        projectLog: this.log,
        log: this.log.log,
        blank: this.getLogBlank(this.log),
        type: 'WAREHOUSE_RECEIPT',
        startTime: dayjs().toISOString(),
        endTime: dayjs().toISOString(),
      }
      try {
        await productionhistory.create(this.productionHistory);
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
    }
  },
  watch: {
    [`log.amount`](val) {
      if (this.lossAmountChangedManually) {
        return;
      }
      let lossAmount = val - this.originalAmount;
      if (lossAmount >= 0) {
        this.lossAmount = parseFloat(lossAmount.toFixed(1));
      } else {
        this.lossAmount = 0;
      }
    },
    show() {
      if (!this.show) {
        this.$emit('close', this.log)
      }

    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.log-blank {
  color: #7c7c7c;
  display: block;
}

</style>
