<template>
<div>
  <v-card-actions outlined flat class="px-0">
    <v-btn
      outlined
      :small="$vuetify.breakpoint.smAndUp"
      :x-small="$vuetify.breakpoint.xsOnly"
      @click="focus = ''">
      {{ $t('today') }}
    </v-btn>
    <v-btn fab text x-small @click="prev">
      <v-icon small>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn fab text x-small @click="next">
      <v-icon small>
        mdi-chevron-right
      </v-icon>
    </v-btn>
    <div v-if="$refs.calendar">
      <div class="ml-2 text-subtitle-2 mt-1">
        {{ $refs.calendar.title }}
      </div>
    </div>
    <v-spacer></v-spacer>
  </v-card-actions>
  <v-calendar ref="calendar"
              color="accent"
              weekdays="1, 2, 3, 4, 5, 6, 0"
              :events="events"
              :locale="$i18n.locale"
              v-model="focus"
              show-week
              locale-first-day-of-year="4"
              @click:event="handleEventClick"
              @click:date="handleDateClick"
              type="month" />
  <add-or-edit-production-line-exception ref="addOrEditProductionLineException" @saved="handleReload"/>
</div>
</template>

<script>
import AddOrEditProductionLineException from "@/components/ProductionLine/AddOrEditProductionLineException";
export default {
  name: "ProductionLineCalendar",
  components: {AddOrEditProductionLineException},
  props: {
    productionLine: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      focus: '',
    }
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    handleReload() {
      this.$emit('reload');
    },
    handleEventClick(e) {
      this.$refs.addOrEditProductionLineException?.open(this.productionLine, e.event);
    },
    handleDateClick(e) {
      this.$refs.addOrEditProductionLineException?.open(this.productionLine, {date: e.date});
    },
    addException() {
      this.$refs.addOrEditProductionLineException?.open(this.productionLine, null);
    },
  },
  computed: {
    productionExceptions() {
      return this.productionLine.productionTimeExceptions;
    },
    events() {
      if (this.productionExceptions?.length > 0) {
        const productionExceptions = this.productionExceptions;
        return productionExceptions.map(productionException => {
          const copy = {...productionException};
          copy.start = copy.date;
          copy.name = `${copy.duration}h - ${copy.description}`;
          copy.timed = false;
          copy.color = copy.type === 'AVAILABLE' ? 'green' : 'red';
          return copy;
        });
      }
      return [];
    },
  },
  mounted() {
    this.$forceUpdate(); // jostain syystä kalenterin title ei näy ilman tätä
  }
}
</script>

<style scoped>

</style>
