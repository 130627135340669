<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon v-if="$vuetify.breakpoint.smAndUp && icon" left>{{ icon }}</v-icon>
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "BasicButton",
  props: {
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
