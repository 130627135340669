import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';
import RedirectToTenantStartView from '@/views/RedirectToTenantStartView';

// Admin views
import UserManagementView from './views/UserManagementView.vue';
import Tenants from './views/TenantsView.vue';
import TranslationsView from '@/views/main/TranslationsView';

// User views
import UserProfile from './views/UserProfile.vue';

// Tenant views
import TenantContainer from './views/TenantContainer.vue';
import TenantUserManagementView from './views/tenant/TenantUserManagementView.vue';
import TenantManagementView from './views/tenant/TenantManagementView.vue';
import WorkTasksView from '@/views/tenant/WorkTaskView';

import PartiesView from '@/views/tenant/PartiesView';
import PartyView from '@/views/tenant/PartyView';

import PersonsView from '@/views/tenant/PersonsView';
import PersonView from '@/views/tenant/PersonView';
import ItemsView from '@/views/tenant/ItemsView';

import Logout from "@/views/tenant/Logout";

import AccessDeniedView from "@/views/AccessDeniedView";
import PageNotFound from "@/views/PageNotFound";
import ProjectsView from "@/views/tenant/ProjectsView.vue";
import ProjectView from "@/views/tenant/ProjectView.vue";
import ProductionLinesView from "@/views/tenant/ProductionLinesView/ProductionLinesView.vue";
import ProductionTimelineView from "@/views/tenant/ProductionTimelineView/ProductionTimelineView.vue";
import WarehouseReceiptsView from "@/views/tenant/WarehouseReceiptsView.vue";
import ProductionView from "@/views/tenant/ProductionView/ProductionView";
import NoTenantsView from "@/views/NoTenantsView";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/',
      name: 'start',
      component: RedirectToTenantStartView,
    },
    {
      path: '/no_tenants',
      name: 'no_tenants',
      component: NoTenantsView,
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/access_denied',
      name: 'access_denied',
      component: AccessDeniedView,
    },
    {
      path: '/t/:tenantId',
      props: true,
      component: TenantContainer,
      children: [
        {
          path: 'users',
          name: 'tenant_users',
          props: true,
          component: TenantUserManagementView,
        },
        {
          path: 'settings',
          name: 'tenant_settings',
          props: true,
          component: TenantManagementView,
        },
        {
          path: 'projects',
          name: 'tenant_projects',
          component: ProjectsView,
        },
        {
          path: 'productionlines',
          name: 'tenant_production_lines',
          component: ProductionLinesView,
        },
        {
          path: 'warehousereceipts',
          name: 'tenant_warehouse_receipts',
          component: WarehouseReceiptsView,
        },
        {
          path: 'productiontimeline',
          name: 'tenant_production_timeline',
          component: ProductionTimelineView,
        },
        {
          path: 'projects/p/:id',
          name: 'tenant_project',
          props: true,
          component: ProjectView,
        },
        {
          path: 'persons',
          name: 'tenant_persons',
          component: PersonsView,
        },
        {
          path: 'person',
          name: 'tenant_new_person',
          props: true,
          component: PersonView,
        },
        {
          path: 'person/:id',
          name: 'tenant_person',
          props: true,
          component: PersonView,
        },
        {
          path: 'parties',
          name: 'tenant_parties',
          component: PartiesView,
        },
        {
          path: 'party',
          name: 'tenant_new_party',
          props: true,
          component: PartyView,
        },
        {
          path: 'party/:id/:tab?',
          name: 'tenant_party',
          props: true,
          component: PartyView,
        },
        {
          path: 'items',
          name: 'tenant_items',
          component: ItemsView,
        },
        {
          path: 'worktasks',
          name: 'tenant_work_tasks_view',
          component: WorkTasksView,
        },
        {
          path: 'production',
          name: 'tenant_production',
          component: ProductionView,
        }
      ],
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
    },
    {
      path: '/translations',
      name: 'translations',
      component: TranslationsView,
    },
    {
      path: '*',
      name: 'page_not_found',
      component: PageNotFound,
    },
  ],
});
