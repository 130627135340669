import { render, staticRenderFns } from "./ItemsView.vue?vue&type=template&id=63a6d22f&scoped=true"
import script from "./ItemsView.vue?vue&type=script&lang=js"
export * from "./ItemsView.vue?vue&type=script&lang=js"
import style0 from "./ItemsView.vue?vue&type=style&index=0&id=63a6d22f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63a6d22f",
  null
  
)

export default component.exports