<template>
  <v-col cols="12">
    <v-alert
      class="text-left"
      :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
      :text="uiComponentProperties.text"
      :value="true"
      v-if="error"
      type="error"
    >
      {{ error }}
    </v-alert>

    <v-text-field
      v-model="username"
      :label="$t('login.username')"
      :disabled="sent"
      autofocus
      :color="uiComponentProperties.inputColor"
      :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
      :outlined="uiComponentProperties.outlined"
      hide-details
      class="mb-8"
      v-on:keyup.enter="submit"
    />

    <v-text-field
      v-model="code"
      v-if="sent"
      :label="$t('login.code')"
      :color="uiComponentProperties.inputColor"
      hide-details
      class="mb-8"
      :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
      :outlined="uiComponentProperties.outlined"
    />

    <v-text-field
      v-model="password"
      v-if="sent"
      type="password"
      :label="$t('login.new_password')"
      :color="uiComponentProperties.inputColor"
      :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
      :outlined="uiComponentProperties.outlined"
      hide-details
      class="mb-8"
      v-on:keyup.enter="verify"
    />

    <div class="text-center">
      <template v-if="sent">
        <v-btn
          :rounded="uiComponentProperties.rounded"
          class="font-weight-bold"
          block
          large
          :class="uiComponentProperties.buttonClass"
          :color="uiComponentProperties.buttonColor"
          @click="verify"
          :disabled="!username"
          :loading="loading"
        >
          <v-icon left>mdi mdi-send</v-icon>
          {{ $t('login.change_password') }}
        </v-btn>
        <v-btn
          :rounded="uiComponentProperties.rounded"
          small
          block
          :color="uiComponentProperties.buttonColor"
          class="mt-3 font-weight-regular"
          text
          @click="submit"
          :disabled="loading"
        >
          {{ $t('login.resend_code') }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          :rounded="uiComponentProperties.rounded"
          class="font-weight-bold"
          block
          large
          :class="uiComponentProperties.buttonClass"
          :color="uiComponentProperties.buttonColor"
          @click="submit"
          :disabled="!username"
          :loading="loading"
        >
          <v-icon left>mdi mdi-send</v-icon>
          {{ $t('login.send_code') }}
        </v-btn>
      </template>
      <v-btn
        :rounded="uiComponentProperties.rounded"
        small
        block
        :color="uiComponentProperties.buttonColor"
        class="mt-3 font-weight-regular"
        text
        @click="signIn"
        :disabled="loading"
      >
        <v-icon left>mdi mdi-arrow-left</v-icon>
        {{ $t('login.back_to_sign_in') }}
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'ForgotPassword',
  props: ['forgotPasswordConfig', 'uiComponentProperties'],
  data() {
    return {
      loading: false,
      username: '',
      code: '',
      password: '',
      error: '',
      sent: false,
      logger: {},
      amplifyUI: AmplifyUI,
    };
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Reset your password'),
      };
      return Object.assign(defaults, this.forgotPasswordConfig || {});
    },
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    submit: function () {
      if (this.username.length === 0) return;

      this.loading = true;
      this.username = this.username.toLowerCase();
      this.$Amplify.Auth.forgotPassword(this.username)
        .then(() => {
          this.setError(false);
          this.sent = true;
          this.loading = false;
          this.logger.info('forgotPassword success');
        })
        .catch(e => {
          this.setError(e);
          this.loading = false;
        });
    },
    verify: function () {
      this.loading = true;
      this.username = this.username.toLowerCase();
      this.$Amplify.Auth.forgotPasswordSubmit(this.username, this.code, this.password)
        .then(() => {
          this.setError('');
          this.logger.info('forgotPasswordSubmit success');
          this.loading = false;
          AmplifyEventBus.$emit('authState', 'signIn');
        })
        .catch(e => {
          this.setError(e);
          this.loading = false;
        });
    },
    signIn: function () {
      AmplifyEventBus.$emit('authState', 'signIn');
    },
    setError: function (e) {
      if (!e) {
        this.error = '';
        return;
      }
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
  },
};
</script>
