<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row align="center" justify="center" no-gutters>
          <div :class="noBottomPadding ? '' : 'pb-8 pb-md-16'" class="text-center px-5">
            <v-row v-if="titleIcon" :style="{height: $vuetify.breakpoint.xsOnly ? dense ? '40px' : '80px' : dense ? '80px' : '140px'}" align="center"
                   justify="center"
                   no-gutters>
              <v-fab-transition>
                <v-icon v-if="iconDelayDone" :color="titleIconColor ? titleIconColor : 'secondary'"
                        :size="$vuetify.breakpoint.xsOnly ? dense ? '40' : '80' : dense ? '80' : '140'">{{ titleIcon }}
                </v-icon>
              </v-fab-transition>
            </v-row>
            <div v-else-if="titleImage" class="image-holder">
              <div class="grey lighten-5 rounded-circle" style="width: 100%; height: 100%;">
                <v-slide-x-transition>
                  <v-row v-if="imageIsLoaded" align="center" class="fill-height" justify="center" no-gutters>
                    <img :src="titleImage" style="height: 80%;"/>
                  </v-row>
                </v-slide-x-transition>
              </div>
            </div>
            <div :class="[$vuetify.breakpoint.xsOnly ? dense ? 'subtitle-1 serif--text' : 'title' : dense ? 'title' : 'headline', dense ? 'mt-2 mb-2' : 'mt-5 mb-5']" class="font-weight-bold">
              {{ title }}
            </div>
            <div :class="$vuetify.breakpoint.xsOnly ? dense ? 'body-2' : 'body-1' : dense ? 'subtitle-2' : 'subtitle-1'">{{ description }}</div>
            <div v-if="reloadButton">
              <v-btn class="mt-5" color="primary" @click="$reloadApp">
                <v-icon :class="ButtonIconColor && 'no-gradient'" :color="ButtonIconColor" left>mdi-refresh</v-icon>
                {{ $t('reload') }}
              </v-btn>
            </div>
            <div v-if="functionButton">
              <v-btn class="mt-5" color="primary" @click="propFunction">
                <v-icon :class="ButtonIconColor && 'no-gradient'" :color="ButtonIconColor" left>
                  {{ functionButtonIcon }}
                </v-icon>
                {{ functionButtonText }}
              </v-btn>
            </div>
            <div v-if="descriptionExtra" class="text-body-2 text-sm-body-1 primary--text text--darken-1 mt-10">
              {{ descriptionExtra }}
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <template v-if="titleImage">
      <img :src="titleImage" style="display: none;" @load="imageIsLoaded = true"/>
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'NoContentView',
  props: {
    titleIcon: String,
    titleIconColor: String,
    title: String,
    description: String,
    descriptionExtra: String,
    reloadButton: {
      type: Boolean,
      default: true,
    },
    functionButton: {
      type: Boolean,
      default: false,
    },
    propFunction: Function,
    functionButtonIcon: String,
    ButtonIconColor: String,
    functionButtonText: String,
    noBottomPadding: {
      type: Boolean,
      default: false,
    },
    titleImage: String,
    dense: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      iconDelayDone: false,
      imageIsLoaded: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.iconDelayDone = true;
    }, 200);
  }
};
</script>

<style lang="scss" scoped>
.image-holder {
  position: relative;
  margin: auto;
  width: 45vw;
  height: 45vw;
}

@media screen and (min-width: 600px) {
  .image-holder {
    width: 15vw;
    height: 15vw;
  }
}
</style>
