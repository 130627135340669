<template>
  <div class="users-list">
    <v-progress-linear
      v-show="subtleLoading"
      :indeterminate="true"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
    ></v-progress-linear>

    <template v-for="user in childUsers">
      <div :key="user.id">
        <v-list-item style="background: none">
          <v-list-item-avatar
            :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject) : 'grey lighten-2'"
            class="user-avatar"
          >
            <v-img v-if="user.imageKey != null" :src="$getImageUrl(user.imageKey)"></v-img>
            <v-icon v-else color="white" size="36" style="margin-top: 8px">fas fa-user</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-container fluid ma-0 pa-0>
              <v-row align="center" class="fill-height" justify="center" ma-0>
                <v-col :class="!user.enabled ? 'grey--text text--lighten-1' : ''" cols="12" lg="5" sm="6" xl="3">
                  {{ user.email ? user.email : user.subject }}
                  <template v-if="user.firstName && user.lastName">
                    <br/>
                    <span class="caption grey--text">{{ user.firstName }} {{ user.lastName }}</span>
                  </template>
                  <template v-if="$vuetify.breakpoint.xsOnly">
                    <div>
                      <span
                        v-for="role in userRoles(user)"
                        :key="role"
                        :style="{ color: $randomizeColorFromString(role, 60, 45) }"
                        class="caption"
                      >{{ $t('user_management.role.' + role) }}
                      </span>
                    </div>
                  </template>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp" lg="7" sm="6" xl="9">
                  <template v-for="role in userRoles(user)">
                    <v-chip
                      :key="role"
                      :color="$randomizeColorFromString(role, 60, 45)"
                      class="mr-1"
                      close
                      outlined
                      small
                      @click:close="removeRoleFromUser(user, role)"
                    ><strong>{{ $t('user_management.role.' + role) }}</strong></v-chip
                    >
                  </template>
                  <v-menu v-if="availableRolesForUser(user).length > 0" bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn class="ma-0" color="primary" icon text v-on="on">
                        <v-icon size="22">add_circle</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pt-0 pb-0" dense>
                      <v-subheader>{{ $t('user_management.add_role_to_user') }}:</v-subheader>
                      <v-list-item v-for="role in availableRolesForUser(user)" :key="role"
                                   @click="addRoleToUser(user, role)">
                        <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                          <v-list-item-title class="font-weight-bold">{{
                              $t('user_management.role.' + role)
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu :close-on-content-click="$vuetify.breakpoint.smAndUp" bottom left>
              <template v-slot:activator="{ on }">
                <v-btn class="mt-0 mb-0" color="grey" icon text>
                  <v-icon size="28" v-on="on">more_vert</v-icon>
                </v-btn>
              </template>
              <v-list class="pt-0 pb-0" dense>
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ $t('user_management.email') }}:</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="18">fas fa-user</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ user.email }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-subheader>{{ $t('user_management.manage_user') }}:</v-subheader>
                <v-list-item @click="removeUserFromTenant(user)">
                  <v-list-item-avatar>
                    <v-icon size="18">fas fa-user-slash</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('tenants.delete_user') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-switch v-model="user.designerMissingEmail" dense
                              @change="(val) => updateUserDesignerMissingEmail(user, val)"></v-switch>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{
                        $t('user_management.designer_missing_email')
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ $t('user_management.manage_user_roles') }}:</v-subheader>
                  <v-list-item v-for="role in childAllRoles" :key="role"
                               @click="toggleUserRole(user, role)">
                    <v-list-item-avatar>
                      <v-icon v-if="user.roles.includes(role)" :color="$randomizeColorFromString(role, 60, 45)">
                        check_box
                      </v-icon>
                      <v-icon v-else :color="$randomizeColorFromString(role, 60, 45)">check_box_outline_blank</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                      <v-list-item-title class="font-weight-bold">{{
                          $t('user_management.role.' + role)
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </template>
  </div>
</template>

<script>
import tenantApi from '../../../api/tenant';

export default {
  name: 'UsersList',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    allRoles: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      subtleLoading: false,
      childAllRoles: this.allRoles,
      childUsers: this.users,
    };
  },
  watch: {
    allRoles() {
      this.childAllRoles = this.allRoles;
    },
    users() {
      this.childUsers = this.users;
    },
  },
  methods: {
    reloadUsers() {
      this.$emit('reload-users');
    },
    userRoles(user) {
      return this.childAllRoles.filter(role => user.roles.includes(role));
    },
    availableRolesForUser(user) {
      const availableRoles = this.childAllRoles.filter(role => role.startsWith('tenant_') && !user.roles.includes(role));
      return availableRoles;
    },
    toggleUserRole(user, role) {
      if (user.roles.findIndex(r => r === role) > -1) {
        this.removeRoleFromUser(user, role);
      } else {
        this.addRoleToUser(user, role);
      }
    },
    async addRoleToUser(user, role) {
      const userIsAdmin = this.childUsers.findIndex((user) => user.id === this.$userInfo.id
        && user.tenantRoles.findIndex((t) => (t.tenant === this.$tenant && t.roleSet.some((e) => e === 'tenant_admin'))) > -1);
      if (userIsAdmin === -1) {
        this.$showErrorNotification(this.$t('user_management.user_no_longer_admin'));
        return
      }
      try {
        const response = await tenantApi.addRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
      this.reloadUsers();
    },
    async removeRoleFromUser(user, role) {
      const userIsAdmin = this.childUsers.findIndex((user) => user.id === this.$userInfo.id
        && user.tenantRoles.findIndex((t) => (t.tenant === this.$tenant && t.roleSet.some((e) => e === 'tenant_admin'))) > -1);
      if (userIsAdmin === -1) {
        this.$showErrorNotification(this.$t('user_management.user_no_longer_admin'));
        return
      }
      if (role === 'tenant_admin') {
        const otherTenantAdmins = this.childUsers.findIndex((user) => user.id !== this.$userInfo.id
          && user.tenantRoles.findIndex((t) => (t.tenant === this.$tenant && t.roleSet.some((e) => e === 'tenant_admin'))) > -1);
        if (otherTenantAdmins === -1) {
          this.$showErrorNotification(this.$t('user_management.disallow_tenant_admin_removal_if_last'));
          return;
        }
        if (user.id === this.$userInfo.id) {
          const confirm = await this.$confirmDelete(this.$t('delete'), this.$t('user_management.remove_own_admin_confirm'));
          if (!confirm) return;
        }
      }

      try {
        const response = await tenantApi.deleteRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
      this.reloadUsers();
    },
    async removeUserFromTenant(user) {
      try {
        await tenantApi.removeUserFromTenant(user.id);
        this.reloadUsers();
        this.$showSuccessNotification(this.$t('tenants.user_delete_success'));
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('tenants.user_delete_fail'));
      }
    },
    async updateUserDesignerMissingEmail(user, val) {

      await tenantApi.updateUserDesignerMissingEmail(user.subject, val)
    }
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  overflow: hidden;
}
</style>
