<template>
  <hb-basic-page :loading="true"></hb-basic-page>
</template>

<script>
export default {
  name: 'RedirectToTenantStartView',
  mounted() {
    if (this.$isLoggedIn && !this.$tenant && !this.$isAdmin) {
      this.$router.push({name: 'no_tenants'})
    } else if (this.$isLoggedIn && this.$tenant != null) {
      if (this.$isOfficeUser || this.$isTenantAdmin) {
        this.$router.push({name: 'tenant_production_timeline', params: {tenantId: this.$tenant}});
      } else if (this.$isTenantBasic) {
        this.$router.push({name: 'tenant_production', params: {tenantId: this.$tenant}});
      }
    } else if (this.$isLoggedIn && this.$tenant == null && this.$isAdmin) {
      this.$router.push({name: 'tenants'});
    }
  },
};
</script>

<style scoped></style>
