import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './config/translations';

Vue.use(VueI18n);

Object.keys(messages).forEach(lang => {
  if (!messages[lang]['translations']) {
    messages[lang]['translations'] = {};
  }
  messages[lang]['translations']['select_languages'] = {
    fi: 'Suomi',
    en: 'English',
  }
})
// Initialize the localization library
const i18n = new VueI18n({
  locale: 'fi',
  fallbackLocale: 'fi',
  messages,
});

export default i18n;
