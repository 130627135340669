<template>
  <hb-basic-page
    v-if="$isTenantAdmin"
    :action-buttons="$vuetify.breakpoint.smAndDown ? { add: titleButton } : {}"
    :fill-height="true"
    :loading="false"
    :no-gutters="true"
    :search-title="`${$t('translations.search')}...`"
    :title="$tc('translations.title', 2)"
    :title-button="titleButton"
    class="parties-view"
    fluid
    @handle-search="debouncedSearch"
  >
    <v-col class="gradient-icons" cols="12">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col cols="12">
            <v-data-table
              :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
              :headers="headers"
              :items="filteredTranslations"
              :items-per-page="50"
              :loading="loading"
              :options.sync="options"
              class="h-100 gradient-info__table"
              style="cursor: pointer"
              @click:row="openEditDialog"
            >
            </v-data-table>
          </v-col>
          <edit-dialog ref="editDialog" @refresh="reload"></edit-dialog>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import debounce from 'lodash.debounce';
import TranslationService from "@/service/translation.service";
import EditDialog from "@/components/Translations/EditDialog";

//:title-button="titleButton"
export default {
  name: 'TranslationsView',
  components: {EditDialog},
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      editDialog: false,
      dialogTranslation: {},
      dialogValid: false,

      translations: [],
      titleButton: {
        text: this.$t('translations.create_new'),
        icon: 'mdi mdi-plus',
        callback: this.createNew,
        primary: true,
      },
    };
  },

  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, {maxWait: 5000});
  },
  mounted() {
    if (!this.$hasMatchingRoles(['admin'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    this.reload();
  },
  computed: {
    filteredTranslations() {
      return this.translations.filter(translation => {
        return translation.searchString.toLowerCase().includes(this.searchString.toLowerCase());
      });
    },
    headers() {
      return [
        {
          text: this.$t('translations.tid'),
          value: 'tid',
        },
        ...TranslationService.languages.map(language => ({
          text: this.$t('translations.languages.' + language),
          value: language,
        })),
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ];
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      await TranslationService.getTranslations();
      const translations = TranslationService.atArray;
      translations.map(translation => {
        let translationSearchString = translation.tid;
        TranslationService.languages.forEach(language => {
          translationSearchString += translation[language];
        });
        translation.searchString = translationSearchString;
        return translation;
      });
      this.translations = translations;
      this.loading = false;
    },
    createNew() {
      this.$refs.editDialog.open({});
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    openEditDialog(item) {
      this.$refs.editDialog.open(item);
    }
  },
  watch: {
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    searchString() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
