<template>
  <hb-basic-page
    v-if="$isTenantAdmin || $isOfficeUser"
    :action-buttons="actionButtons"
    :fill-height="true"
    :hide-app-bar="$vuetify.breakpoint.smAndDown"
    :subtle-loading="!loading && updating"
    :title="$tc('production_timeline.title', 2)"
    class="production-lines-view"
    fluid
    no-container-padding
    no-gutters
  >
    <v-fade-transition>
      <v-col class="fill-height production-timeline-content" cols="12">
        <v-row :class="[$vuetify.breakpoint.smAndDown && 'flex-column', unqueuedMinimized && 'minimize-unqueued']"
               class="fill-height"
               no-gutters>
          <v-col :class="$vuetify.breakpoint.mdAndUp && 'px-3 pb-3'" class="unqueued fill-height" lg="5" md="5" xl="4">
            <v-card :class="$vuetify.breakpoint.smAndDown && 'grey lighten-3'" :outlined="$vuetify.breakpoint.mdAndUp"
                    :tile="$vuetify.breakpoint.smAndDown"
                    class="fill-height">
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-card-title>{{ $t('production_timeline.unqueued') }}</v-card-title>
                <v-divider></v-divider>
              </template>
              <v-card-text class="queue-scroll py-2 px-1">

                <v-fade-transition group hide-on-leave>
                  <template v-if="loading">
                    <project-card-skeleton-loader v-for="i in [1,2,3]" :key="i"/>
                  </template>
                </v-fade-transition>

                <v-fade-transition hide-on-leave>
                  <template v-if="!loading">
                    <component
                      :is="$isTenantAdmin ? 'draggable' : 'div'"
                      :list="unscheduledProjectLogs"
                      class="list-group"
                      group="projects"
                      handle=".handle"
                      @change="(e) => handleDragChangeUnscheduled(e)"

                    >
                      <project-card v-for="projectLog in unscheduledProjectLogs"
                                    :key="projectLog.id"
                                    :project-log="projectLog"
                                    class="list-group-item"></project-card>
                      <no-content-view v-if="!loading && unscheduledProjectLogs.length === 0"
                                       :class="$vuetify.breakpoint.smAndUp ? 'pt-10' : ''"
                                       :description="projectLogs.length > 0 && unscheduledProjectLogs.length === 0 ? $t('production_timeline.no_unqueued_projects_description') : $t('production_timeline.no_projects_found_description')"
                                       :function-button="projectLogs.length === 0"
                                       :function-button-text="$t('production_timeline.reload_projects')"
                                       :prop-function="reload"
                                       :reload-button="false"
                                       :title="projectLogs.length > 0 && unscheduledProjectLogs.length === 0 ? $t('production_timeline.no_unqueued_projects') : $t('production_timeline.no_projects_found')"
                                       dense
                                       function-button-icon="mdi-refresh"
                                       title-icon="mdi mdi-store-24-hour"/>
                    </component>
                  </template>
                </v-fade-transition>

              </v-card-text>
            </v-card>
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp && 'px-3 pb-3'" class="production-line fill-height" lg="7" md="7"
                 xl="8">
            <v-card :outlined="$vuetify.breakpoint.mdAndUp" :tile="$vuetify.breakpoint.smAndDown"
                    class="fill-height scheduled-projects-card">
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-card-title>{{ $t('production_timeline.production_line') }}
                  <v-spacer/>
                  <v-btn v-if="$isTenantAdmin" icon small @click="refreshScheduledTimes">
                    <v-icon>mdi mdi-refresh</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
              </template>
              <v-card-text class="queue-scroll pa-0">
                <v-fade-transition hide-on-leave>
                  <v-row v-if="loading" class="pa-2">
                    <v-col cols="12">
                      <project-card-skeleton-loader v-for="i in [1,2,3]" :key="i"/>
                    </v-col>
                  </v-row>
                </v-fade-transition>

                <v-fade-transition hide-on-leave>
                  <div v-if="!loading && weeks.length > 0" class="production-timeline-queue-scroll"
                       style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow-y: auto;">
                    <v-row no-gutters>
                      <v-col style="z-index: 2;">
                        <template v-for="week in weeks">
                          <v-row :key="week.weekNumber" class="calendar-week-wrapper" no-gutters>
                            <v-col :style="{maxWidth: $vuetify.breakpoint.smAndDown ? '14px' : '18px'}"
                                   class="mr-1" style="z-index: 2;">
                              <div :class="week.weekNumber % 2 === 0 ? 'even' : 'odd'"
                                   class="calendar-week d-flex flex-column justify-start align-center fill-height">
                                <div class="calendar-week-number text-center">{{ week.weekNumber }}</div>
                              </div>
                            </v-col>
                            <v-col>
                              <template v-if="week.productionExceptions.length > 0">
                                <v-alert class="py-2 py-md-3 mx-2 mt-2 mb-2" color="info" text>
                                  <div class="font-weight-bold text-body-2 text-uppercase text-md-subtitle-2 mb-1">
                                    {{ $t('production_timeline.exceptions_for_week') }} {{ week.weekNumber }}:
                                  </div>
                                  <div v-for="exception in week.productionExceptions" :key="exception.id"
                                       class="text-caption text-md-body-2">
                                    {{ $t('production_timeline.production_exception_types.' + exception.type) }}
                                    {{ $formatWeekDayLong(exception.date) }} {{ exception.duration }} tuntia -
                                    {{ exception.description }}
                                  </div>
                                </v-alert>
                              </template>
                              <component :is="$isTenantAdmin ? 'draggable' : 'div'" :list="week.projects"
                                         class="list-group"
                                         group="projects"
                                         handle=".handle"
                                         @change="(e) => handleDragChangeScheduled(e, week.weekNumber)">
                                <template v-for="(projectLog) in week.projects">
                                  <project-card
                                    :key="projectLog.id"
                                    :project-log="projectLog"
                                    :updating="updating"
                                    class="list-group-item"></project-card>
                                </template>
                              </component>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                  </div>
                </v-fade-transition>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-fade-transition>
  </hb-basic-page>
</template>

<script>
import productionLineApi from '@/api/productionline';
import projectApi from '@/api/project';
import dayjs from "dayjs";
import ProjectCard from "@/views/tenant/ProductionTimelineView/components/ProjectCard";
import ProjectCardSkeletonLoader from "@/views/tenant/ProductionTimelineView/components/ProjectCardSkeletonLoader";
import Draggable from 'vuedraggable';
import NoContentView from "@/components/NoContentView";
import orderBy from "lodash/orderBy";

export default {
  name: 'ProductionTimelineView',
  components: {NoContentView, ProjectCardSkeletonLoader, ProjectCard, Draggable},
  data() {
    return {
      loading: true,
      options: {},
      pagedResult: {},
      searchString: '',
      productionLines: [],
      projectLogs: [],
      saving: false,
      dayHeight: 240,
      unqueuedMinimized: false,
      unscheduledProjectLogs: [],
      scheduledProjectLogs: [],
      origSchedulesProjectLogs: [],
      updating: false,
      currentWeek: this.$formatWeekNumber(dayjs()),
      weeks: [],
    };
  },
  async mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    await this.reload();
  },
  computed: {
    productionLine() {
      return this.productionLines?.[0] || {};
    },
    productionTimeExceptions() {
      const o = {};
      this.productionLines.forEach(l => {
        l.productionTimeExceptions?.forEach(plte => {
          if (o[`${l.id}`]) {
            o[`${l.id}`].push(plte);
            return;
          }
          o[`${l.id}`] = [plte];
        });
      })
      return o;
    },
    actionButtons() {
      return {};
    },
  },
  methods: {
    async handleDragChangeScheduled(e, week) {
      let startDate = null;
      let projectLogId = null;
      let timelineStartDate = dayjs().startOf("hour").toISOString(); // Does determine the place for the first project
      const weekIndex = this.weeks.findIndex(w => w.weekNumber === week);
      if (e.added) {
        if (e.added.newIndex > 0) {
          startDate = this.weeks[weekIndex]?.projects[e.added.newIndex - 1]?.productionEndDate;
        } else {
          if (weekIndex > 0) {
            let earlierProjectFound = false;
            let step = 1;
            while (!earlierProjectFound) {
              if (this.weeks[weekIndex - step]?.projects[this.weeks[weekIndex - step]?.projects.length - 1]?.productionEndDate) {
                startDate = this.weeks[weekIndex - step]?.projects[this.weeks[weekIndex - step]?.projects.length - 1]?.productionEndDate
                earlierProjectFound = true;
              } else {
                if (this.weeks[weekIndex - (step + 1)]) {
                  step++;
                } else {
                  startDate = dayjs().startOf("hour").toISOString();
                  earlierProjectFound = true;
                }
              }
            }
          } else {
            startDate = dayjs().startOf("hour").toISOString();
          }
        }
        projectLogId = e.added.element.id
      } else if (e.moved) {
        if (e.moved.newIndex > 0) {
          startDate = this.weeks[weekIndex]?.projects[e.moved.newIndex - 1]?.productionEndDate || dayjs().startOf("hour").toISOString();
        } else {
          startDate = this.weeks[weekIndex - 1]?.projects[this.weeks[weekIndex - 1]?.projects.length - 1]?.productionEndDate || dayjs().startOf("hour").toISOString();
        }
        projectLogId = e.moved.element.id
      }

      if (projectLogId && startDate) {
        this.updating = true;
        let project = this.projectLogs.find(p => p.id === projectLogId)
        project.productionStartDate = startDate;
        project.productionEndDate = dayjs(startDate).add(project.productionTime, 'hour').toISOString();

        try {
          await projectApi.reschedule(this.productionLines[0].id, projectLogId, startDate, timelineStartDate);
          await this.reloadProjects()
        } catch (e) {
          this.$showErrorNotification(this.$t('error.unexpected_error'));
        }
        this.updating = false;
      }
    },
    async handleDragChangeUnscheduled(e) {
      console.log("handleDragChangeUnscheduled", e);
      if (e.added && e.added.element) { // Unschedule project
        this.updating = true;
        let element = e.added.element;
        try {
          // Mark startDate as null -> project will be unscheduled
          await projectApi.reschedule(this.productionLines[0].id, element.id, null, null);
          await this.reloadProjects()
        } catch (e) {
          this.$showErrorNotification(this.$t('error.unexpected_error'));
        }
        this.updating = false;
      }
      /*let startDate = null;
      let projectLogId = null;
      let timelineStartDate = dayjs().startOf("hour").toISOString(); // Does determine the place for the first project
      const weekIndex = this.weeks.findIndex(w => w.weekNumber === week);
      if (e.added) {
        if (e.added.newIndex > 0) {
          startDate = this.weeks[weekIndex]?.projects[e.added.newIndex - 1]?.productionEndDate;
        } else {
          if (weekIndex > 0) {
            let earlierProjectFound = false;
            let step = 1;
            while (!earlierProjectFound) {
              if (this.weeks[weekIndex - step]?.projects[this.weeks[weekIndex - step]?.projects.length - 1]?.productionEndDate) {
                startDate = this.weeks[weekIndex - step]?.projects[this.weeks[weekIndex - step]?.projects.length - 1]?.productionEndDate
                earlierProjectFound = true;
              } else {
                if (this.weeks[weekIndex - (step + 1)]) {
                  step++;
                } else {
                  startDate = dayjs().startOf("hour").toISOString();
                  earlierProjectFound = true;
                }
              }
            }
          } else {
            startDate = dayjs().startOf("hour").toISOString();
          }
        }
        projectLogId = e.added.element.id
      } else if (e.moved) {
        if (e.moved.newIndex > 0) {
          startDate = this.weeks[weekIndex]?.projects[e.moved.newIndex - 1]?.productionEndDate || dayjs().startOf("hour").toISOString();
        } else {
          startDate = this.weeks[weekIndex - 1]?.projects[this.weeks[weekIndex - 1]?.projects.length - 1]?.productionEndDate || dayjs().startOf("hour").toISOString();
        }
        projectLogId = e.moved.element.id
      }

      if (projectLogId && startDate) {
        this.updating = true;
        let project = this.projectLogs.find(p => p.id === projectLogId)
        project.productionStartDate = startDate;
        project.productionEndDate = dayjs(startDate).add(project.productionTime, 'hour').toISOString();

        try {
          await projectApi.reschedule(this.productionLines[0].id, projectLogId, startDate, timelineStartDate);
          await this.reloadProjects()
        } catch (e) {
          this.$showErrorNotification(this.$t('error.unexpected_error'));
        }
        this.updating = false;
      }*/
    },
    async reloadProjects() {
      try {
        /*this.projectLogs = (await projectApi.getPaged({
          searchString: null,
          status: ["CANT_START", "CAN_START", "MANUFACTURING"],
          noScheduling: false,
        }, {page: 1, itemsPerPage: 1000}))?.content;*/

        //### ###  ##   ##   #####   ##   ##           ##   ##    ###    ##   ##    ###              #####   ##   ##           ######   ######    #####       ###   ######    ####    ######   ##       #####     ####    ######      ###    ###
        //##   ##  ##   ##  ##   ##  ### ###           ###  ##   ## ##   ### ###   ## ##            ##   ##  ###  ##           ##   ##  ##   ##  ##   ##       ##   ##       ##  ##   # ## #   ##      ##   ##   ##  ##   ##           ##   ## ##
        //##   ##  ##   ##  ##   ##  #######           #### ##  ##   ##  #######  ##   ##           ##   ##  #### ##           ##   ##  ##   ##  ##   ##       ##   ##      ##          ##     ##      ##   ##  ##        ##           ##  ##   ##
        //#######  ##   ##  ##   ##  #######           #######  ##   ##  #######  ##   ##           ##   ##  #######           ##   ##  ##  ###  ##   ##   #   ##   #####   ##          ##     ##      ##   ##  ##  ###   #####    #   ##  ##   ##
        //##   ##  ##   ##  ##   ##  ## # ##           ## ####  #######  ## # ##  #######           ##   ##  ## ####           ######   #####    ##   ##  ##   ##   ##      ##          ##     ##      ##   ##  ##   ##   ##      ##   ##  #######
        //##   ##  ##   ##  ##   ##  ##   ##           ##  ###  ##   ##  ##   ##  ##   ##           ##   ##  ##  ###           ##       ## ###   ##   ##  ##   ##   ##       ##  ##     ##     ##   #  ##   ##   ##  ##   ##      ##   ##  ##   ##
        //### ###   #####    #####   ##   ##           ##   ##  ##   ##  ##   ##  ##   ##            #####   ##   ##           ##       ##  ###   #####    #####    ######    ####      ##     ######   #####     ####    ######   #####   ##   ##


        this.projectLogs = (await projectApi.getLogsPaged({
          searchString: null,
          done: false,
          //status: ["CANT_START", "CAN_START", "MANUFACTURING"],
          noScheduling: false,
        }, {page: 1, itemsPerPage: 1000}))?.content;
        this.unscheduledProjectLogs = this.projectLogs.filter(p => !p.productionStartDate && p.productionTime != null && ["CANT_START", "CAN_START", "PAUSED", "MANUFACTURING"].includes(p.status));
        this.scheduledProjectLogs = orderBy(this.projectLogs.filter(p => p.productionStartDate && p.productionTime != null && ["CANT_START", "CAN_START", "PAUSED", "MANUFACTURING"].includes(p.status)), ['productionStartDate'], ['asc']);
        this.parseWeeks();
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    parseWeeks() {
      const weeks = []
      const lastProjectStartDate = dayjs(this.scheduledProjectLogs[this.scheduledProjectLogs.length - 1]?.productionStartDate);
      let productionTimeExceptions = this.productionTimeExceptions[this.productionLines[0].id];
      if (productionTimeExceptions) {
        productionTimeExceptions = productionTimeExceptions.sort((a, b) => new Date(a.date) - new Date(b.date));
      }
      const lastExceptionDate = productionTimeExceptions ? dayjs(productionTimeExceptions[productionTimeExceptions.length - 1].date) : null;

      if (lastProjectStartDate || lastExceptionDate) {
        let weekDate = this.scheduledProjectLogs[0]?.productionStartDate ? dayjs(this.scheduledProjectLogs[0].productionStartDate).startOf('week') : dayjs().startOf('week');
        while ((lastProjectStartDate && weekDate.isBefore(lastProjectStartDate)) || (lastExceptionDate && weekDate.isBefore(lastExceptionDate))) {
          weeks.push({weekNumber: this.$formatWeekNumber(weekDate), projects: [], productionExceptions: []});
          weekDate = weekDate.add(1, 'week');
        }
      } else {
        weeks.push({weekNumber: this.$formatWeekNumber(dayjs()), projects: [], productionExceptions: []});
      }

      this.scheduledProjectLogs.forEach(project => {

        const weekNumber = this.$formatWeekNumber(project.productionStartDate);
        if (weekNumber) {
          const weekNumberIndex = weeks.findIndex(w => w.weekNumber === weekNumber);
          if (weekNumberIndex > -1) {
            if (!project || !project.project) return;
            weeks[weekNumberIndex].projects.push(project);
          } else {
            if (!project || !project.project) return;
            weeks.push({weekNumber: weekNumber, projects: [project], productionExceptions: []});
          }
        }
      });
      Object.keys(this.productionTimeExceptions).forEach(timeline => {
        this.productionTimeExceptions[timeline].forEach(exception => {
          const exceptionDate = dayjs(exception.date);
          if (exceptionDate.isBefore(dayjs().startOf('week'))) return;
          const weekNumber = this.$formatWeekNumber(exception.date);
          if (weekNumber) {
            const weekNumberIndex = weeks.findIndex(w => w.weekNumber === weekNumber);
            if (weekNumberIndex > -1) {
              weeks[weekNumberIndex].productionExceptions.push(exception);
            } else {
              weeks.push({weekNumber: weekNumber, projects: [], productionExceptions: [exception]});
            }
          }
        });
      });
      this.weeks = weeks;
    },
    async reload() {
      try {
        this.productionLines = await productionLineApi.getProductionLines();
        await this.reloadProjects();
      } catch (e) {
        this.$showErrorNotification(this.$t('projects.errors.error_loading_projects'));
      }
      this.loading = false;
    },
    async refreshScheduledTimes() {
      this.updating = true;
      try {
        await projectApi.refreshScheduledTimes(this.productionLines[0].id);
        await this.reloadProjects();
      } catch (e) {
        this.$showErrorNotification(this.$t('error.unexpected_error'));
      }
      this.updating = false;
    }
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 959px) {
  .production-timeline-content {
    height: calc(100vh - 56px);
  }

  .unqueued {
    width: 100% !important;
    min-width: 100% !important;
    flex: 0 0 30%;
    transition: all 0.15s ease-in-out;
    overflow: scroll;
  }
  .production-line {
    width: 100% !important;
    min-width: 100% !important;
    flex: 0 0 70%;
    transition: all 0.15s ease-in-out;
  }

  .scheduled-projects-card {
    z-index: 3;
    box-shadow: 0 -5px 12px -5px rgba(0, 0, 0, 0.3) !important;
  }

  .minimize-unqueued {
    .unqueued {
      flex: 0 0 20px;
    }

    .production-line {
      flex: 0 0 calc(100% - 20px);
    }
  }
}

@media screen and (min-width: 960px) {
  .production-timeline-content {
    height: calc(100vh - 64px);
  }

  .queue-scroll {
    max-height: 100%;
    height: calc(100vh - 141px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
}

.list-group {
  position: relative;
  z-index: 3;
}

$card-margin: 0px;

.project-card {
  margin: $card-margin 5px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-bottom: 1px solid black;
}

.calendar-week-wrapper {
  border-bottom: 1px solid #eee;

  &:nth-child(odd) {
    background-color: #fdfdfd;
  }

  .calendar-week {
    .calendar-week-number {
      font-size: 8px;
      font-weight: 500;
    }

    &.even {
      background-color: #454545;
      color: white;
    }

    &.odd {
      background-color: #ededed;
      color: black;
    }
  }
}

@media screen and (min-width: 960px) {
  .calendar-week-wrapper {
    .calendar-week {
      .calendar-week-number {
        font-size: 11px;
      }
    }
  }
}

/*$card-width: 400px;
.date-container {
  width: 100px;
  background-color: #f5f5f5;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}





.left-scroll {
  position: relative;
  width: $card-width;
  overflow-y: scroll;
  max-height: 100%;
}

.right-scroll {
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
}

.left-scroll, .right-scroll {
  height: calc(100vh - 140px);
}

.project-line-container {
  width: $card-width;
}

.header-row {
  position: relative;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid #e0e0e0;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  background: #77948f;
  padding: 5px 5px 5px 15px;
  color: #fff;
  overflow: hidden;
  //margin-right: -10px;
}*/
</style>

