import axios from 'axios';

export default {
  async getProductionLines() {
    return await axios({
      url: '/api/tenant/productionline',
    }).then(r => r.data);
  },
  async upsertProductionLines(productionLines) {
    return await axios({
      url: '/api/tenant/productionline/upsert',
      method: 'PUT',
      data: productionLines
    }).then(r => r.data);
  },
  async getProductionLineException(productionLineId, productionLineExceptionId) {
    return await axios({
      url: `/api/tenant/productiontimeexception/${productionLineId}/${productionLineExceptionId}`,
    }).then(r => r.data);
  },
  async updateProductionLineException(productionLineId, productionLineExceptionId, data) {
    return await axios({
      url: `/api/tenant/productiontimeexception/${productionLineId}/${productionLineExceptionId}`,
      method: 'PUT',
      data
    }).then(r => r.data);
  },
  async deleteProductionLineException(productionLineId, productionLineExceptionId) {
    return await axios({
      url: `/api/tenant/productiontimeexception/${productionLineId}/${productionLineExceptionId}`,
      method: 'DELETE',
    }).then(r => r.data);
  },
  async createProductionLineException(productionLineId, data) {
    return await axios({
      url: `/api/tenant/productiontimeexception/${productionLineId}`,
      method: 'POST',
      data
    }).then(r => r.data);
  }

};
