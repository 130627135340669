<template>
  <v-chip :x-small="!large || $vuetify.breakpoint.xsOnly" :small="large && $vuetify.breakpoint.smAndUp" label :color="statusColor" :style="{height: large ? '' : '13px'}" :class="large ? '' : 'px-1'">
    {{ $t('project.status.' + status) }}
  </v-chip>
</template>

<script>
import projectApi from "@/api/project";

export default {
  name: "StatusChip",
  props: {
    status: {
      type: String,
      required: true
    },
    large: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    statusColor() {
      return projectApi.getProjectStatuses().find(x => x.value === this.status)?.color;
    }
  }
}
</script>

<style scoped>

</style>
