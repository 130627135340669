<template>
  <hb-basic-page
    :back-button="backButton"
    :extension-height="$vuetify.breakpoint.smAndUp ? 107 : 47"
    :fill-height="loading"
    :loading="loading"
    :title="$tc('party.title', 1)"
  >
    <template v-slot:app-bar-extension>
      <v-col class="px-0">
        <v-row>
          <div v-if="$vuetify.breakpoint.smAndUp" :class="$isTenantAdmin ? 'mb-3' : ''" class="ml-2">
            <v-btn class="primary mr-3" @click="id ? save() : create()">
              <v-icon class="no-gradient" left>mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
          <template>
            <v-tabs v-if="tabs && party" v-model="tab" background-color="transparent">
              <v-tab v-for="item in tabs" :key="item.name">
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-row>
      </v-col>
    </template>

    <v-col class="pa-0" cols="12" style="margin-bottom: 72px">
      <v-tabs-items v-model="selectedTab">
        <!-- BASIC INFO -->
        <v-tab-item>
          <v-col
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-5'"
            cols="12"
            lg="6"
            md="8"
            offset-lg="3"
            offset-md="2"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <v-card-text>
                <v-text-field
                  v-model="party.companyName"
                  :disabled="saving"
                  :label="$t('party.company_name_long')"
                  autocomplete="off"
                  class="mb-5 mt-4"
                  clearable
                  dense
                  hide-details
                  item-value=""
                  light
                  outlined
                  tabindex="1"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-office-building</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="party.businessCode"
                  :disabled="saving"
                  :label="$t('party.business_code')"
                  autocomplete="off"
                  class="mb-5 mt-4"
                  clearable
                  dense
                  hide-details
                  item-value=""
                  light
                  outlined
                  tabindex="2"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-identifier</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="party.contactFirstname"
                  :disabled="saving"
                  :label="$t('party.contact_firstname')"
                  autocomplete="off"
                  class="mb-5 mt-4"
                  clearable
                  dense
                  hide-details
                  item-value=""
                  light
                  outlined
                  tabindex="3"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="party.contactLastname"
                  :disabled="saving"
                  :label="$t('party.contact_lastname')"
                  autocomplete="off"
                  class="mb-5 mt-4"
                  clearable
                  dense
                  hide-details
                  item-value=""
                  light
                  outlined
                  tabindex="4"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="party.phone"
                  :disabled="saving"
                  :label="$t('party.phone')"
                  autocomplete="off"
                  class="mb-5 mt-4"
                  clearable
                  dense
                  hide-details
                  item-value=""
                  light
                  outlined
                  tabindex="5"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-phone</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="party.email"
                  :disabled="saving"
                  :label="$t('party.email')"
                  autocomplete="off"
                  class="mb-5 mt-4"
                  clearable
                  dense
                  hide-details
                  item-value=""
                  light
                  outlined
                  tabindex="6"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-email</v-icon>
                  </template>
                </v-text-field>
                <v-autocomplete
                  v-model="party.type"
                  :disabled="saving"
                  :items="types"
                  :label="$t('party.type')"
                  autocomplete="off"
                  class="mb-5"
                  clearable
                  dense
                  hide-details
                  item-text="label"
                  item-value="value"
                  light
                  outlined
                  tabindex="7"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-shape-outline</v-icon>
                  </template>
                </v-autocomplete>
                <v-checkbox v-if="party.tenantParty" v-model="party.tenantParty" :label="$t('party.tenant_party')"
                            disabled readonly>
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-tab-item>

        <!-- ADDRESS -->
        <v-tab-item>
          <v-col
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-5'"
            lg="6"
            md="8"
            offset-lg="3"
            offset-md="2"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <address-card
                :address="party.visitingAddress"
                :saving="saving"
                :subtitle="$t('party.visiting_address')"
                :title="$t('party.addresses')"
                @change="handleVisitingAddressChange"
              />
              <address-card
                :address="party.billingAddress"
                :saving="saving"
                :subtitle="$t('party.billing_address')"
                @change="handleBillingAddressChange"
              />
            </v-card>
          </v-col>
        </v-tab-item>

        <!-- PERSONS -->
        <v-tab-item>
          <v-col
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons ma-0' : ' gradient-icons'"
            cols="12"
            lg="6"
            md="8"
            offset-lg="3"
            offset-md="2"
          >
            <v-btn v-if="party.id" :block="$vuetify.breakpoint.xsOnly" color="primary" @click="addNewPerson">
              <v-icon class="no-gradient" left>mdi-content-save</v-icon>
              <span>{{ $t('party.buttons.new_person') }}</span>
            </v-btn>
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <v-data-table
                id="worker-licenses-table"
                :headers="headers"
                :items="partyPersons"
                :mobile-breakpoint="0"
                :no-data-text="$t('person.no_persons')"
                :sort-by.sync="sortBy"
                :sort-desc.sync="descending"
                class="gradient-info__table mt-4 mb-4"
                disable-pagination
                hide-default-footer
                locale="fi-FI"
              >
                <template v-slot:body="{ items }">
                  <tbody v-for="item in items" :key="item.id">
                  <tr v-if="items.length > 0" class="bottom-border" style="cursor: pointer" @click="editPerson(item)">
                    <td style="white-space: nowrap">
                      <span>{{ item.lastname }} {{ item.firstname }}</span>
                    </td>
                    <td>
                      <span>{{ item.phone }}</span>
                    </td>
                    <td>
                      <span>{{ item.email }}</span>
                    </td>
                    <td>
                      <v-btn
                        fab
                        small
                        text
                        @click.stop="
                            $confirmDelete($t('party.delete_person_from_party'), $t('party.delete_person_from_party_confirm')).then(confirm => {
                              if (confirm) removePersonFromParty(item);
                            })
                          "
                      >
                        <v-icon class="no-gradient" color="error">mdi mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <div v-if="$vuetify.breakpoint.xsOnly">
      <v-btn bottom color="default" fab fixed left @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn v-if="id" bottom color="primary" fab fixed right @click="save">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn v-else bottom color="primary" fab fixed right @click="create">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
    <select-person-dialog ref="selectPersonDialog" @created="getPersons"/>
  </hb-basic-page>
</template>

<script>
import partyApi from '@/api/party';
import personApi from '@/api/person';
import AddressCard from '@/components/AddressCard';
import SelectPersonDialog from '@/components/Parties/selectPersonDialog';

export default {
  name: 'PartyView',
  components: {SelectPersonDialog, AddressCard},
  props: ['id'],
  data() {
    return {
      tab: 0,
      tabs: [
        {
          name: this.$t('party.basic_info'),
        },
        {
          name: this.$t('party.address_info'),
        },
        {
          name: this.$tc('party.person', 2),
        },
      ],
      descending: true,
      headers: [
        {
          text: this.$t('person.name'),
        },
        {
          text: this.$t('party.phone'),
        },
        {
          text: this.$t('party.email'),
        },
        {
          text: '',
        },
      ],
      sortBy: 'lastname',
      partyPersons: [],
      selectedTab: 'basic_info',
      loading: false,
      searchPhrase: '',
      backButton: {
        show: true,
        fallback: 'tenant_parties',
      },
      saving: false,
      types: partyApi.getPartyTypes(),
      party: {
        companyName: '',
        businessCode: '',

        contactFirstname: '',
        contactLastname: '',

        phone: '',
        email: '',
        code: '',
        type: 'customer',
        visitingAddress: {},
        billingAddress: {},
        tenantParty: false,
      },
    };
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    this.loading = true;
    if (this.id) {
      this.getParty();
      this.getPersons();
    }
    this.selectedTab = this.tab;
    this.loading = false;
  },
  watch: {
    /*selectedTab(val) {
      if (val !== this.tab) {
        if (this.id) {
          this.$router.replace({ name: 'tenant_party', params: { id: this.party.id, tab: val } });
        } else {
          this.$router.replace({ name: 'tenant_new_party', tab: val });
        }
      }
    },*/
    tab(val) {
      if (val !== this.selectedTab) {
        this.selectedTab = val;
      }
    },
  },
  methods: {
    addNewPerson() {
      this.$refs.selectPersonDialog.open(this.party);
    },
    async removePersonFromParty(person) {
      let personData = person;
      personData.employer = null;
      await personApi.updatePerson(person.id, personData);
      await this.getPersons();
    },
    editPerson(person) {
      this.$router.push({name: 'tenant_person', params: {id: person.id, tenantId: this.$tenant}});
    },
    async getPersons() {
      this.partyPersons = await personApi.getPersonsByEmployer(this.id);
    },
    async getParty() {
      this.loading = true;
      this.party = await partyApi.getParty(this.id);
      this.loading = false;
    },
    async save() {
      this.saving = true;
      await partyApi.updateParty(this.id, this.party);
      this.saving = false;
      await this.$router.push({name: 'tenant_parties'});
    },
    async create() {
      this.saving = true;
      await partyApi.createParty(this.party);
      this.saving = false;
      await this.$router.push({name: 'tenant_parties'});
    },
    async cancel() {
      await this.$router.push({name: 'tenant_parties'});
    },
    handleVisitingAddressChange({name, value}) {
      this.party.visitingAddress[name] = value;
    },
    handleBillingAddressChange({name, value}) {
      this.party.billingAddress[name] = value;
    },
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}
</style>
