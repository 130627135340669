<template>
  <hb-basic-page
    :title="this.$route.params.id ? $t('person.edit_person') : $t('person.new_person')"
    :loading="loading"
    :fill-height="loading"
    :back-button="backButton"
    :extension-height="$vuetify.breakpoint.smAndUp && $isTenantAdmin ? 107 : 47"
    style="background-color: transparent"
  >
    <template v-slot:app-bar-extension>
      <v-col class="px-0">
        <v-row>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2" :class="$isTenantAdmin ? 'mb-3' : ''">
            <v-btn @click="saveUser" class="primary mr-3">
              <v-icon :left="$vuetify.breakpoint.smAndUp" class="mr-2">mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
          <template>
            <v-tabs v-model="tab" background-color="transparent">
              <v-tab>{{ $t('person.basic_details') }}</v-tab>
              <v-tab>{{ $t('person.address_details') }}</v-tab>
            </v-tabs>
          </template>
        </v-row>
      </v-col>
    </template>

    <v-col cols="12" class="pa-0" :style="{ marginBottom: $vuetify.breakpoint.xsOnly ? '76px' : '68px' }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-col
            md="8"
            offset-md="2"
            lg="8"
            offset-lg="2"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : 'mt-5 gradient-icons'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? '0' : ''">
              <v-card-text>
                <!--<div style="cursor: pointer" class="mb-5" v-if="person.manager" @click="person.manager = false">
                <v-icon>mdi mdi-checkbox-marked-circle-outline</v-icon>
                <span class="ml-5">{{ $t('user_management.user_page.remove_from_foreman_list') }}</span>
              </div>-->

                <v-text-field
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('person.firstname')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="person.firstname"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('person.lastname')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="person.lastname"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  :label="$t('person.phone')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="person.phone"
                >
                  <template v-slot:prepend>
                    <span>
                      <a style="text-decoration: none" v-if="person.phone" :href="`tel:${person.phone}`"><v-icon>mdi mdi-phone</v-icon></a>
                      <v-icon v-if="!person || !person.phone">mdi mdi-phone</v-icon>
                    </span>
                  </template>
                </v-text-field>

                <v-text-field
                  :disabled="saving"
                  outlined
                  dense
                  :rules="[$rules.email, $rules.spacesNotAllowed]"
                  validate-on-blur
                  :label="$t('person.email')"
                  light
                  item-value=""
                  autocomplete="off"
                  v-model="person.email"
                  clearable
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-email</v-icon>
                  </template>
                </v-text-field>

                <party-combobox
                  :saving="saving"
                  :parties="parties"
                  :loading="loadingParties"
                  :label="$t('person.employer')"
                  icon="mdi mdi-domain"
                  :value="person.employer"
                  @change="handlePersonEmployerChange"
                >
                </party-combobox>
                <!--<v-text-field
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  style="margin-top: -6px"
                  :label="$t('person.social_security_number')"
                  light
                  item-value=""
                  autocomplete="off"
                  v-model="person.ssn"
                  clearable
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-identifier</v-icon>
                  </template>
                </v-text-field>-->
                <!--<div style="cursor: pointer" class="mb-5 d-flex" @click="person.manager = !person.manager">
                  <div>
                    <v-icon v-if="person.manager">mdi mdi-checkbox-marked-circle</v-icon>
                    <v-icon v-if="!person.manager">mdi mdi-checkbox-blank-circle-outline</v-icon>
                  </div>

                  <div class="d-inline-block">
                    <span class="ml-5">{{ $t('user_management.user_page.is_manager_hint') }}</span>
                  </div>
                </div>-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col
            md="8"
            offset-md="2"
            lg="8"
            offset-lg="2"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : 'mt-5 gradient-icons'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? '0' : ''">
              <address-card
                :saving="saving"
                :address="person.address"
                :subtitle="$t('person.address')"
                @change="handlePersonAddressChange"
              />
            </v-card>
          </v-col>
        </v-tab-item>
      </v-tabs-items>

      <v-btn fab fixed right bottom @click="saveUser" v-if="$vuetify.breakpoint.xsOnly" color="primary">
        <v-icon>mdi mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </hb-basic-page>
</template>

<script>
import personApi from '@/api/person';
import partyApi from '@/api/party';
import dayjs from 'dayjs';

import AddressCard from '@/components/AddressCard';
import PartyCombobox from '@/components/PartyCombobox';

const addressTemplate = {
  address: '',
  address2: '',
  address3: '',
  postcode: '',
  city: '',
  country: '',
  dateFrom: dayjs().startOf('year'),
  dateTo: null,
  type: 'VISITING',
  hidden: false,
};
export default {
  name: 'PersonView',
  components: { PartyCombobox, AddressCard },
  data() {
    return {
      tab: 0,
      loading: false,
      loadingParties: false,
      searchPhrase: '',
      showAlert: false,
      backButton: {
        show: true,
        fallback: 'tenant_persons',
      },
      saving: false,
      person: {
        firstname: '',
        lastname: '',
        address: { ...addressTemplate },
        taxNumber: '',
        ssn: '',
        dob: '',
        manager: false,
        hidden: false,
        phone: '',
        email: '',
        employer: null,
      },
      parties: [],
    };
  },
  computed: {},
  methods: {
    async getPerson() {
      this.loading = true;
      const person = await personApi.getPerson(this.$route.params.id);
      if (person.address === null) {
        person.address = { ...addressTemplate };
      }
      this.person = person;
      this.loading = false;
    },
    async saveUser() {
      this.loading = true;

      if (this.person.id > 0) {
        await personApi.updatePerson(this.person.id, this.person);
        this.$showSuccessNotification(this.$t('person.updated'));
      } else {
        await personApi.createPerson(this.person);
        this.$showSuccessNotification(this.$t('person.created'));
      }

      this.loading = false;
      await this.$router.push({ name: 'tenant_persons' });
    },
    cancel() {
      this.$router.go(-1);
    },
    handlePersonAddressChange({ name, value }) {
      if (!this.person.address) this.person = { ...this.person, address: {} };
      this.person.address[name] = value;
    },
    handlePersonEmployerChange(party) {
      this.person.employer = party;
    },
    async getParties() {
      this.loadingParties = true;
      const parties = await partyApi.getParties();
      this.parties = parties;
      this.loadingParties = false;
    },
  },
  mounted() {
    this.loading = true;
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    if (this.$route.params.id) {
      this.getPerson();
    }
    this.getParties();
    this.loading = false;
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}

.toggle_group_buttons {
  height: 48px !important;
  width: 50% !important;
}
</style>
