<template>
  <hb-basic-page
    v-if="$isTenantAdmin"
    :fill-height="true"
    :loading="loading"
    :title="$tc('production_lines.title', 1)"
    class="production_lines_view">

          <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2" offset-xl="3" xl="6">
            <template v-for="(line, i) in productionLines">
              <form :key="`${line.id}_${i}`" :ref="`form_${i}`">

                <!--
                <v-card class="mb-3" outlined>
                  <v-card-title>{{ $t('production_lines.details') }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pb-0">
                    <v-text-field v-model="line.name"
                                  :disabled="saving"
                                  :label="$t('production_lines.name')"
                                  autocomplete="off"
                                  clearable
                                  :dense="$vuetify.breakpoint.xsOnly"
                                  item-value=""
                                  light
                                  outlined></v-text-field>
                    <v-textarea v-model="line.description"
                                  :disabled="saving"
                                  :label="$t('production_lines.description')"
                                  autocomplete="off"
                                  clearable
                                  rows="3"
                                  :dense="$vuetify.breakpoint.xsOnly"
                                  item-value=""
                                  light
                                  outlined
                    ></v-textarea>
                  </v-card-text>
                </v-card>
                -->

                <v-card class="mb-3" outlined>
                  <v-card-title>
                    {{ $t('production_lines.working_hours') }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''">
                      <template v-for="weekday in weekdays">
                        <v-col :key="weekday">
                            <v-text-field v-model.number="line[`${weekday}`]" :disabled="saving"
                                          :label="$t(`weekdays.${weekday}`)"
                                          autocomplete="off"
                                          dense
                                          suffix="h"
                                          hide-details="auto"
                                          light
                                          outlined
                                          pattern="^[0-9]+(\.[0-9])?$"
                                          persistent-placeholder
                                          placeholder="24"
                            ></v-text-field>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :small="$vuetify.breakpoint.xsOnly" color="primary" @click="save" :loading="saving">
                      <v-icon left>mdi mdi-content-save</v-icon>
                      {{ $t('save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>

                <v-card class="mb-3" outlined>
                  <v-card-title>
                    {{ $tc('production_lines.exception._', 2) }}
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="addException(line)">
                      <v-icon>mdi mdi-plus</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <production-line-calendar ref="productionCalendar" :production-line="line" @reload="reload" :loading="loading" />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :small="$vuetify.breakpoint.xsOnly" color="primary" @click="addException" :loading="saving">
                      <v-icon left>mdi mdi-plus</v-icon>
                      {{ $t('production_lines.add_exception') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </form>
            </template>
          </v-col>
  </hb-basic-page>
</template>

<script>
import productionLineApi from '@/api/productionline';
import ProductionLineCalendar from "@/views/tenant/ProductionLinesView/components/ProductionLineCalendar";

const productionLineTemplate = {
  id: null,
  "name": "",
  "description": null,
  "active": true,
  "mondayStart": null,
  "mondayEnd": null,
  "tuesdayStart": null,
  "tuesdayEnd": null,
  "wednesdayStart": null,
  "wednesdayEnd": null,
  "thursdayStart": null,
  "thursdayEnd": null,
  "fridayStart": null,
  "fridayEnd": null,
  "saturdayStart": null,
  "saturdayEnd": null,
  "sundayStart": null,
  "sundayEnd": null,
  "productionTimeExceptions": []
};
export default {
  name: 'ProductionLinesView',
  components: {ProductionLineCalendar},
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      productionLines: [],
      saving: false,
      weekdays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ],
    };
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    this.reload()
  },
  methods: {
    add() {
      this.productionLines.push(structuredClone(productionLineTemplate));
    },
    async save() {
      this.saving = true;
      try {
        await productionLineApi.upsertProductionLines(this.productionLines);
        await this.reload();
        this.$showSuccessNotification(this.$t('production_lines.successfully_saved_production_lines'));
      } catch (e) {
        console.error(e)
        this.$handleApiError(e, this.$t('production_lines.errors.error_saving_production_lines'));
      }
      this.saving = false;
    },
    addException() {
      this.$refs.productionCalendar[0]?.addException();
    },
    async reload() {
      try {
        this.productionLines = await productionLineApi.getProductionLines();
      } catch (e) {
        this.$showErrorNotification(this.$t('production_lines.errors.error_loading_production_lines'));
      }
      this.loading = false;
    },
    createNew() {
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
  },
};
</script>

<style lang="scss" scoped>
ul.only-sub-item {
  list-style: none;
}
</style>
<style lang="scss"></style>
