<template>
  <basic-dialog v-model="dialog" :actionButtons="actionButtons" :loading="saving"
                :title="newException ? $t('production_lines.exception.new') : $t('production_lines.exception.edit')">

    <template v-if="loading">
      <v-container fluid>
        <hb-loading-indicator/>
      </v-container>
    </template>

    <div v-if="!loading && productionLineException" class="px-2 scroll-content">

      <v-card-text>
        <div :class="$vuetify.breakpoint.xsOnly ? 'caption' : ''" class="mt-4 mb-6">
          {{ $t('required_fields_are_marked_with_asterisk') }} (<span class="red--text">*</span>).
        </div>
        <v-text-field
          v-model="productionLineException.description"
          :disabled="saving"
          :label="$t('production_lines.description')"
          class="required"
          dense
          hide-details
          outlined
          validate-on-blur
          v-on:keydown.enter.prevent="newException ? create : save"
        >
        </v-text-field>
      </v-card-text>

      <v-date-picker v-if="newException"
                     v-model="selectedDates"
                     first-day-of-week="1"
                     full-width
                     locale-first-day-of-year="4"
                     no-title
                     range
                     show-week>
      </v-date-picker>
      <v-date-picker v-else
                     v-model="productionLineException.date"
                     first-day-of-week="1"
                     full-width
                     locale-first-day-of-year="4"
                     no-title
                     show-week>
      </v-date-picker>

      <v-card-text>
        <v-card class="grey lighten-4">
          <v-tabs v-model="tabs" background-color="grey lighten-4" class="mode-tabs">
            <v-tab key="shift" :disabled="!newException">{{ $t('production_lines.shift_exception') }}</v-tab>
            <v-tab key="advanced">{{ $t('production_lines.advanced_exception') }}</v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="tabs" class="transparent">
              <v-tab-item key="shift">
                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="12">
                      <p>{{ $t('production_lines.choose_shift_for_exception') }}</p>
                      <div>
                        <v-btn-toggle v-model="shifts" mandatory>
                          <v-btn :value="1" :x-small="$vuetify.breakpoint.xsOnly">1-{{
                              $t('production_lines.shift')
                            }}
                          </v-btn>
                          <v-btn :value="2" :x-small="$vuetify.breakpoint.xsOnly">2-{{
                              $t('production_lines.shift')
                            }}
                          </v-btn>
                          <v-btn :value="3" :x-small="$vuetify.breakpoint.xsOnly">3-{{
                              $t('production_lines.shift')
                            }}
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                      <div>
                        <v-checkbox v-model="alsoSaturdays" :label="$t('production_lines.also_saturdays')"
                                    hide-details></v-checkbox>
                        <v-checkbox v-model="alsoSundays" :label="$t('production_lines.also_sundays')"
                                    hide-details></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item key="advanced">
                <v-container fluid px-0 py-3>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model.number="productionLineException.duration" :disabled="saving"
                                    :label="$t('production_lines.duration')"
                                    :rules="[$rules.required]"
                                    autocomplete="off"
                                    class="required"
                                    clearable
                                    dense
                                    light
                                    outlined
                                    pattern="^[0-9]+(\.[0-9])?$"
                                    persistent-placeholder
                                    placeholder="6.5"
                                    suffix="h"
                      ></v-text-field>
                      <v-btn-toggle v-model="productionLineException.type" mandatory>
                        <v-btn v-for="type in exceptionTypes" :key="type.value" :small="$vuetify.breakpoint.xsOnly"
                               :value="type.value">
                          {{ type.label }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
        <v-alert :value="showAlert" class="mt-3 mb-0" outlined type="error">
          {{
            selectedDates && selectedDates.length > 0 ? $t('validation.check_all_required_fields') : $t('validation.no_dates_selected')
          }}
        </v-alert>
      </v-card-text>
    </div>

    <template v-slot:secondary-buttons>
      <v-btn
        v-if="productionLineException && productionLineException.id"
        :small="$vuetify.breakpoint.xsOnly"
        color="error"
        text
        @click.native="remove"
      >
        <v-icon v-if="$vuetify.breakpoint.smAndUp">mdi-trash</v-icon>
        <span>{{ $t('delete') }}</span></v-btn
      >
    </template>

  </basic-dialog>
</template>

<script>
import productionLineApi from '@/api/productionline';
import dayjs from "dayjs";
import BasicDialog from "@/components/BasicDialog";

const productionLineExceptionTemplate = {
  id: null,
  date: null,
  description: '',
  startTime: '',
  endTime: '',
};
export default {
  name: 'AddOrEditProductionLineException',
  components: {BasicDialog},
  data() {
    return {
      loading: true,
      showAlert: false,
      title: '',
      saving: false,
      dialog: false,
      productionLineException: null,
      productionLine: null,
      selectedDates: null,
      shifts: 2,
      shiftDurations: {
        1: 8,
        2: 16,
        3: 24
      },
      tabs: 0,
      alsoSaturdays: null,
      alsoSundays: null,
    };
  },
  computed: {
    actionButtons() {
      return {
        cancel: {
          click: this.cancel,
        },
        save: {
          icon: this.newException ? 'mdi mdi-plus' : 'mdi-content-save',
          text: this.newException ? this.$t('production_lines.create_exception') : this.$t('save'),
          click: this.save,
        }
      }
    },
    exceptionTypes() {
      return [
        {label: this.$t('production_lines.exception.AVAILABLE'), value: "AVAILABLE"},
        {label: this.$t('production_lines.exception.NOT_AVAILABLE'), value: "NOT_AVAILABLE"},
      ]
    },
    newException() {
      return !this.productionLineException?.id;
    },
    originalDurations() {
      return {
        0: this.productionLine.sunday,
        1: this.productionLine.monday,
        2: this.productionLine.tuesday,
        3: this.productionLine.wednesday,
        4: this.productionLine.thursday,
        5: this.productionLine.friday,
        6: this.productionLine.saturday,
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.loading = true;
        this.selectedDates = null;
        this.productionLine = null;
        this.productionLineException = null;
        this.showAlert = false;
        this.tabs = 0;
      }
    }
  },
  methods: {
    async open(productionLine, productionLineException) {
      this.dialog = true;
      this.productionLine = productionLine;
      if (productionLineException?.date) {
        this.selectedDates = [productionLineException.date];
      }
      if (productionLineException?.id) {
        this.productionLineException = productionLineException;
        await this.getProductionLineException();
        this.tabs = 1;
      } else {
        this.productionLineException = {...productionLineExceptionTemplate};
        this.productionLineException.date = productionLineException?.date;
        this.productionLineException.productionLineId = productionLine.id;
      }
      this.loading = false;
    },
    async remove() {
      try {
        await productionLineApi.deleteProductionLineException(this.productionLine.id, this.productionLineException.id);
        this.$emit('saved', this.productionLineException);
        this.cancel();
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    cancel() {
      this.dialog = false;
    },
    async save() {
      if (!this.productionLineException.description || this.selectedDates?.length === 0) {
        this.showAlert = true;
        return;
      }

      if (this.tabs === 1) { // advanced
        if (!this.productionLineException.duration || !this.productionLineException.type) {
          this.showAlert = true;
          return;
        }
      }

      this.saving = true;

      if (this.newException) { // new exception
        this.selectedDates = this.selectedDates.sort((a, b) => new Date(a) - new Date(b));
        let date = dayjs(this.selectedDates[0]);
        try {
          if (this.selectedDates[1] && this.selectedDates[0] !== this.selectedDates[1]) {
            const promises = [];
            const lastDatePlusOne = dayjs(this.selectedDates[1]).add(1, 'day');
            while (date.isBefore(lastDatePlusOne)) {
              const weekDayNumber = date.day();
              let shouldSaveException = true;
              if (this.tabs === 0) {
                shouldSaveException = (([1, 2, 3, 4, 5].includes(weekDayNumber)) || (this.alsoSaturdays && weekDayNumber === 6) || (this.alsoSundays && weekDayNumber === 0)) && this.originalDurations[weekDayNumber] !== this.shiftDurations[this.shifts];
              }
              if (shouldSaveException) {
                const productionLineException = {...this.productionLineException};
                productionLineException.date = date.format('YYYY-MM-DD');

                if (this.tabs === 0) {
                  if (this.originalDurations[weekDayNumber] < this.shiftDurations[this.shifts]) {
                    productionLineException.duration = this.shiftDurations[this.shifts] - this.originalDurations[weekDayNumber];
                    productionLineException.type = 'AVAILABLE';
                  } else {
                    productionLineException.duration = this.originalDurations[weekDayNumber] - this.shiftDurations[this.shifts];
                    productionLineException.type = 'NOT_AVAILABLE';
                  }
                }

                promises.push(productionLineApi.createProductionLineException(this.productionLine.id, productionLineException));
              }
              date = date.add(1, 'day');
            }
            await Promise.all(promises);
          } else {
            this.productionLineException.date = date.format('YYYY-MM-DD');
            if (this.tabs === 0) {
              const weekDayNumber = date.day();
              if (this.originalDurations[weekDayNumber] < this.shiftDurations[this.shifts]) {
                this.productionLineException.duration = this.shiftDurations[this.shifts] - this.originalDurations[weekDayNumber];
                this.productionLineException.type = 'AVAILABLE';
              } else {
                this.productionLineException.duration = this.originalDurations[weekDayNumber] - this.shiftDurations[this.shifts];
                this.productionLineException.type = 'NOT_AVAILABLE';
              }
            }
            await productionLineApi.createProductionLineException(this.productionLine.id, this.productionLineException);
          }
          this.$emit('saved');
          this.cancel();
        } catch (e) {
          this.$handleApiError(e);
        }
      } else { // update exception
        try {
          await productionLineApi.updateProductionLineException(this.productionLine.id, this.productionLineException.id, this.productionLineException);
          this.$emit('saved');
          this.cancel();
        } catch (err) {
          this.$handleApiError(err);
        }
      }
      this.saving = false;
    },
    async getProductionLineException() {
      try {
        this.productionLineException = await productionLineApi.getProductionLineException(this.productionLine.id, this.productionLineException.id);
      } catch (err) {
        this.$handleApiError(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-content {
  max-height: calc(100vh - 260px);
  overflow: auto;
}
</style>

<style lang="scss">
.mode-tabs {
  .v-slide-group__prev--disabled,
  .v-slide-group__next--disabled {
    display: none !important;
  }
}

</style>
