<template>
  <hb-basic-page
    :title="$tc('work_tasks.title', 2)"
    fluid
    :loading="false"
    :fill-height="true"
    :extension-height="60"
    :search-title="`${$t('work_tasks.search')}...`"
    @handle-search="debouncedSearch"
    class="worktask-view"
    :no-gutters="true"
  >
    <template v-if="!$isOnlyTenantBasic && $vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="openNewInDialog" color="primary" v-if="!$isOnlyTenantBasic">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('work_tasks.new') }}</span>
      </v-btn>
    </template>

    <v-btn @click="openNewInDialog" color="primary" v-if="!$isOnlyTenantBasic && $vuetify.breakpoint.xsOnly" fab fixed bottom right>
      <v-icon class="no-gradient">mdi-plus</v-icon>
    </v-btn>

    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="4" lg="3" xl="2" :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''">
                <checkbox-filter :filter="filter" :filter-options="filterOptions" @change="handleFilterChange" @blur="this.reload" />
              </v-col>
              <v-col xs="12" md="6" lg="3">
                <v-select
                  v-if="!$isOnlyTenantBasic"
                  v-model="filterUser"
                  :items="richerUsers"
                  :placeholder="`${$t('reports.employee')}`"
                  item-text="combinedInfo"
                  item-value="id"
                  multiple
                  hide-details
                  single-line
                  solo
                  clearable
                  background-color=""
                  append-icon="filter_list"
                >
                  <template slot="prepend-inner">
                    <v-icon class="mr-2">people</v-icon>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <template v-if="index === 0 && filterUser.length === 1">
                      <span v-if="item.lastName">{{ item.lastName }} {{ item.firstName }}</span>
                      <span v-else>{{ item.email }}</span>
                    </template>
                    <template v-if="filterUser.length > 1 && index === 0">
                      <span>{{ filterUser.length }} {{ $t('reports.selections') }}</span>
                    </template>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-title>
                      <template v-if="data.item.lastName">{{ data.item.lastName }} {{ data.item.firstName }}</template>
                      <template v-else>{{ data.item.email }}</template>
                    </v-list-item-title>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-5">
            <v-card outlined>
              <v-data-table
                :headers="headers"
                :items="pagedResult.content"
                class="transparent"
                :options.sync="options"
                :server-items-length="pagedResult.totalElements"
                :loading="loading"
                :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                :items-per-page="50"
                @click:row="openInDialog"
                style="cursor: pointer"
              >
                <template v-slot:item.title="{ item }"
                ><span v-if="item.object">{{ $t(`work_tasks.objects.${$snakeCase(item.object)}`) }}</span
                >&nbsp;<span>{{ item.title }}</span></template
                >
                <template v-slot:item.assignedUser.lastName="{ item }"
                ><span v-if="item.assignedUser">{{ item.assignedUser.lastName }} {{ item.assignedUser.firstName }}</span></template
                ><template v-slot:item.assignerUser.lastName="{ item }"
              ><span v-if="item.assignerUser">{{ item.assignerUser.lastName }} {{ item.assignerUser.firstName }}</span></template
              >
                <template v-slot:item.createdAt="{ item }"
                ><span v-if="item.createdAt">{{ $formatDateTime(item.createdAt) }} </span></template
                >
                <template v-slot:item.status="{ item }"
                ><span v-if="item.status"
                ><v-icon>{{ $workTaskStatusIcon(item.status) }}</v-icon> {{ $t(`work_tasks.status.${item.status}`) }}
                </span></template
                >

                <!--<template v-slot:item.type="{ item }"> {{ $t(`party.types.${item.type}`) }} </template>-->
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>

    <work-task-dialog ref="dialog" @save="reload"></work-task-dialog>
  </hb-basic-page>
</template>

<script>
import worktaskApi from '@/api/workTask';
import debounce from 'lodash.debounce';
import CheckboxFilter from '@/components/CheckboxFilter';
import WorkTaskDialog from '@/components/WorkTasks/WorkTaskDialog';
import UiEventBus from '@/UiEventBus';
export default {
  name: 'WorkTaskView',
  components: { WorkTaskDialog, CheckboxFilter },
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      users: [],
      filterUser: [],
      headers: [
        {
          text: this.$t('work_tasks.subject'),
          value: 'title',
        },
        {
          text: this.$t('work_tasks.message'),
          value: 'message',
        },
        {
          text: this.$t('work_tasks.status.subtitle'),
          value: 'status',
        },
        {
          text: this.$t('work_tasks.assigned_user'),
          value: 'assignedUser.lastName',
        },
        {
          text: this.$t('work_tasks.assigner_user'),
          value: 'assignerUser.lastName',
        },
        {
          text: this.$t('work_tasks.created_at'),
          value: 'createdAt',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      filter: ['RECEIVED', 'WAITING', 'INPROGRESS'],
      filterOptions: [
        { desc: this.$t('work_tasks.status.RECEIVED'), value: 'RECEIVED' },
        { desc: this.$t('work_tasks.status.WAITING'), value: 'WAITING' },
        { desc: this.$t('work_tasks.status.INPROGRESS'), value: 'INPROGRESS' },
        { desc: this.$t('work_tasks.status.DONE'), value: 'DONE' },
      ],
      workTasks: [],
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, { maxWait: 5000 });
  },
  async mounted() {
    this.loadingScreen = true;
    if (this.$isOnlyTenantBasic) {
      this.filterUser = [this.$userInfo.id];
    }
    //this.reloadParties();
    this.users = await this.$loadUsers();
    UiEventBus.$on('workTasks.changed', this.reload);

    this.loadingScreen = false;
  },
  beforeDestroy() {
    UiEventBus.$off('workTasks.changed', this.reload);
  },
  computed: {
    richerUsers() {
      if (this.$isOnlyTenantBasic) {
        return this.$richerUsers(this.users).filter(u => u.id === this.$userInfo.id);
      } else {
        return this.$richerUsers(this.users);
      }
    },
  },
  methods: {
    async reload() {
      this.loading = true;
      this.pagedResult = await worktaskApi.getWorkTasksPaged(
        { searchString: this.searchString, statuses: this.filter, assignedUserIds: this.filterUser.length > 0 ? this.filterUser : null },
        this.options
      );
      this.loading = false;
    },

    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    openNewInDialog() {
      this.$refs.dialog.openNew();
    },
    openInDialog(workTask) {
      this.$refs.dialog.open(workTask);
    },
  },
  watch: {
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    searchString() {
      this.reload();
    },
    filterUser() {
      this.reload();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss"></style>
