<template>
  <div style="position: relative">
    <div :class="icon ? 'header-with-icon' : ''"
         class="text-h6 text-md-h5 text-xl-h4 section-title text-wrap">
      <v-icon v-if="icon" :size="$vuetify.breakpoint.smAndDown ? '28' : '36'" class="header-icon" color="primary" left>
        {{ icon }}
      </v-icon>
      <slot></slot>
    </div>
    <div v-if="!!$slots['description']" class="subtext caption pt-1" no-gutters>
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    icon: String,
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  position: relative;
  word-break: break-word;

  &.header-with-icon {
    padding-left: 38px;
  }

  .header-icon {
    position: absolute;
    left: 0;
    top: -2px;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &.header-with-icon {
      padding-left: 48px;
    }

    .header-icon {
      top: -4px;
    }
  }
}

.subtext {
  margin-top: 3px;
  border-top: 3px solid var(--v-primary-base) !important;
}
</style>
