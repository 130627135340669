<template>
  <hb-basic-page
    v-if="$isTenantAdmin || $isOfficeUser"
    ref="basicPage"
    :action-buttons="$vuetify.breakpoint.smAndDown ? {} : {}"
    :extension-height="56"
    :fill-height="true"
    :loading="false"
    :no-gutters="true"
    :search-title="`${$t('project.search')}...`"
    :title="$tc('project.title', 2)"
    class="projects-view"
    fluid
    @handle-search="debouncedSearch"
  >
    <template v-slot:app-bar-extension>
      <div :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '44%' : '' }">
        <checkbox-filter
          :filter="filter"
          :filter-options="filterOptions"

          @blur="reload"
          @change="handleFilterChange"
        />
      </div>
      <div :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '25%' : '' }"
           class="ml-2 d-flex justify-center align-center">
        <div>
          <v-checkbox v-model="filterNoScheduling" :label="$t('project.no_scheduling')" hide-details></v-checkbox>
        </div>
      </div>
      <div class="ml-3">
        <v-btn
          :disabled="loading"
          :loading="loading"
          :title="$t('clear_filters')"
          icon
          @click="clearFilters()"
        >
          <v-icon>mdi mdi-filter-off</v-icon>
        </v-btn>
      </div>


    </template>
    <template v-slot:app-bar-nav-icon>
      <v-app-bar-nav-icon :title="$t('refresh_from_adminet')" @click="showUpdateFromAdminetDialog = true">
        <v-icon>mdi mdi-refresh</v-icon>
      </v-app-bar-nav-icon>
    </template>
    <v-col class="gradient-icons" cols="12">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card outlined>
              <v-data-table
                :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                :headers="headers"
                :items="pagedResult.content"
                :items-per-page="50"
                :loading="loading"
                :options="options"
                :server-items-length="pagedResult.totalElements"
                class="transparent"
                item-key="__key"
                style="cursor: pointer"
                @click:row="edit"
                @update:options="(opt) => options = opt"
              >
                <template v-slot:item.drawerName="{ item }">
                  <span v-if="false && !item.drawerName"><v-icon color="error">mdi mdi-draw</v-icon></span>
                  <span v-if="item.drawerName">{{ item.drawerName }}</span>
                </template>
                <template v-slot:item.status="{ item }">

                  <span :class="`${getProjectStatusColor(item.status)}--text`">{{
                      item.status ? $t(`project.status.${item.status}`) : null
                    }}</span>
                </template>


                <template v-slot:item.logs="{ item }">
                  <ul :class="{'only-log': item && item.logs && item.logs.length === 1}">
                    <template v-for="log in item.logs">
                      <li v-if="log.log" :key="`${log.id}`">{{ log.log.description }} {{ log.log.description2 }},
                        <strong>{{ log.amount }} {{ log.log.unit }}</strong>
                        <v-chip v-if="item.status !== log.status" :color="`${getProjectStatusColor(log.status)}`"
                                x-small>
                          {{ log.status ? $t(`project.status.${log.status}`) : null }}
                        </v-chip>
                      </li>
                    </template>
                  </ul>
                </template>
                <template v-slot:item.productionTime="{ item }">{{
                    item.productionTime ? `${item.productionTime} h` : ''
                  }}
                </template>
                <template v-slot:item.estimatedStart="{ item }">{{ $formatDateNoTime(item.estimatedStart) }}</template>
                <template v-slot:item.estimatedEnd="{ item }">{{ $formatDateNoTime(item.estimatedEnd) }}</template>
                <template v-slot:item.deliveryDate="{ item }">{{ $formatDateNoTime(item.deliveryDate) }}</template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-fade-transition>
      <basic-dialog v-model="showUpdateFromAdminetDialog" :action-buttons="{
        cancel: {
          text: this.$t('cancel'),
          click: () => {
            this.showUpdateFromAdminetDialog = false;
          },
        },
        refresh: {
          type: 'primary',
          text: this.$t('refresh'),
          click: () => {
            this.showUpdateFromAdminetDialog = false;
            this.refreshFromAdminet();
          },
        }
      }" :title="this.$t('project.update_from_adminet')" dialog-title-class="info--text" width="350">

        <v-card-text class="pt-2">
          {{ $t('project.update_from_adminet_text') }}
          <v-text-field
            v-model="updateFromAdminetContinueFrom"
            :label="$t('project.continue_from_code')"
            class="mt-4"
            dense
            outlined
          />
        </v-card-text>

      </basic-dialog>
    </v-col>
  </hb-basic-page>
</template>

<script>
import projectApi from '@/api/project';
import debounce from 'lodash.debounce';
import CheckboxFilter from '@/components/CheckboxFilter';
import BasicDialog from "@/components/BasicDialog.vue";
import {useStorage} from "@vueuse/core";

const optionsTemplate = structuredClone({
  "page": 1,
  "itemsPerPage": 50,
  "sortBy": [],
  "sortDesc": [
    false
  ],
  "groupBy": [],
  "groupDesc": [],
  "mustSort": false,
  "multiSort": false
})
export default {
  name: 'ProjectsView',
  components: {BasicDialog, CheckboxFilter},
  data() {
    return {
      loading: false,
      options: structuredClone(optionsTemplate),
      pagedResult: {},
      searchString: '',
      showUpdateFromAdminetDialog: false,
      updateFromAdminetContinueFrom: null,
      headers: [
        {
          text: this.$t('project.code'),
          value: 'code',
        },
        {
          text: this.$t('project.name'),
          value: 'name',
        },
        {
          text: this.$t('project.city'),
          value: 'city',
        },
        {
          text: this.$t('project.building_model'),
          value: 'buildingModel',
        },
        {
          text: this.$t('project.estimated_end'),
          value: 'estimatedEnd',
        },
        {
          text: this.$t('project.delivery_date'),
          value: 'deliveryDate',
        },
        {
          text: this.$t('project.production_time'),
          value: 'productionTime',
          align: 'end',
        },
        {
          text: this.$t('project.logs._'),
          value: 'logs',

          sort: (a, b) => `${a.logs?.[0]?.description}`.localeCompare(`${b.logs?.[0]?.description}`),
        },
        {
          text: this.$t('project.drawer_name_short'),
          value: 'drawerName',

        }, {
          text: this.$t('project.status._'),
          value: 'status',
        },
        /*{
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },*/
      ],
      filter: [],
      filterNoScheduling: false,
      parties: [],
      titleButton: {
        text: this.$t('project.buttons.new_project'),
        icon: 'mdi mdi-plus',
        callback: this.createNew,
        primary: true,
      },
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, {maxWait: 5000});
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
    const {
      filter,
      searchString,
      options,
      pagedResult,
      filterNoScheduling
    } = useStorage('ohr-projects-filter', {}).value || {};

    if (filter) {
      this.filter = filter;
    } else {
      this.filter = this.filterOptions.map((x) => x.value).filter(x => x !== 'DONE');
    }
    if (filterNoScheduling) {
      this.filterNoScheduling = filterNoScheduling;
    }
    if (searchString) {
      this.searchString = searchString;
      this.$refs.basicPage?.setSearchPhrase?.(searchString);
    }
    if (options) {
      this.options = options;
    }
    if (pagedResult) {
      this.pagedResult = pagedResult;
    }

    //this.reload();
  },
  computed: {
    filterOptions() {
      return projectApi.getProjectStatuses().map(x => {
        x.desc = x.label;
        return x;
      });
    }
  },
  methods: {
    async refreshFromAdminet() {
      //const confirmUpdate = await this.$confirm("info", this.$t('project.update_from_adminet'), this.$t('project.update_from_adminet_text'));
      //if (confirmUpdate) {
      await projectApi.runRobot(this.updateFromAdminetContinueFrom || null);
      //}
    },
    async reload() {
      this.loading = true;
      try {
        const options = structuredClone(this.options);
        /*if (options.sortBy?.[0] === 'logs') {
          options.sortBy = ['code']
        }*/
        const pagedResult = await projectApi.getPaged({
          searchString: this.searchString,
          status: this.filter,
          noScheduling: this.filterNoScheduling || undefined,
        }, options);

        //pagedResult.content = uniqBy(pagedResult?.content, 'code');

        pagedResult.content = pagedResult.content?.map((x, i) => {
          x.__key = `${x.id}__${x.code}__${i}`
          return x;
        })
        this.pagedResult = pagedResult;
        const saveablePagedResult = structuredClone(this.pagedResult)
        /*saveablePagedResult.content = saveablePagedResult.content?.map((x) => {
          return {};
        })*/
        useStorage('ohr-projects-filter', {}).value = {
          filter: this.filter,
          filterNoScheduling: this.filterNoScheduling,
          options: this.options,
          searchString: this.searchString,
          pagedResult: saveablePagedResult,
        };
        this.updateFromAdminetContinueFrom = this.pagedResult?.content?.[0]?.code;
      } catch (e) {
        this.$showErrorNotification(this.$t('project.errors.error_loading_projects'))
      }
      this.loading = false;
    },
    createNew() {
      this.$router.push({name: 'tenant_new_project'});
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    handleFilterNoSchedulingChange() {
      this.reload();
    },
    edit(project) {
      this.$router.push({name: 'tenant_project', params: {id: project.id, tenantId: this.$tenant}});
    },
    getProjectStatusColor(status) {
      return projectApi.getProjectStatuses().find(x => x.value === status)?.color;
    },
    clearFilters() {
      useStorage('ohr-projects-filter', {}).value = null;
      this.filter = this.filterOptions.map((x) => x.value).filter(x => x !== 'DONE');
      this.filterNoScheduling = false;
      this.searchString = '';
      this.$refs.basicPage?.setSearchPhrase?.('');
      this.options = structuredClone(optionsTemplate);
    }
  },
  watch: {
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    filterNoScheduling(to) {
      this.handleFilterNoSchedulingChange(to);
    },
    searchString() {
      this.options.page = 1;
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
ul.only-log {
  list-style: none;
}

</style>

<style lang="scss"></style>
