<template>
  <v-dialog :value="value" :width="width || ''" max-width="660" @input="updateValue">
    <v-card class="white">
      <v-card-title>
        <div :class="dialogTitleClass" class="text-truncate dialog-title">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="updateValue(false)">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
          v-show="subtleLoading"
          :indeterminate="true"
          color="primary lighten-2"
          height="2"
      ></v-progress-linear>
      <v-divider></v-divider>

      <slot></slot>

      <v-divider/>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks ? 'flex-column-reverse' : ''">
        <template
            v-if="$vuetify.breakpoint.smAndUp || (secondaryButtons && Object.keys(secondaryButtons).length === 1)">
          <template v-for="(kvalue, key) in secondaryButtons">
            <basic-button :key="key"
                          :block="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks"
                          :class="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks ? 'ma-1' : ''"
                          :disabled="loading"
                          :small="$vuetify.breakpoint.xsOnly"
                          text
                          v-bind="kvalue"
                          @click="kvalue.click">
              {{ $t(key) }}
            </basic-button>
          </template>
          <slot name="secondary-buttons"></slot>
        </template>
        <template
            v-if="$vuetify.breakpoint.xsOnly && (!!$slots['secondary-buttons'] || (secondaryButtons && Object.keys(secondaryButtons).length > 1))">
          <v-menu top>
            <template v-slot:activator="{on}">
              <basic-button :small="$vuetify.breakpoint.xsOnly" text v-on="on">
                {{ $t('more') }}
                <v-icon right>mdi mdi-dots-horizontal</v-icon>
              </basic-button>
            </template>
            <v-row class="flex-column white pa-2" no-gutters>
              <template v-for="(kvalue, key) in secondaryButtons">
                <basic-button :key="key"
                              :block="$vuetify.breakpoint.xsOnly"
                              :class="$vuetify.breakpoint.xsOnly ? 'my-1' : ''"
                              :disabled="loading"
                              small
                              text
                              v-bind="kvalue"
                              @click="kvalue.click">
                  {{ $t(key) }}
                </basic-button>
              </template>
              <slot name="secondary-buttons"></slot>
            </v-row>
          </v-menu>
        </template>

        <v-spacer></v-spacer>
        <template v-for="(kvalue, key) in actionButtons">

          <basic-button v-if="key !== 'save' && kvalue.type !== 'PRIMARY'" :key="key"
                        :block="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks"
                        :class="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks ? 'ma-1' : ''"
                        :disabled="loading || kvalue.disabled"
                        :small="$vuetify.breakpoint.xsOnly"
                        text
                        v-bind="kvalue"
                        @click="kvalue.click">
            {{ $t(key) }}
          </basic-button>

          <save-button v-if="key === 'save' || kvalue.type === 'PRIMARY'"
                       :key="key"
                       :block="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks"
                       :class="$vuetify.breakpoint.xsOnly && displayButtonsAsBlocks ? 'ma-1' : ''"
                       :disabled="valid === false || loading || kvalue.disabled"
                       :icon="kvalue.icon"
                       :loading="loading"
                       :small="$vuetify.breakpoint.xsOnly"
                       :text="kvalue.text"
                       @click="kvalue.click"/>
        </template>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import BasicButton from "@/components/BasicButton";
import SaveButton from "@/components/SaveButton";

export default {
  name: "BasicDialog",
  components: {BasicButton, SaveButton},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    subtleLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
    actionButtons: {
      type: Object,
      default: () => {
      },
    },
    dialogTitleClass: {
      type: String,
      default: "",
    },
    secondaryButtons: {
      type: Object,
      default: () => {
      },
    },
    valid: {
      type: Boolean,
      default: null,
    },
    value: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "",
    },
    displayButtonsAsBlocks: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    log(...args) {
      console.log(...args);
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-title {
  max-width: 75%;
}

@media screen and (min-width: 291px) {
  .dialog-title {
    max-width: 85%;
  }
}

@media screen and (min-width: 385px) {
  .dialog-title {
    max-width: 90%;
  }
}
</style>
