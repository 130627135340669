<template>
  <hb-basic-page
      v-if="$isTenantAdmin || $isOfficeUser"
      :action-buttons="$vuetify.breakpoint.smAndDown ? {  } : {}"
      :extension-height="56"
      :fill-height="true"
      :loading="false"
      :no-gutters="true"
      :search-title="`${$t('item.search')}...`"
      :title="$tc('item.title', 2)"
      class="parties-view"
      fluid
      @handle-search="debouncedSearch"
  >
    <template v-slot:app-bar-extension>
    </template>
    <template v-slot:app-bar-nav-icon>
      <v-app-bar-nav-icon :title="$t('refresh_from_adminet')" @click="refreshFromAdminet">
        <v-icon>mdi mdi-refresh</v-icon>
      </v-app-bar-nav-icon>
    </template>
    <v-col class="gradient-icons" cols="12">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card outlined>
              <v-data-table
                  :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                  :headers="headers"
                  :items="pagedResult.content"
                  :items-per-page="50"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="pagedResult.totalElements"
                  class="h-100 gradient-info__table"
                  style="cursor: pointer"
                  @click:row="openDialog"
              >
                <template v-slot:item.description="{ item }"> {{ item.description }} {{ item.description2 }}</template>
                <template v-slot:item.status="{ item }"> {{
                    item.status ? $t(`item.status.${item.status}`) : null
                  }}
                </template>
                <template v-slot:item.estimatedStart="{ item }"> {{ $formatDateNoTime(item.estimatedStart) }}</template>
                <template v-slot:item.widthHeight="{ item }"> {{
                    [item.width, item.height].filter(Boolean).join(' x ')
                  }}
                </template>
                <template v-slot:item.estimatedEnd="{ item }"> {{ $formatDateNoTime(item.estimatedEnd) }}</template>
                <template v-slot:item.deliveryDate="{ item }"> {{ $formatDateNoTime(item.deliveryDate) }}</template>
                <template v-slot:item.subItems="{ item }">
                  <ul v-if="item && item.subItems && item.subItems.length > 0"
                      :class="{'only-sub-item': item && item.subItems && item.subItems.length === 1}">
                    <li v-for="(subItem,i) in item.subItems" :key="`${subItem.id}_${i}`"> {{
                        subItem.description
                      }}
                      {{ subItem.description2 }}
                    </li>
                  </ul>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <add-or-edit-item-dialog ref="addOrEditItemDialog" @updated="reload"/>
        </v-row>

      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import itemApi from '@/api/item';
import debounce from 'lodash.debounce';
import AddOrEditItemDialog from "@/components/Items/AddOrEditItemDialog.vue";

export default {
  name: 'ItemsView',
  components: {AddOrEditItemDialog},
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      headers: [
        {
          text: this.$t('item.code'),
          value: 'code',
        },
        {
          text: this.$t('item.description'),
          value: 'description',
        },
        {
          text: this.$t('item.unit'),
          value: 'unit',
        },
        {
          text: this.$t('item.warehouse_amount'),
          value: 'warehouseAmount',
        },
        {
          text: this.$t('item.group'),
          value: 'group.name',
        },
        {
          text: this.$t('item.size'),
          value: 'widthHeight',
          sortable: false
        },
        {
          text: this.$tc('item.sub_item', 2),
          value: 'subItems',
        },

        /*{
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },*/
      ],
      filter: [],
      parties: [],
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, {maxWait: 5000});
  },
  mounted() {
    if (!this.$hasMatchingTenantRoles(['tenant_admin', 'tenant_office'])) {
      this.$router.replace({name: 'access_denied'});
      return;
    }
  },
  computed: {},
  methods: {

    async refreshFromAdminet() {
      const confirmUpdate = await this.$confirm("info", this.$t('item.update_from_adminet'), this.$t('item.update_from_adminet_text'));
      if (confirmUpdate) {
        await itemApi.runRobot();
        await itemApi.runWarehouseStockRobot();
      }
    },
    async reload() {
      this.loading = true;
      try {
        this.pagedResult = await itemApi.getPaged({searchString: this.searchString, types: this.filter}, this.options);
      } catch (e) {
        this.$showErrorNotification(this.$t('item.errors.error_loading_items'))
      }
      this.loading = false;
    },
    createNew() {
      this.$router.push({name: 'tenant_new_item'});
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    openDialog(item) {

      this.$refs.addOrEditItemDialog?.open(item.id, false);
    },
    edit(/*item*/) {
      return;
      //this.$router.push({name: 'tenant_item', params: {id: item.id, tenantId: this.$tenant}});
    },
  },
  watch: {
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    searchString() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
ul.only-sub-item {
  list-style: none;
}
</style>
<style lang="scss"></style>
